import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Fuse from 'fuse.js';

import { IndexWidgetService } from '../../../components/index-widget/index-widget.service';
import { DefinitionService } from '../../../shared/definitions/definition.service';
import { DefinitionTypeModel } from '../../../shared/definitions/models/definition-type.model';
import { RawDefinitionModel } from '../../../shared/definitions/models/raw-definition.model';
import { Subject } from '../../../shared/subjects/subject';
import { SubjectService } from '../../../shared/subjects/subject.service';

@Component({
  selector: 'exo-definitions',
  templateUrl: 'definitions.html',
  styleUrls: ['definitions.scss'],
  animations: [
    trigger('search', [
      transition(':enter', [
        style({ width: 0 }),
        animate('.3s ease', style({ width: '*' })),
      ]),
      transition(':leave', [
        animate('.3s ease', style({ width: 0 })),
      ]),
    ]),
    trigger('clear', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.1s ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('.1s ease', style({ opacity: 0 })),
      ]),
    ]),
    trigger('scroller', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.2s ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('.2s ease', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class DefinitionsComponent implements OnInit, OnDestroy {
  subject: Subject;
  fuse: any;

  @ViewChild('searchbar') searchbar: ElementRef<HTMLInputElement>;

  notFound = false;
  loading = true;
  page = 1;
  searchOpen = false;
  searchTerm = '';
  scrollTop = false;

  all: RawDefinitionModel['categories'];
  result: RawDefinitionModel['categories'];
  type: DefinitionTypeModel;
  link: string;

  constructor(
    private route: ActivatedRoute,
    private subjectService: SubjectService,
    private definitionService: DefinitionService,
    private changeDetector: ChangeDetectorRef,
    private indexService: IndexWidgetService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  get legacy() {
    return this.type === DefinitionTypeModel.LEGACY;
  }

  get categories() {
    return this.result;
  }

  get mappedIndex() {
    return this.result.map((c, i) => ({
      content: c.name,
      index: i,
      children: c.subcategories.map((s, si) => ({ content: s.name, index: si })),
    }));
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params) => {
      const slug = params.get('slug');

      this.subjectService.getSubjectBySlug(slug).subscribe((subject) => {
        this.subject = subject;

        this.definitionService.getDefinition(subject._id).subscribe((definition) => {
          this.type = definition[0];

          if (this.type === DefinitionTypeModel.MODERN) {
            const d = definition[1];

            d.categories.sort(this.sort('name'));
            d.categories.forEach((category) => {
              category.subcategories.forEach(subcategory => subcategory.definitions.sort(this.sort('term')));
              category.subcategories.sort(this.sort('name'));
            });

            this.fuse = new Fuse(
              this.definitionService.flatten(d),
              {
                threshold: 0.5,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                  'definition.term',
                ],
              },
            );

            this.all = this.result = d.categories;
            this.indexService.index = this.mappedIndex;

            this.loadComplete();
          } else {
            this.link = definition[1].link;
            this.loadComplete();
          }
        },
        this.handleError);
      });
    });
  }

  sort<T extends any>(key: keyof T) {
    return (a: T, b: T) => (a[key] as any).localeCompare(b[key], undefined, { numeric: true, sensitivity: 'base' });
  }

  ngOnDestroy() {
    this.indexService.index = undefined;
  }

  onChange(newValue) {
    this.searchTerm = newValue;
    this.result = newValue && this.fuse ? this.definitionService.reshape(this.fuse.search(newValue)).categories : this.all;
    this.indexService.index = this.mappedIndex;
    this.changeDetector.detectChanges();
  }

  loadComplete() {
    this.loading = false;
  }

  handleError({ status }) {
    if (status === 404) {
      this.loading = false;
      this.notFound = true;
    }
  }

  openPdf() {
    window.open(this.link);
  }

  toggleSearch() {
    this.searchOpen = !this.searchOpen;

    if (this.searchOpen) {
      setTimeout(() => this.searchbar.nativeElement.focus());
    }
  }

  clearSearch() {
    this.onChange('');
    this.searchbar.nativeElement.focus();
  }

  scrollToTop() {
    scrollTo({
      top: 0,
    });
  }

  @HostListener('window:scroll')
  onScroll() {
    this.scrollTop = this.document.documentElement.scrollTop > 50;
  }
}
