import { Component, OnInit } from "@angular/core";
import { Experiment } from "../../../shared/experiment/experiment";
import { ExperimentService } from "../../../shared/experiment/experiment.service";

import { ModalController } from "../../modal/modal.controller";
import { FreeSectionsModalComponent } from "../free-sections/free-sections";

@Component({
  selector: "exo-free-present",
  templateUrl: "free-present.html",
  styleUrls: ["free-present.scss"],
})
export class FreePresentModalComponent {
  constructor(
    private modalCtrl: ModalController,
    private experimentService: ExperimentService
  ) {}

  async claimPresent() {
    const experiment = await this.experimentService.addUser().toPromise();
    this.modalCtrl.setModal(FreeSectionsModalComponent, {
      experiment
    });
  }

  close() {
    this.modalCtrl.closeModal();
  }
}
