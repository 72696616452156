import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { SummaryChapter, SummarySection } from '../summaries/summary';
import { User } from '../user/user';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';
import { SummaryProgress } from './summaryProgress';

@Injectable()
export class SummaryProgressService {
  private SECONDS_PER_WORD = 0.075;
  private scrollingIsDone = false;
  private timer: number;
  private timerObject: any;
  private running = false;
  private subjectEncodedName: string;
  private userId: string;

  constructor(
    private auth: HttpAuthInterceptor,
    private authService: AuthService,
  ) {
    this.auth.user$.subscribe((user: User) => {
      if (user) {
        this.userId = user._id;
        return;
      }

      this.authService.getUser().pipe(first()).subscribe((user: User) => {
        this.userId = user._id;
      });
    });
  }

  private determineTimeThreshold(text: string) {
    if (!text) return 0;

    const regexForStripSVG = /<path.*>.*?<\/path>/ig
    text = text.replace(regexForStripSVG, '');
    text = text.replace(/<\/?[^>]+(>|$)/g, '');

    const threshold = text.split(' ').length * this.SECONDS_PER_WORD;
    return threshold;
  }

  private determineThresholdFromChapter(chapter: SummaryChapter, section: SummarySection) {
    const chapterTitle = this.determineTimeThreshold(chapter.encodedChapterName);
    const sectionTitle = section ? this.determineTimeThreshold(section.encodedSectionName) : 0;
    let sectionHTML = 0;
    section.topics.forEach((topic) => {
      sectionHTML = sectionHTML + (section ? this.determineTimeThreshold(topic.html) : 0);
    });
    return chapterTitle + sectionTitle + sectionHTML;
  }

  setScrollingIsDone() {
    console.log('register scrollling is done from service');
    if (!this.scrollingIsDone) {
      this.scrollingIsDone = true;
    }
  }

  startTracking(chapter: SummaryChapter, section: SummarySection, subjectEncodedName: string, firstUnread: SummarySection) {
    this.resetTracking();
    this.running = true;
    this.subjectEncodedName = subjectEncodedName;
    const threshold = this.determineThresholdFromChapter(chapter, section) * 1000;

    // while person didn't scroll it keeps hanging inside this.tracker.
    this.tracker(threshold, chapter.encodedChapterName, section.encodedSectionName, section.sectionTitle, firstUnread);
  }

  private tracker(threshold: number, encodedChapterName, encodedSectionName, sectionTitle, firstUnread: SummarySection) {
    if (!this.running) {
      return;
    }
    if (this.timer >= threshold && this.scrollingIsDone) {
      this.completeTracking(encodedChapterName, encodedSectionName, sectionTitle, firstUnread);
    }
    if (this.timer <= threshold || !this.scrollingIsDone) {
      this.timerObject = setTimeout(() => {
        this.timer += 1000;
        this.tracker(threshold, encodedChapterName, encodedSectionName, sectionTitle, firstUnread); // keep looping...
      }, 1000);
    }
  }

  resetTracking() {
    this.scrollingIsDone = false;
    this.timer = 0;
    this.running = false;
    if (this.timerObject) {
      clearTimeout(this.timerObject);
    }
  }

  completeTracking(encodedChapterName: string, encodedSectionName: string, sectionTitle: string, firstUnread: SummarySection) {
    this.resetTracking();
    this.saveProgress({
      encodedChapterName,
      encodedSectionName,
      encodedSubjectName: this.subjectEncodedName,
      sectionTitle,
      firstUnread,
      userId: this.userId,
    });
  }

  async loadProgress() {
    const user = await this.authService.getStoragedUser();
    const ls = localStorage.getItem('summary-progress');
    
    if (ls) {
      return JSON.parse(ls).filter((progress) => progress.userId === user._id);
    }

    localStorage.setItem('summary-progress', JSON.stringify([]));
    return [];
  }

  saveProgress(summaryProgress: SummaryProgress) {
    const ls = localStorage.getItem('summary-progress') || '[]';
    const summaryProgressData = JSON.parse(ls);

    const foundSummaryProgressIdx = summaryProgressData.findIndex(progress => 
      progress.encodedSectionName === summaryProgress.encodedSectionName &&
      progress.userId === this.userId
    );

    if (foundSummaryProgressIdx !== -1) {
      summaryProgressData[foundSummaryProgressIdx] = summaryProgress;
    }
    else {
      summaryProgressData.push(summaryProgress);
    }

    return localStorage.setItem('summary-progress', JSON.stringify(summaryProgressData));
  }
}
