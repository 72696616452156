import { Injectable } from '@angular/core';

@Injectable()
export class IndexWidgetService {
  public index: {
    content: string,
    index: number,
    children: {
      content: string,
      index: number,
    }[],
  }[];
}
