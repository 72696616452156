import { DeviceService } from './../../../components/device/device.service';
import { Component, OnInit } from '@angular/core';

import { UserService } from '../../../shared/user/user.service';

import { Device } from './../../../shared/user/device';

@Component({
  selector: 'exo-devices',
  templateUrl: 'devices.html',
  styleUrls: ['devices.scss'],
})
export class DevicesComponent implements OnInit {
  devices: Device[];
  loading = true;
  public ownDevice: Device;

  constructor(
    private userService: UserService,
    private deviceService: DeviceService,
  ) { }

  ngOnInit() {
    this.getDevices();
  }

  async getDevices() {
    this.ownDevice = await this.deviceService.getFingerprint();
    this.userService.getDevices().subscribe((devices) => {
      this.devices = devices;
      this.loading = false;
    },
    (err) => {
      this.loading = false;
      console.log(err);
    });
  }

  removeDevice(identifier: string) {
    this.userService.deleteDevice(identifier).subscribe((result) => {
      this.devices = this.devices.filter(device => device.identifier !== identifier);
    },
    (err) => {
      console.log(err);
    });
  }

  public isOwnDevice(device: Device) {
    return device.identifier === this.ownDevice.identifier;
  }

}
