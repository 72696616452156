import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { Device } from '../user/device';
import { User } from '../user/user';
import { UserService } from '../user/user.service';
import { addAuthHeader } from '../util/helpers';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';

import { Token } from './token';

const USER_CACHE_TIME_MS = 10000;

@Injectable()
export class AuthService {
  private userSubscription: any;

  private user: ReplaySubject<User> = new ReplaySubject(1);
  private last_cache_time_ms = 0;

  constructor(private http: HttpClient, private auth: HttpAuthInterceptor, private userService: UserService) { }

  getStoragedUser(): Promise<User> {
    return new Promise((resolve) => {
      this.userSubscription = this.auth.user$.subscribe((user: User) => {
        if (user) {
          this.userSubscription && this.userSubscription.unsubscribe();
          return resolve(user);
        }
      });
    });
  }

  getUser(): Observable<User> {
    if (!(Date.now() > this.last_cache_time_ms + USER_CACHE_TIME_MS)) {
      return this.user;
    }

    this.last_cache_time_ms = Date.now();
    this.http.get<User>('/user').pipe(first()).pipe(tap((res) => {
      this.auth.user.next(res);
    })).subscribe((user: User) => {
      this.user.next(user);
    });
    return this.user;
  }

  login(credentials, device: Device, admin = false): Observable<User> {
    return this.http.post<User>('/user/login', { ...credentials, device, admin }).pipe(tap(res => {
      this.auth.setToken(res.token);
    }));
  }

  adminLogin(credentials, device: Device): Observable<User> {
    return this.http.post<User>('/admin/login', { ...credentials, device }).pipe(tap(res => {
      this.auth.setToken(res.token);
    }));
  }

  logout(): void {
    this.last_cache_time_ms = 0;
    this.http.post('/user/logout', null, addAuthHeader()).subscribe();
    this.auth.removeToken();
  }

  isLoggedIn(): boolean {
    return !!this.auth.getToken() ;
  }

  socialLogin(socialToken, device: Device, user?): Observable<User> {
    if (socialToken.provider === 'apple') {
      socialToken.code = socialToken.serverAuthCode;
      socialToken.name = user ? user.name : '';
    }
    return this.http.post<Token>(`/user/login/${socialToken.provider}`, { ...socialToken, device }).pipe(tap((res: any) => {
      this.auth.user.next(res);
      this.auth.setToken(res.token);
      return this.getUser();
    }));
  }

  passwordReset(pwResetKey, password): Observable<any> {
    return this.http.put('/user/password/reset', { pwResetKey, password });
  }

  unblockAccount(key: string): Observable<any> {
    return this.http.put('/user/account/unblock', { key });
  }

  refreshToken(token: Token) {
    return this.http.post<Token>(`/auth/refresh`, { refreshToken: token.refreshToken }).pipe(tap((res: Token) => {
      this.userService.resetSupportedFeatures();
      this.auth.setToken(res);
    }));
  }
}
