<exo-card
  *ngFor="let product of cart?.items"
  class="order-card"
  space="0 0 16px 0"
>
  <div class="order">
    <div class="order-left">
      <figure class="product-figure">
        <img src="{{apiUrl}}{{product.cover}}" />
      </figure>
      <div>
        <span class="TextSubjectCheckoutTitle">{{product.description}}</span>
        <span class="TextSubjectCheckoutSubject"
          >{{product.name}} {{product.courseLevels | joinComma}}</span
        >
        <span
          class="TextSubjectCheckoutTitle"
          [style.margin]="'4px 0 0 0'"
          [innerHTML]="product.price | price"
        ></span>
      </div>
    </div>
    <exo-button
      text="delete"
      classes="btn-icon-close"
      (click)="deleteFromCart(product)"
    ></exo-button>
  </div>
</exo-card>
