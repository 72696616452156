import { Component, Input, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
  Experiment,
  ExperimentSection,
  ExperimentSubjectSections,
} from "../../../shared/experiment/experiment";
import { ExperimentService } from "../../../shared/experiment/experiment.service";

import { ModalController } from "../../modal/modal.controller";

@Component({
  selector: "exo-free-sections",
  templateUrl: "free-sections.html",
  styleUrls: ["free-sections.scss"],
})
export class FreeSectionsModalComponent implements OnInit {
  @Input() experiment: Experiment;
  selectedSubject = null;
  selectedSection: ExperimentSection;
  subjectsSections: ExperimentSubjectSections[];
  private readonly freeSelectionThreshold: number = 3;

  constructor(
    private modalCtrl: ModalController,
    private experimentService: ExperimentService
  ) {}

  ngOnInit() {
    this.getSections();
  }

  getSections() {
    this.experimentService.getSections().subscribe((subjectsSections) => {
      this.subjectsSections = subjectsSections;
    });
  }

  collapseSubject(subject) {
    if (this.selectedSubject === subject) {
      this.selectedSubject = null;
      return;
    }
    this.selectedSubject = subject;
  }

  selectSection(section: ExperimentSection) {
    this.selectedSection = section;
  }

  async claimSelectedSection() {
    if (this.experiment.userExperimentGroup < this.freeSelectionThreshold) {
      this.close();
    } else {
      if (this.disableSelection()) {
        return;
      }
      const nextClaim = await firstValueFrom(this.experimentService.claimPresent(this.experiment.claimUrl, this.selectedSection));
      this.selectedSection = null;
      if(nextClaim?.claimUrl) {
        this.experiment.claimUrl = nextClaim.claimUrl;
        await this.getSections();
      }
      else {
        this.close();
      }
    }
  }

  close() {
    this.modalCtrl.closeModal();
  }

  disableSelection() {
    return this.experiment?.userExperimentGroup >= this.freeSelectionThreshold && !this.selectedSection;
  }

  displayTitleText() {
    return this.experiment.userExperimentGroup < this.freeSelectionThreshold
      ? "Jouw gratis subdomeinen"
      : "Selecteer een vak";
  }

  displaySubTitleText() {
    return this.experiment.userExperimentGroup < this.freeSelectionThreshold ? "Bekijk welke subdomeinen jij gratis krijgt" : "Van dit vak krijg je gratis toegang tot een subdomein naar keuze";
  }

  displayButtonText() {
    return this.experiment.userExperimentGroup < this.freeSelectionThreshold
      ? "Ga naar het dashboard"
      : "Selecteer onderwerp";
  }
}
