<div class="summary-header">
  <div class="header-nav" [class.active]="showNav">
    <div>
      <exo-button
        class="btn-summary-menu"
        text="'menu'"
        [icon]="'/assets/nav-icons/menu.svg'"
        [iconSize]="'18px'"
        classes="btn-icon-regular"
        (click)="showNav = !showNav"
      ></exo-button>
    </div>
  </div>

  <div class="header-reader">
    <div class="title TextSubjectChapter">
      {{selectedChapter?.chapterTitle}}
    </div>
    <div class="actions" *ngIf="subject?.encodedName">
      <span [routerLink]="['/subject', subject?.encodedName]">
        <exo-button
          [icon]="'/assets/nav-icons/close.svg'"
          [iconSize]="'14px'"
          classes="btn-icon-regular"
        ></exo-button>
      </span>
    </div>
  </div>
</div>

<div class="summary-page">
  <!-- nav -->
  <div class="summary-nav-push" [class.active]="showNav"></div>
  <div class="summary-nav" [class.active]="showNav">
    <div class="summary-scroller">
      <div class="indexer">
        <!-- chapter -->
        <div
          *ngFor="let chapter of summarySections?.chapters; let chapterIndex = index"
        >
          <span class="summary-chapter-title TextSubjectIndexChapter"
            >{{chapter.chapterTitle}}</span
          >
          <ul>
            <!-- section -->
            <li
              *ngFor="let section of chapter.sections; let sectionIndex = index"
              (click)="selectSection(chapterIndex, sectionIndex);"
              [class.dynamic-color]="selectedSection?.encodedSectionName === section.encodedSectionName"
              [class.locked]="!section.preview && summarySections.preview"
            >
              <span class="summary-section-title TextSubjectIndexItemSummary"
                >{{section.sectionTitle}}</span
              >
              <span
                class="TextSubjectIndexItemSummary score"
                *ngIf="section.sectionRating"
                >{{section.sectionRating | score}}</span
              >
            </li>
          </ul>
        </div>
      </div>
      <a
        class="btn summary-answers-btn"
        *ngIf="answersLink"
        [href]="answersLink"
        target="_blank"
        >Bekijk de antwoorden</a
      >
    </div>
  </div>

  <!-- reader -->
  <div class="summary-reader" (scroll)="onReaderScroll($event)" #reader>
    <ng-container *ngIf="subject">
      <exo-summary-buyables [subject]="subject"></exo-summary-buyables>
    </ng-container>
    <div class="reader-content" *ngIf="selectedSection">
      <exo-chart
        *ngIf="lastResult?.averageRating"
        class="section-score"
        [size]="64"
        [score]="lastResult?.averageRating"
        [strokeSize]="4"
      ></exo-chart>
      <h2 class="summary-section-title TextSubjectIndexItemSummary">
        {{selectedSection.sectionTitle}}
      </h2>

      <div *ngFor="let topic of selectedSection?.topics">
        <div
          class="summary-topic {{getTopicRatingLvl(topic?.encodedTopicName)}}"
        >
          <div
            class="summary-topic-title dynamic-color TextSummaryTopicsTitle"
            [innerHTML]="topic.topicTitle | safeHtml"
            *ngIf="topic.topicTitle"
          ></div>
          <div
            class="summary-topic-html"
            [innerHTML]="topic.html | safeHtml"
            *ngIf="topic.html"
          ></div>
        </div>
      </div>

      <span class="next-btn" *ngIf="!isLastSection">
        <exo-button
          classes="btn-next"
          text="Volgende onderdeel"
          (click)="selectNextSection()"
        ></exo-button>
      </span>

      <exo-summary-results
        *ngIf="selectedSection?.hasPracticeModule && practiceModuleActive"
        [subject]="subject"
        [isTrainingPreview]="isTrainingPreview"
        [chapter]="selectedChapter"
        [section]="selectedSection"
        [summarySectionTraining]="lastResult"
      ></exo-summary-results>
    </div>
  </div>
</div>

<div
  class="water-mark"
  [class.loaded]="loaded"
  [style.background-color]="subject?.color"
>
  {{user?.email}}
</div>
