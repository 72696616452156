<svg attr.width="{{size}}" attr.height="{{size}}" *ngIf="loaded">
  <defs>
    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%"   stop-color="#05a"/>
      <stop offset="100%" stop-color="#0a5"/>
    </linearGradient>
  </defs>

  <circle
    stroke="#e2e2e2"
    fill="transparent"
    attr.cx="{{size / 2}}"
    attr.cy="{{size / 2}}"
    attr.r="{{radius}}"
    attr.stroke-width="{{strokeSize}}"
  />

  <circle class="dynamic-stroke" *ngIf="score > 0"
    fill="transparent" 
    stroke-linecap="round" 
    stroke="black"
    attr.id="{{id}}"
    attr.stroke-width="{{strokeSize}}"
    attr.cx="{{size / 2}}"
    attr.cy="{{size / 2}}"
    attr.r="{{radius}}"
    attr.transform="rotate(-90 {{size / 2}}, {{size / 2}})"
    style="animation: dash 1.5s ease forwards"
  />
</svg>
<span *ngIf="!showPercentage" [ngStyle]="{'height': size + 'px', 'width': size + 'px'}">{{score | score}}</span>
<span *ngIf="showPercentage" [ngStyle]="{'height': size + 'px', 'width': size + 'px'}">{{score * 10}}<div class="percentage-sign">&#x25;</div></span>
