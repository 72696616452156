<div class="exercise-root" *ngIf="loaded" [@routetrans]>
  <div class="first">
    <div class="exc-header">
      <exo-button-deprecated
        [style]="'icon'"
        [icon]="'/assets/nav-icons/close.svg'"
        class="btn-close"
        (click)="close()"
      >
      </exo-button-deprecated>
    </div>

    <div class="content content-scroll a-top">
      <div class="results-summary">
        <div class="atada">
          <div>
            <h2 class="title">Rapportcijfer</h2>
            <p class="st-16">{{message}}</p>
          </div>
          <exo-chart
            class="circle-chart-small"
            [id]="'chart-small'"
            [size]="70"
            [score]="mathRounder(results?.averageRating, 1)"
            [strokeSize]="4"
          ></exo-chart>
          <exo-chart
            class="circle-chart-large"
            [id]="'chart-large'"
            [size]="150"
            [score]="mathRounder(results?.averageRating, 1)"
          ></exo-chart>
        </div>

        <div>
          <span class="title">Tijdsduur</span>
          <span class="title dynamic-color sb-24"
            >{{timefy(results?.totalTime)}}</span
          >

          <span class="title">Tijd per vraag</span>
          <span class="title dynamic-color sb-24"
            >{{timefy(results?.averageTimePerQuestion)}}</span
          >

          <span class="title" *ngIf="results?.usedHints !== 0"
            >Hints bekeken</span
          >
          <span class="title dynamic-color" *ngIf="results?.usedHints !== 0"
            >{{results?.usedHints}}</span
          >

          <span class="title">Aantal te behalen punten</span>
          <span class="title dynamic-color sb-24"
            >{{results?.totalPoints}}</span
          >

          <span class="title">Aantal behaalde punten</span>
          <span class="title dynamic-color sb-24"
            >{{results?.scoredPoints}}</span
          >
        </div>

        <div>
          <exo-button-deprecated
            [label]="'Terug'"
            [style]="'large compact'"
            (clickEvent)="close()"
          ></exo-button-deprecated>
        </div>
      </div>
    </div>
  </div>

  <div class="second">
    <div class="content content-scroll a-top">
      <!-- Section results -->
      <div class="section-results" *ngIf="results?.hasMissingTopics">
        <h2 class="title">Onderdelen</h2>
        <ul>
          <ng-container>
            <li *ngFor="let answer of results.questionAnswers">
              <div class="rule">
                <img
                  src="{{answer.state === 0 ? '/assets/nav-icons/check.svg' : '/assets/nav-icons/incorrect.svg'}}"
                />
                <span>{{answer.questionAnswer}}</span>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>

      <!-- Topics results -->
      <div class="topic-results" *ngIf="!results?.hasMissingTopics">
        <h2 class="title">{{results?.name || 'Onderdelen'}}</h2>
        <ul>
          <ng-container>
            <li *ngFor="let topic of results?.questionTopics">
              <div (click)="openTopic(topic)">
                <span class="rule">{{topic.topicTitle}}</span>
                <div class="topic-score">
                  <span class="topic-score-main">{{topic.rating}}</span>
                  <span
                    *ngIf="topic?.improvementRate !== 0"
                    class="topic-score-improvement {{getImprovementColor(topic?.improvementRate)}}"
                    >{{topic?.improvementRate > 0 ? '+' :
                    ''}}{{topic.improvementRate}}</span
                  >
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
