<div class="payment-status-container">
  <exo-card space="0">
    <div class="card-content">
      <span class="TextPaymentTitle" [style.margin]="'0 0 32px 0'"
        >Deel het betaalverzoek</span
      >

      <span class="TextPaymentTitleMedium" [style.margin]="'0 0 8px 0'"
        >Kopieer de onderstaande link</span
      >

      <div class="link-container">
        <a id="link-text" target="_blanl" [href]="cart?.paymentLink"
          >{{cart?.paymentLink}}</a
        >
        <button class="copy-button" (click)="copyLink()">Copy</button>
        <div id="target" contenteditable="true"></div>
      </div>

      <span class="TextPaymentTitleMedium" [style.margin]="'32px 0 16px 0'"
        >Of deel via</span
      >

      <div class="share-container">
        <a
          class="whatsapp-share-btn"
          [href]="generateLinkWhatsapp()"
          data-action="share/whatsapp/share"
          target="_blank"
        ></a>
        <a
          class="email-share-btn"
          [href]="generateLinkEmail()"
          target="_blank"
        ></a>
      </div>

      <div class="payment-back-btn">
        <exo-button
          [style.width]="'80%'"
          classes="btn-regular"
          text="Terug naar het dashboard"
          (click)="gotoDashboard()"
        ></exo-button>
      </div>
    </div>
  </exo-card>
</div>
