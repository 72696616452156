import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AuthService } from '../../../shared/auth/auth.service';
import { RegisterService } from '../../../shared/register/register.service';

@Component({
  selector: 'exo-validate-email',
  templateUrl: 'validate-email.html',
  styleUrls: ['validate-email.scss'],
  animations: [
    trigger('code', [
      state('*', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, position: 'absolute' }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ValidateEmailComponent implements OnInit, AfterViewInit {
  @Output() out = new EventEmitter<any>();
  @Input() component: boolean; // component mode
  @Input() email: string;

  @ViewChild('input')
  private input: ElementRef;

  sent = false;
  loading = false;
  status: string;

  key: string;

  constructor(private registerService: RegisterService, private authService: AuthService) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.focus();
  }

  next(event) {
    if (this.key && this.key.length === 6) {
      this.submit();
    }
  }

  submit() {
    // Do API call
    this.status = null;
    this.loading = true;
    this.registerService.validateCode(this.key, this.email, this.component).subscribe((res) => {
      if (!this.component) {
        this.registerService.newUser = true;
      }
      this.out.emit('next');
    },
    (err) => {
      this.status = 'Ongeldige code';
      this.loading = false;
      console.log(err);
    });
  }

  resend() {
    this.registerService.resendCode(this.email).subscribe((data) => {
      this.sent = true;
      setTimeout(() => {
        this.sent = false;
      },         10000);
    },
    (err) => {
      console.log(err);
    });
  }

}
