<div class="dropdown-container">
  <div class="dropdown" [class.active]="active" (click)="focus()" #dropdownEl>
    <div class="dropdown-value" (click)="chevronClick($event)">
      <span
        *ngIf="
          (!selectedItem && !placeholderFixed) ||
          placeholderFixed ||
          selectedItem.name === 'all'
        "
        >{{ placeholder }}</span
      >
      <span
        class="selected-value"
        *ngIf="selectedItem && !placeholderFixed && selectedItem.name !== 'all'"
        >{{ selectedItem.name ? selectedItem.name : selectedItem }}</span
      >
      <i class="material-icons" [class.active]="active">expand_more</i>
    </div>
  </div>

  <div class="items" *ngIf="active" [@dropdown]>
    <input
      class="search-input"
      *ngIf="enableSearch"
      type="text"
      name="searchEl"
      placeholder="Zoek jouw school"
      (keyup.enter)="select()"
      (keyup)="getList($event)"
      (keydown)="keydownDirection($event)"
      [(ngModel)]="searchInput"
      (ngModelChange)="search($event)"
      #searchEl
      autocomplete="off"
      autofocus
    />
    <ul
      [class.scroller]="true"
      [style.max-height]="max ? 54 * max + 'px' : 'unset'"
      [class.filter]="filter"
    >
      <li
        *ngFor="let item of filteredItems | search: searchInput; let i = index"
        (click)="($event.stopPropagation); selectChosen(item)"
        [class.active]="i === selectedIndex"
        id="{{ i + 'cit' }}"
        [class.filter]="filter"
      >
        <div *ngIf="filter" class="filter-option" [class.active]="item.active">
          <span>{{ item.name }}</span>
        </div>
        <span *ngIf="!filter">{{ item.name }}</span>
      </li>
      <li
        class="no-results"
        *ngIf="(filteredItems | search: searchInput).length === 0"
      >
        Geen resultaten
      </li>
    </ul>
  </div>
</div>
