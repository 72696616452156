import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';

@Component({
  templateUrl: 'payment-link.page.html',
  styleUrls: ['payment-link.page.scss'],
})

export class PaymentLinkPageComponent implements OnInit {
  cart: Cart;
  paymentMessage = 'Hoi, zou je deze bestelling voor mij willen betalen? Dit gaat mij enorm helpen bij het leren voor het eindexamen :)';

  constructor(
    private cartService: CartService,
    private sanitizer: DomSanitizer,
    private router: Router,
    ){}

  ngOnInit() {
    this.cartService.cart$.pipe(take(1)).subscribe((cart: Cart) => {
      this.cart = cart;
    });
  }
  
  copyLink() {
    const aux = document.createElement("div");
    aux.setAttribute("contentEditable", 'true');
    aux.innerHTML = document.getElementById('link-text').innerHTML;
    aux.setAttribute("onfocus", "document.execCommand('selectAll', false,null)"); 
    document.body.appendChild(aux);
    aux.focus();
    document.execCommand("copy");
    document.body.removeChild(aux);
  }

  generateLinkWhatsapp() {
    return this.cart?.paymentLink ? this.sanitizer.bypassSecurityTrustUrl(`https://api.whatsapp.com/send?text=${this.paymentMessage} ${encodeURIComponent(this.cart.paymentLink)}`) : '';
  }

  generateLinkEmail() {
    return this.cart?.paymentLink ? this.sanitizer.bypassSecurityTrustUrl(`mailto:?subject=Betaalverzoek Examenoverzicht&body=${this.paymentMessage} ${this.cart.paymentLink}`) : '';
  }

  gotoDashboard() {
    this.cartService.clearCart();
    this.router.navigate(['/']);
  }
}
