import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'exo-oldexams',
  templateUrl: 'oldexams.html',
  styleUrls: ['oldexams.scss'],
})
export class OldexamsComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }
}
