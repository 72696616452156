import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ModalController } from "../../components/modal/modal.controller";
import { FreePresentModalComponent } from "../../components/modals/free-present/free-present";

import { AuthService } from "../../shared/auth/auth.service";
import { ExperimentService } from "../../shared/experiment/experiment.service";
import { RegisterService } from "../../shared/register/register.service";
import { CourseTypes, Subject } from "../../shared/subjects/subject";
import { SubjectService } from "../../shared/subjects/subject.service";
import { SummaryService } from "../../shared/summaries/summary.service";
import { User } from "../../shared/user/user";

import { DatalayerService } from "./../../services/datalayer/datalayer.service";

@Component({
  selector: "exo-dashboard",
  templateUrl: "dashboard.page.html",
  styleUrls: ["dashboard.page.scss"],
  animations: [
    trigger("newUser", [
      state("*", style({ opacity: 1, height: "*" })),
      transition(":leave", [
        style({ opacity: 1, transform: "*" }),
        animate("300ms ease-out", style({ opacity: 0, height: "0px" })),
      ]),
    ]),
  ],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  subjects: Subject[];
  normalSubjects: Subject[];
  extraSubjects: Subject[];
  nextSubject: Subject;
  newUser: boolean;
  loading = true;
  hasSummaries: boolean;
  sub: Subscription;
  user: User;

  constructor(
    private subjectService: SubjectService,
    private registerService: RegisterService,
    private summaryService: SummaryService,
    private datalayerService: DatalayerService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private experimentService: ExperimentService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((user: User) => {
      this.user = user;

      this.newUser = this.registerService.newUser;
      this.getSubjects();
      this.getSummaries();
      this.freePresentCheck();
    });
  }

  freePresentCheck() {
    if (
      this.user.experiments &&
      this.user.experiments[
        this.experimentService.CONVERSION_EXPERIMENT_NAME
      ] === "active"
    ) {
      this.showPresentModal();
    }
  }

  showPresentModal() {
    this.modalCtrl.setModal(FreePresentModalComponent);
  }

  getSummaries() {
    this.summaryService.hasSummaries().subscribe((hasSummaries) => {
      this.hasSummaries = hasSummaries;
    });
  }

  getSubjects() {
    this.sub = this.subjectService.getSubjects().subscribe(
      (subjects) => {
        this.normalSubjects = [];
        this.extraSubjects = [];

        if (subjects && subjects.length > 0) {
          const next = subjects.sort((a, b) => {
            return (
              new Date(a.timeslots["1"].startDate).getTime() -
              new Date(b.timeslots["1"].startDate).getTime()
            );
          })[0];

          if (new Date(next.timeslots["1"].startDate).getTime() > Date.now()) {
            this.nextSubject = next;
          }

          this.subjects = subjects.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );

          subjects.forEach((subject) => {
            if (subject.category === CourseTypes.extraCourses) {
              this.extraSubjects.push(subject);
            } else {
              this.normalSubjects.push(subject);
            }
          });
        } else {
          this.subjects = [];
          this.nextSubject = null;
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  sendDatalayer(label: string, category: string, action: string) {
    this.datalayerService.send({
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  data(event: any) {
    if (event.nickname) {
      this.user.nickname.isGenerated = false;
      this.user = { ...this.user };
    }
    if (event.school) {
      this.user.school = event.school;
      this.user = { ...this.user };
    }
  }
}
