<div class="widget">
  <header>
    <span (click)="out.emit({back: true})" class="fa fa-arrow-left"></span>
    <p>Met welke vakken heb je moeite</p>
  </header>
  <div class="content">
    <exo-loader *ngIf="loading"></exo-loader>
    <div class="row" *ngIf="!loading">
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Talen a</span>
        </div>
        <ul class="list-group">
          <li class="list-group-item extra added" [class.difficult]="course.difficult" (click)="toggleCourse('Talen', i, true)" *ngFor="let course of otherCourses['Talen']; let i = index"><span>{{course.name}}</span><span>&nbsp;{{course.courseLevel}}</span></li>
          <li class="list-group-item" [class.added]="course.added" [class.difficult]="course.difficult" (click)="toggleCourse('Talen', i)" *ngFor="let course of courses['Talen']; let i = index">{{course.name}}</li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Exacte vakken</span>
        </div>
        <ul class="list-group">
          <li class="list-group-item extra added" [class.difficult]="course.difficult" (click)="toggleCourse('Exacte vakken', i, true)" *ngFor="let course of otherCourses['Exacte vakken']; let i = index"><span>{{course.name}}</span><span>&nbsp;{{course.courseLevel}}</span></li>
          <li class="list-group-item" [class.added]="course.added"  [class.difficult]="course.difficult"  (click)="toggleCourse('Exacte vakken', i)" *ngFor="let course of courses['Exacte vakken']; let i = index">{{course.name}}</li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Maatschappij vakken</span>
        </div>
        <ul class="list-group">
          <li class="list-group-item extra added" [class.difficult]="course.difficult" (click)="toggleCourse('Maatschappij vakken', i, true)" *ngFor="let course of otherCourses['Maatschappij vakken']; let i = index"><span>{{course.name}}</span><span>&nbsp;{{course.courseLevel}}</span></li>
          <li class="list-group-item" [class.added]="course.added"  [class.difficult]="course.difficult"  (click)="toggleCourse('Maatschappij vakken', i)" *ngFor="let course of courses['Maatschappij vakken']; let i = index">{{course.name}}</li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Extra vakken</span>
        </div>
        <ul class="list-group">
          <li class="list-group-item extra added" [class.difficult]="course.difficult" (click)="toggleCourse('Extra vakken', i, true)" *ngFor="let course of otherCourses['Extra vakken']; let i = index"><span>{{course.name}}</span><span>&nbsp;{{course.courseLevel}}</span></li>
          <li class="list-group-item" [class.added]="course.added"  [class.difficult]="course.difficult"  (click)="toggleCourse('Extra vakken', i)" *ngFor="let course of courses['Extra vakken']; let i = index">{{course.name}}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer">
    <button class="btn btn-primary" (click)="submit()">Volgende</button>
  </div>
</div>
