<div class="payments-block" *ngIf="paymentOptions">
  <ul>
    <li
      *ngFor="let paymentOption of paymentOptions"
      (click)="selectPaymentOption(paymentOption)"
    >
      <div>
        <img src="{{paymentOption.image.svg}}" />
        <span class="TextPaymentsOptionsName"
          >{{paymentOption?.description}}</span
        >
      </div>
      <div
        class="checkbox"
        [class.active]="paymentOption?.description === cart?.paymentOption?.description"
      ></div>
    </li>
  </ul>
</div>
