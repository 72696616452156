<div class="card">
  <header *ngIf="!notFound">Video van de dag ({{title}})</header>

  <div class="content" *ngIf="!notFound">
    <exo-loader *ngIf="!link"></exo-loader>

    <div *ngIf="link" (click)="sendDatalayer('daily-video', 'video', 'play')">
      <iframe [attr.src]="link" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</div>
