import { BaseLoginProvider } from '../entities/base-login-provider';
import { SocialUser } from '../entities/user';

declare let FB: any;

export class FacebookLoginProvider extends BaseLoginProvider {

  public static readonly PROVIDER_ID: string = 'FACEBOOK';

  constructor(private clientId: string) { super(); }

  initialize(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.loadScript(FacebookLoginProvider.PROVIDER_ID,
        '//connect.facebook.net/en_US/sdk.js',
        () => {
          FB.init({
            appId: this.clientId,
            autoLogAppEvents: true,
            cookie: true,
            xfbml: true,
            version: 'v2.12',
          });
          FB.AppEvents.logPageView();

          FB.getLoginStatus((response: any) => {
            if (response.status === 'connected') {
              const token = response.authResponse.accessToken;
              FB.api('/me?fields=name,email,picture', (res: any) => {
                const user: SocialUser = new SocialUser();

                user.id = res.id;
                user.name = res.name;
                user.email = res.email;
                user.photoUrl = `https://graph.facebook.com/${res.id}/picture?type=normal`;
                user.token = token;

                resolve(user);
              });
            }
          });
        });
    });
  }

  signIn(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      FB.login((response: any) => {
        if (response.authResponse) {
          const token = response.authResponse.accessToken;
          FB.api('/me?fields=name,email,picture', (res: any) => {
            const user: SocialUser = new SocialUser();

            user.id = res.id;
            user.name = res.name;
            user.email = res.email;
            user.photoUrl = `https://graph.facebook.com/${res.id}/picture?type=normal`;
            user.token = token;

            resolve(user);
          });
        }
      }, { scope: 'email,public_profile' });
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      FB.logout((response: any) => {
        resolve();
      });
    });
  }

}
