<div class="header">
  <img src="/assets/logo.png" alt="Examen overzicht" height="48"/>
</div>

<div class="payment-success">
  
  <div class="widget">
    <header>
      In behandeling
    </header>
    <div class="content">
      Je wordt binnen enkele ogenblikken terug gestuurd naar de app.
    </div>
  </div>

  <div class="redirect-message" [class.show]="showMessage">
    <p>Ben je nog niet terug gestuurd?</p>
    <a (click)="openApp()">Open de app</a>
  </div>

</div>
