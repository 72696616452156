<div class="modala">
  <exo-button-deprecated
    [style]="'icon-plain'"
    [icon]="'/assets/nav-icons/close.svg'"
    class="close-modal-btn"
    (click)="close()"
  ></exo-button-deprecated>

  <div>
    <span class="text-title">{{topic?.topicTitle}}</span>
    <p class="text-description" [mathjax]="topic?.html"></p>
  </div>

  <div class="modala-footer">
    <exo-btn label="Sluiten" (click)="close()"></exo-btn>
  </div>
</div>
