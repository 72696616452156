<div class="modal-popup">
  <div class="modala-header">
    <exo-button-deprecated
      [style]="'icon-plain'"
      [icon]="'/assets/nav-icons/close.svg'"
      class="close-modal"
      (click)="close()"
    ></exo-button-deprecated>
  </div>

  <h3 class="PopupTitle title">Hoe wil jij oefenen?</h3>
  <p>
    Wil jij het vak leren kennen en een idee krijgen van wat je moet weten? Kies
    dan 'verkennen'.
  </p>
  <p>
    Ben je klaar om je optimaal voor te bereiden op het eindexamen? Kies er dan
    voor om te strijden als een eindexamenbaas.
  </p>
  <div class="slider-action">
    <exo-slider
      [labels]="sliderLabels"
      [min]="0"
      [max]="thesisCategories?.length"
      [sliderValue]="1"
      (valueChange)="sliderChange($event)"
    ></exo-slider>
  </div>
  <div class="button-action">
    <exo-button
      classes="btn-regular"
      text="Start met oefenen"
      [type]="'submit'"
      (click)="startTraining()"
    ></exo-button>
  </div>
</div>
