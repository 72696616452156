import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { PaymentService } from '../../../shared/payment/payment.service';
import { PaymentOption } from '../../../shared/payment/paymentOption';

@Component({
  selector: 'exo-cart-issuers',
  templateUrl: 'issuers.html',
  styleUrls: ['issuers.scss'],
})
export class CartIssuersComponent implements OnInit, OnDestroy {
  public cart: Cart;
  private cartSubscription: Subscription;
  public paymentOptions: PaymentOption[];
  public selectedPaymentOption: PaymentOption;

  constructor(
    private paymentService: PaymentService,
    private cartService: CartService,
  ) {
  }

  ngOnInit() {
    this.cartSubscription = this.cartService.cart$.subscribe(((cart: Cart) => {
      this.cart = cart;
    }));

    this.paymentService.getPaymentOptions().subscribe((res) => {
      this.paymentOptions = res;
      this.loadPayment();
    });
  }

  loadPayment() {
    if (this.cart && this.cart.paymentOption) {
      this.selectedPaymentOption = this.paymentOptions.find((option: PaymentOption) => option.description === this.cart.paymentOption.description);
      return;
    }
    this.selectedPaymentOption = this.paymentOptions[0];
  }

  selectPaymentOption(paymentOption: PaymentOption) {
    this.selectedPaymentOption = paymentOption;
    this.cartService.setPaymentOption(paymentOption);
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
  }

}
