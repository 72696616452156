import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'exo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [
    trigger('dropdown', [
      state('*', style({ height: '*' })),
      transition(':enter', [
        style({ height: '0px' }),
        animate('300ms ease', style({ height: '*' }))
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('300ms ease', style({ height: '0px' }))
      ])
    ])
  ],
})
export class DropdownComponent implements OnInit, OnChanges {
  @ViewChild('dropdownEl') dropdownEl: ElementRef;
  @ViewChild('searchEl') searchEl: ElementRef;
  active = false;
  dontClose = false;
  selectedIndex: number = -1;
  searchInput: string = '';
  @Input() enableSearch: boolean = false;
  @Input() items: Array<any>;
  @Input() placeholder: string = 'Placeholder';
  @Input() selectedItem?: any;
  @Input() max?: number;
  @Input() placeholderFixed?: boolean = false;
  @Input() searchFunction: (term: string) => Array<any> = (term) => this.items.filter(item => item.name.toLowerCase().includes(term.toLowerCase()));
  @Input() filter: boolean = false;
  @Output() selectedItemChange = new EventEmitter();


  filteredItems: Array<any>

  constructor(
    private cdr: ChangeDetectorRef,
    private userService: UserService,
  ) { }

  @HostListener('document:click', ['$event'])
  click(event: Event) {
    if (this.dropdownEl && !this.dropdownEl.nativeElement.contains(event.target)) {
      if (!this.dontClose) this.active = false;
    }
  }

  ngOnChanges(changes: any) {
    if (this.items !== this.filteredItems) {
      this.filteredItems = changes.items.currentValue;
    }
  }

  ngOnInit() {
    this.max = this.max ? this.max : 5;
    this.filteredItems = this.items;
  }

  focus() {
    this.active = true;
    this.reset();
    this.cdr.detectChanges();
    if (this.enableSearch && this.searchEl) {
      this.searchEl.nativeElement.focus();
    }
    this.scrollToItem();
  }

  chevronClick(event: any) {
    event.stopPropagation();
    this.active = !this.active;
    if (this.active) {
      this.reset();
      this.cdr.detectChanges();
      if (this.enableSearch && this.searchEl) {
        this.searchEl.nativeElement.focus();
      }
      this.scrollToItem();
    }
  }

  reset() {
    this.selectedIndex = -1;
    this.searchInput = '';
  }

  scrollToItem() {
    if (typeof this.selectedItem !== 'undefined' && this.selectedItem !== null && !this.filter) {
      setTimeout(() => {
        this.selectedIndex = this.items.findIndex((item) => item === this.selectedItem);
        const el = document.getElementById(this.selectedIndex + 'cit');
        if (el) el.scrollIntoView(true);
      });
    }
  }

  getList(event: any) {
    if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      if (this.searchInput.length > 0) {
        this.selectedIndex = this.selectedIndex >= 0 ? this.selectedIndex : 0;
      }
      else {
        this.selectedIndex = -1;
      }
    }
  }

  selectChosen(item: any, ev: any) {
    this.selectedItemChange.emit(item);
    this.selectedItem = item;
    if (this.filter) this.dontClose = true;
    this.filteredItems = this.items;

    setTimeout(() => {
      this.active = false;
    }, 100)

  }

  select() {
    if (this.filteredItems.length > 0 && this.selectedIndex > -1) {
      this.selectedIndex = this.selectedIndex === -1 ? 0 : this.selectedIndex;
      this.selectedItemChange.emit(this.filteredItems[this.selectedIndex]);
      this.selectedItem = this.filteredItems[this.selectedIndex];
    }
    else {
      this.selectedItemChange.emit(null);
      this.selectedItem = null;
    }
    this.active = false;
    this.filteredItems = this.items;
  }

  keydownDirection(event: any) {
    switch (event.key) {
      case 'ArrowUp': {
        if (this.selectedIndex <= 0) {
          this.selectedIndex = this.filteredItems.length - 1;
        }
        else {
          this.selectedIndex = --this.selectedIndex;
        }
      } break;
      case 'ArrowDown': {
        if (this.selectedIndex >= this.filteredItems.length - 1) {
          this.selectedIndex = 0;
        }
        else {
          this.selectedIndex = ++this.selectedIndex;
        }
      } break;
    }
    if (this.selectedIndex > -1) {
      const el = document.getElementById(this.selectedIndex + 'cit');
      if (el) el.scrollIntoView(false);
    }

  }

  search(event) {
    // this.filteredItems = this.searchFunction(event);
    this.userService.getSchools(event).subscribe((list) => {
      this.filteredItems = list;
    });
  }

}
