import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { DeviceService } from "../../components/device/device.service";
import { RegisterService } from "../../shared/register/register.service";
import { Subject } from "../../shared/subjects/subject";
import { Device } from "../../shared/user/device";
import {
  AccountType,
  Gender,
  RegistrationGoal,
  User,
  UserEditProfile,
} from "../../shared/user/user";
import { UserService } from "../../shared/user/user.service";

@Component({
  templateUrl: "register.page.html",
  styleUrls: ["register.page.scss"],
})
export class RegisterPageComponent implements OnInit, OnDestroy {
  progress = 0;
  state = 1;
  registerCredentials: { email: string; password: string } = {
    email: null,
    password: null,
  };
  socialToken: string;
  provider: string;
  incr = 100 / 10;
  device = new Device();
  subjects: Subject[];

  verify = false;

  accountType: AccountType;
  gender: Gender;
  registrationGoal: RegistrationGoal;
  difficultSections: string[];
  difficultSubjects: string[];
  courseLevel;
  sub: any;

  courses: any;
  otherCourses: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private registerService: RegisterService,
    private userService: UserService,
    private deviceService: DeviceService
  ) { }

  ngOnInit() {
    this.progress += this.incr;
    this.deviceService.getFingerprint().then((device) => {
      this.device = device;
    });

    this.sub = this.route.queryParams.subscribe((params) => {
      if (params["verify"]) {
        this.progress = this.incr * 6;
        this.verify = true;
        this.registerCredentials.email = params["email"];
        this.state = 7;
      } else if (params["accessToken"] && params["provider"]) {
        this.socialToken = params["accessToken"];
        this.provider = params["provider"];
      } else if (params["email"] && params["password"]) {
        this.registerCredentials.email = params["email"];
        this.registerCredentials.password = params["password"];
      } else {
        this.router.navigate(["login"], { queryParams: { register: true } });
      }
    });
  }

  patchUser() {
    const userEditProfile: UserEditProfile = {
      accountType: this.accountType || AccountType.student,
      gender: this.gender,
      courseLevel: this.courseLevel,
      subjects: this.subjects,
      difficultSubjects: this.difficultSubjects,
      difficultSections: this.difficultSections,
      registrationGoal: this.registrationGoal,
    };

    this.userService.patchUser(userEditProfile).subscribe(
      () => {
        this.router.navigate(["dashboard"]);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  registerUser() {
    const user: User = {
      accessToken: this.socialToken,
      provider: this.provider ? this.provider.toLowerCase() : null,
      email: this.registerCredentials.email,
      password: this.registerCredentials.password,
      accountType: this.accountType || AccountType.student,
      difficultSections: this.difficultSections,
      difficultSubjects: this.difficultSubjects,
      registrationGoal: this.registrationGoal,
      subjects: this.subjects,
      courseLevel: this.courseLevel,
    };
    if (this.gender !== undefined) {
      user.gender = this.gender;
    }

    this.registerService.register(user, this.device).subscribe(
      (token) => { },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  data(event) {
    if (event.back === true) {
      if (this.verify) {
        this.router.navigate(["login"]);
      }
      this.progress -= this.incr;
      this.state--;
      return;
    }

    if (event.courses) {
      this.courses = event.courses;
    }
    if (event.otherCourses) {
      this.otherCourses = event.otherCourses;
    }

    if (event.accountType !== undefined) {
      this.accountType = event.accountType;
    }
    if (event.gender !== undefined) {
      this.gender = event.gender;
    }

    if (event.courseLevel) {
      this.courseLevel = event.courseLevel;
    }
    if (event.subjects) {
      this.subjects = event.subjects;
    }
    if (event.difficultSections) {
      const subject = this.subjects.find(subject => event.difficultSubjects[0] === subject.encodedName);
      this.difficultSubjects = [subject._id];
      this.difficultSections = event.difficultSections;
    }
    if (event.registrationGoal) {
      this.registrationGoal = event.registrationGoal;
    }
    if (event.purchased === "no") {
      this.state++;
    }

    switch (this.state) {
      case 4:
        if (
          this.accountType === AccountType.parent ||
          this.accountType === AccountType.teacher
        ) {
          this.state = 7;
          this.data("reload");
        } else {
          this.state++;
          this.progress += this.incr;
        }
        break;
      case 6:
        this.state++;
        this.progress += this.incr;
        this.data("reload");
        break;
      case 7:
        if (event === "next") {
          this.state++;
          this.progress += this.incr;
        } else {
          if (this.provider) {
            this.patchUser();
          } else {
            this.registerUser();
          }
        }
        break;

      case 9:
        this.router.navigate(["dashboard"]);
        break;

      default:
        this.state++;
        this.progress += this.incr;
    }
  }
}
