import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from "lodash";

import { DatalayerService } from '../../../services/datalayer/datalayer.service';
import { Subject } from '../../../shared/subjects/subject';
import { SummaryProgressService } from '../../../shared/summary-progress/summary-progress.service';

@Component({
  selector: 'exo-dashboard-continue',
  templateUrl: 'dashboard-continue.html',
  styleUrls: ['dashboard-continue.scss'],
})

export class DashboardContinueComponent implements OnInit {
  @Input() subjects: Subject[];
  summaryProgress: any[];
  subjectProgress: any[];

  constructor(
    private router: Router,
    private datalayerService: DatalayerService,
    private summaryProgressService: SummaryProgressService,
  ) { }

  ngOnInit() {
    this.getSummaryProgress();
  }

  async getSummaryProgress() {
    this.summaryProgress = await this.summaryProgressService.loadProgress();
    const tempSubjectProgress = _.groupBy(this.summaryProgress, (progress) => progress.encodedSubjectName);

    this.subjectProgress = [];
    Object.entries(tempSubjectProgress).forEach(entry => {
      const [key, value] = entry;
      this.subjectProgress.push({
        subject: this.subjects?.find((subject) => subject.encodedName === key),
        progresses: value,
      });
    });
  }

  continueSubject(progress) {
    const lastProgress = progress.progresses.slice(-1)[0];

    this.router.navigate(["/subject", lastProgress.encodedSubjectName, "summary"], {
      queryParams: { section:lastProgress.firstUnread.encodedSectionName },
    });
  }
}
