<div class="container">
  <div class="row">
<div class="tada">
  <div class="content">

    <div class="badges-grid">
      <ul class="badges-list">
        <ng-container *ngFor="let superBadge of superBadges; index as i">
          <li>
            <exo-badge [userBadge]="getCurrentBadge(superBadge)" (click)="openBadge(getCurrentBadge(superBadge))"></exo-badge>
          </li>
        </ng-container>
      </ul>
    </div>
    
  </div>  

</div>  

</div>  
</div>  