import { Token } from '../auth/token';
import { Product } from '../products/product';
import { Subject } from '../subjects/subject';

import { Device } from './device';

export class User {
  __v?: number;
  _id?: string;
  email: string;
  password: string;
  courseLevel: string;
  created?: string;
  subjects: Subject[];
  devices?: Device[];
  verified?: number;
  name?: string;
  token?: Token;
  role?: Role;
  profileImage?: string;
  accessToken?: string;
  provider?: string;
  products?: Product[];
  productBundles?: Product[];

  accountType: AccountType;
  gender?: Gender;
  nickname?: Nickname;
  registrationGoal: RegistrationGoal;
  difficultSections: string[];
  difficultSubjects: string[];
  school?: School;

  socialAuthMethods?: {
    id: string;
    provider: string;
  }[];

  experiments?: {
    [key: string]: string;
  };
}

export class RegistrationGoal {
  studentTypes?: string[];
  preparation?: number;

  constructor() {
    this.preparation = 5;
    this.studentTypes = [];
  }
}

export enum Role {
  admin = 'admin',
  user = 'user',
}

export enum AccountType {
  student,
  teacher,
  parent,
}

export enum Gender {
  male,
  female,
  other,
  unknown,
}

export class Nickname {
  value: string;
  isGenerated: boolean;

  constructor() {
    this.value = '';
    this.isGenerated = false;
  }
}

export class School {
  _id?: string;
  name: string;
}

export class UserEditProfile {
  email?: string;
  name?: string;

  accountType?: AccountType;
  gender?: Gender;
  nickname?: string;
  schoolId?: string;

  courseLevel?: string;
  subjects?: any[];
  difficultSubjects?: string[];
  difficultSections?: string[];
  registrationGoal?: RegistrationGoal;

  password?: string;
  curpassword?: string;
}
