import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '../../../../components/modal/modal.controller';
import { BuyableModalComponent } from '../../../../components/modals/buyable-modal/buyable-modal.component';
import { TrainingChoiceModalComponent } from '../../../../components/modals/training-choice/training-choice';
import { Subject } from '../../../../shared/subjects/subject';
import { SummaryChapter, SummarySection, SummarySectionTraining } from '../../../../shared/summaries/summary';
import { BuyableType } from '../../../../shared/training/buyable';
@Component({
  selector: 'exo-summary-results',
  templateUrl: 'results.html',
  styleUrls: ['results.scss'],
})
export class SummaryResultsComponent implements OnInit {
  @Input() subject: Subject;
  @Input() isTrainingPreview = true;
  @Input() summarySectionTraining: SummarySectionTraining;
  @Input() section: SummarySection;
  @Input() chapter: SummaryChapter;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
  }

  openTraining() {
    if (this.isTrainingPreview && !this.section.preview) {
      this.openBuyableModal();
      return;
    }

    this.modalCtrl.setModal(TrainingChoiceModalComponent,
      {
        subject: this.subject,
        encodedChapterName: this.chapter.encodedChapterName,
        section: this.section,
      },
    );
  }

  openBuyableModal() {
    this.modalCtrl.setModal(BuyableModalComponent,
      {
        subject: this.subject,
        buyableType: BuyableType.training,
      },
    );
  }
}
