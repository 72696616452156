import { Section } from "./section";

export class Course {
  category: string;
  code: string;
  color: string;
  courseLevel: string;
  name: string;
  sku: string[];
  subjectImage: string;
  startDate: string;
  endDate: string;
  hasSummary: boolean;
  hasExercise: boolean;
  timeslots: {
    "1": {
      startDate: string;
      endDate: string;
    };
    "2": {
      startDate: string;
      endDate: string;
    };
  };
  _id: string;
  encodedName: string;
}

export class Subject extends Course {
  grade: number;
}

export class SubjectWithSections extends Course {
  sections: Section[];
}

export const CourseTypes = {
  languageCourses: "Talen",
  exactCourses: "Exacte vakken",
  societalCourses: "Maatschappij vakken",
  extraCourses: "Extra vakken",
};
