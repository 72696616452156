import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SubjectService } from '../../../shared/subjects/subject.service';
import { Tip } from '../../../shared/tips/tip';
import { TipsService } from '../../../shared/tips/tips.service';

@Component({
  selector: 'exo-tips',
  templateUrl: 'tips.html',
  styleUrls: ['tips.scss'],
})
export class TipsComponent implements OnInit {
  tips: Tip[];
  loading = false; // not used for now
  notFound = false;

  constructor(
    private route: ActivatedRoute,
    private tipsService: TipsService,
    private subjectService: SubjectService,
  ) { }

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');

      this.subjectService.getSubjectBySlug(slug).subscribe((subject) => {
        this.getTips(subject._id);
      });
    });
  }

  getTips(id: string) {
    this.tipsService.getTips(id).subscribe((tips) => {
      this.tips = tips;
    },
    (err) => {
      if (err.status === 404) {
        this.loading = false;
        this.notFound = true;
      }
    });
  }
}
