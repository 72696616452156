import { PaymentOption, User } from '../payment/paymentOption';
import { Product } from '../products/product';
import { ProductBundle } from '../products/productBundle';

export class Cart {
  items: ProductBundle[];
  shippingAdres: any;
  discount: Discount;
  price: {
    shippingCost: number;
    subTotal: number;
    grandTotal: number;
  };
  status: any;
  user?: User;
  paymentId?: string;
  paymentOption?: PaymentOption;
  paymentIssuer?: string;
  paymentLink?: string;
  userId?: string;

  constructor() {
    this.items = [];
    this.price = {
      shippingCost: 0,
      subTotal: 0,
      grandTotal: 0,
    };
    this.shippingAdres = {};
    this.status = null;
    this.userId = '';
  }
}

export interface Discount {
  code: string;
  amount: number;
  description: string;
}
