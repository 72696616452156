import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BadgeCtrl {

  public badgeVisible = false;
  public badgeChanged = new Subject<any>();
  badgeChanged$ = this.badgeChanged.asObservable();

  constructor(
  ) { }

  setBadge(badge: any) {
    this.badgeChanged.next(badge);
    this.badgeVisible = true;
  }

  closeBadge() {
    this.setBadge(null);
    this.badgeVisible = false;
  }

}
