import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccountType, User } from '../../../shared/user/user';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'exo-choose-usertype',
  templateUrl: 'choose-usertype.html',
  styleUrls: ['choose-usertype.scss'],
})
export class ChooseUsertypeComponent implements OnInit {
  @Input() user: User;
  @Input() mode: string;
  @Output() out = new EventEmitter<any>();

  accountTypes: any[];

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.accountTypes = [
      {
        name: 'Leerling',
        type: AccountType.student,
      },
      {
        name: 'Leraar',
        type: AccountType.teacher,
      },
      {
        name: 'Ouder',
        type: AccountType.parent,
      },
    ];
  }

  onSubmit(accountType: AccountType) {
    if (this.mode === 'popup') {
      this.userService.patchUser({ accountType }).subscribe(() => {
        this.out.emit({ accountType });
      });
      return;
    }
    this.out.emit({ accountType });
  }

}
