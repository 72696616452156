import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { SubjectService } from '../../../shared/subjects/subject.service';
import { Video } from '../../../shared/videos/video';
import { VideosService } from '../../../shared/videos/videos.service';

import { DatalayerService } from './../../../services/datalayer/datalayer.service';

@Component({
  selector: 'exo-videos',
  templateUrl: 'videos.html',
  styleUrls: ['videos.scss'],
  animations: [
    trigger('vid', [
      state('*', style({ opacity: 1, transform: 'scale(1)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(1.1)' }),
        animate('600ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('300ms ease-out', style({ opacity: 0, transform: 'scale(1.1)' })),
      ]),
    ]),
  ],
})
export class VideosComponent implements OnInit {
  videos: Video[] = [];

  loading: boolean;
  notFound = false;
  showVid = false;

  curVid: Video;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.showVid = false;
  }

  constructor(
    private route: ActivatedRoute,
    private videosService: VideosService,
    private sanitizer: DomSanitizer,
    private subjectService: SubjectService,
    private datalayerService: DatalayerService,
  ) { }

  ngOnInit() {
    this.loading = true;

    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');

      this.subjectService.getSubjectBySlug(slug).subscribe((subject) => {
        this.getVideos(subject._id);
      });
    });
  }

  getVideos(id: string) {
    this.videosService.getVideos(id).subscribe((videos) => {
      for (const video of videos) {
        const regex = /(youtu(?:\.be|be\.com)\/(?:.*v(?:\/|=)|(?:.*\/)?)([\w\'-]+))/i;
        video.ytId = video.link.match(regex)[2];
        video.link = video.link.replace('watch?v=', 'embed/');
        video.safeLink = this.sanitizer.bypassSecurityTrustResourceUrl(`${video.link}?autoplay=1&showinfo=0&controls=1`);
        this.videos.push(video);
      }

      this.loading = false;
    },
    (err) => {
      if (err.status === 404) {
        this.loading = false;
        this.notFound = true;
      }
    });
  }

  showVideo(vid: Video) {
    this.curVid = vid;
    this.showVid = true;
    this.sendDatalayer();
  }

  sendDatalayer() {
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: 'video',
      eventAction: 'play',
      eventLabel: 'course-video',
    });
  }

}
