import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';

import { User } from '../user/user';

import { Token } from './../auth/token';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  public isRefreshingToken = false;
  public tokenSubject: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);

  private accountState: BehaviorSubject<any> = new BehaviorSubject<any>(this.getToken() ? 'connected' : null);
  public accountState$ = this.accountState.asObservable();

  public user: BehaviorSubject<any> = new BehaviorSubject<User>(null);
  public user$ = this.user.asObservable();

  constructor(
    private http: HttpClient,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | any> {
    if (request.url.includes('/user/login')) {
      return next.handle(request);
    }

    const token = this.getToken();

    if (token && !request.url.includes('/auth/refresh')) {
      const dateNow = new Date().getTime();
      const tokenExpirationDate = new Date(token.expirationDate).getTime();
      const delta = tokenExpirationDate - dateNow;

      if (delta < 10000) {
        return this.handleError401(request, next);
      }
    }

    return this.handleRequest(request, next);
  }

  handleRequest = (request, next) => {
    return next.handle(this.addToken(request, this.getToken())).pipe(
      catchError((err: any) => {
        if (err.status === 401) { return this.handleError401(request, next); }
        if (err.status === 404 && err.url.includes('/auth/refresh')) { return this.onError(); }
        return throwError(err);
      },
    ));
  }

  private addToken(req: HttpRequest<any>, token: Token): HttpRequest<any> {
    if (token) {
      return req.clone({ setHeaders: { Authorization: `Bearer ${token.token}` } });
    }
    return req;
  }

  private handleError401(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.getToken()) {
      this.onError();
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.refreshToken(this.getToken()).pipe(
        switchMap((newToken: Token) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(req, newToken));
          }
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
      );
    }

    return this.tokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap((token: Token) => {
        return next.handle(this.addToken(req, token));
      },
    ));
  }

  public refreshToken(token: Token) {
    return this.http.post<Token>(`/auth/refresh`, { refreshToken: token.refreshToken }).pipe(
      tap((res: Token) => {
        this.setToken(res);
      }),
    );
  }

  public getToken(): Token {
    const token = localStorage.getItem('token');
    if (token) {
      return JSON.parse(localStorage.getItem('token'));
    }
    return null;
  }

  public setToken(token: Token) {
    localStorage.setItem('token', JSON.stringify(token));
    this.accountState.next('connected');
  }

  public removeToken() {
    localStorage.removeItem('token');
    this.isRefreshingToken = false;
    this.accountState.next('disconnected');

  }

  private onError() {
    this.removeToken();
    this.tokenSubject.next(null);
    return EMPTY;
  }
}
