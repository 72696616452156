<div class="widget" [class.popup]="mode === 'popup'">
  <header>
    <span *ngIf="mode !== 'popup'" (click)="out.emit({back: true})" class="fa fa-arrow-left"></span>
    <p>Ik ben een</p>
    <span *ngIf="mode === 'popup'" style="left: initial; right: 30px" (click)="out.emit({dismiss: true})" class="fa fa-times"></span>
  </header>
  <div class="content">
    <exo-loader *ngIf="!genders"></exo-loader>
    <button *ngFor="let gender of genders" class="btn {{'btn-primary'}}" (click)="onSubmit(gender.type)">{{gender.name}}</button>
    <button *ngIf="!user?.gender" class="btn {{'btn-primary'}}" (click)="onSubmit(null)">Overslaan</button>
  </div>
</div>
