import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Subject } from '../../../shared/subjects/subject';

import { SubjectService } from './../../../shared/subjects/subject.service';
import { ModalController } from './../../modal/modal.controller';

@Component({
  selector: 'exo-close-training',
  templateUrl: 'close-training.html',
  styleUrls: ['close-training.scss'],
})
export class CloseTrainingModalComponent implements OnInit, OnDestroy {
  subejctSubcription: Subscription;
  subject: Subject;

  constructor(
    private subjectService: SubjectService,
    private router: Router,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.subejctSubcription = this.subjectService.subjectChange$.subscribe((subject: Subject) => {
      this.subject = subject;
    });
  }

  public exit() {
    this.router.navigate(['/subject', this.subject.encodedName]);
    this.close();
  }

  public close() {
    this.modalCtrl.closeModal();
  }

  ngOnDestroy(): void {
    this.subejctSubcription.unsubscribe();
  }

}
