<div class="widget" [class.popup]="mode === 'popup'">
  <header>
    <p>Voeg je school toe</p>
    <span style="left: initial; right: 30px" (click)="out.emit({dismiss: true})" class="fa fa-times"></span>
  </header>

  <div class="content">
    <exo-loader [class.active]="!loaded"></exo-loader>
    <p>
      Op basis van je emailadres hebben we alvast gegokt naar welke school jij gaat.
    </p>
		<form (ngSubmit)="onSubmit(school._id)">
      <exo-dropdown class="sb-10 dd-subjects" enableSearch="true" placeholder="{{school ? school?.name : 'Kies een school'}}" (selectedItemChange)="selectSchool($event)" [items]="schoolsList()" max="4.5"></exo-dropdown>
    </form>

		<div *ngIf="error" class="error">{{error}}</div>
    
  </div>

  <div class="footer">
    <button class="btn btn-cancel" (click)="out.emit({dismiss: true})">Annuleren</button>
    <button class="btn btn-primary" (click)="onSubmit(school._id)">Toevoegen</button>
  </div>
</div>
