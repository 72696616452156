import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { Subject } from '../../../shared/subjects/subject';
import { Training } from '../../../shared/training/training';
import { UserAnswer } from '../../../shared/training/user-answer';

import { ModalController } from './../../../components/modal/modal.controller';
import { CloseTrainingModalComponent } from './../../../components/modals/close-training/close-training.component';
import { PictureModalComponent } from './../../../components/modals/picture/picture.component';
import { ReportModalComponent } from './../../../components/modals/report/report.component';
import { SubjectService } from './../../../shared/subjects/subject.service';
import { AnswerState } from './../../../shared/training/answer-state';
import { Question } from './../../../shared/training/question';
import { Rating } from './../../../shared/training/rating';
import { Thesis } from './../../../shared/training/thesis';
import { TrainingService } from './../../../shared/training/training.service';
import { exerciseAnimation } from './../exercise.animations';

@Component({
  selector: 'exo-question-exercise',
  templateUrl: 'question.page.html',
  styleUrls: ['question.page.scss'],
  animations: exerciseAnimation,
})

export class QuestionTrainingPageComponent implements OnInit, OnDestroy {

  constructor(
    private subjectService: SubjectService,
    private trainingService: TrainingService,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
  ) { }

  @ViewChild('txtArea') txtArea: ElementRef;
  subject: Subject;
  thesis: Thesis;
  thesisId: string;
  training: Training;
  sub: Subscription;
  loaded = false;
  rating: Rating;
  startTime: any;
  endTime: any;
  animationDuration = 350;
  currentQuestion: Question;
  currentThesisIdx: number;
  questionCount = 0;
  questionsAnsweredCount = 0;

  currentQuestionsCount = 0;
  currentQuestionsAnsweredCount = 0;

  ngOnInit() {
    this.setThesisId();
    this.sub = combineLatest([this.subjectService.subjectChange$, this.trainingService.trainingChange$])
      .subscribe(([subject, training]) => {
        this.subject = subject;
        this.training = training;

        this.questionCount = training.questionCount;
        this.questionsAnsweredCount = training.questionsAnsweredCount;

        this.currentQuestionsCount = training.questionCount;
        this.currentQuestionsAnsweredCount = training.questionsAnsweredCount + 1;

        if (!this.loaded) {
          this.startTime = new Date();
          this.getThesis();
        }
      });
  }

  private setThesisId() {
    this.route.url.subscribe((url) => {
      this.thesisId = url[0].path;
    });
  }

  private getThesis() {
    this.trainingService.getThesis(this.thesisId).subscribe((thesis: Thesis) => {
      this.thesis = thesis;

      const currentThesisIdx = this.training.thesisIds.findIndex((id: string) => id === this.thesisId);
      this.currentThesisIdx = currentThesisIdx;

      setTimeout(() => {
        this.currentThesisIdx = currentThesisIdx + 1;
      }, 0);

      this.removeAnsweredQuestions();
      this.elementReplace();
      this.loaded = true;
    });
  }

  public isSimpleQuestion(question?: Question) {
    return question && !question.title || question.title === '';
  }

  public isOpenQuestion(question: Question) {
    return question?.type === 'OpenQuestion';
  }

  public isDictationQuestion(question: Question) {
    return question?.type === 'Dictation';
  }

  public showAssist(question: Question, assistState: string) {
    if (!question.usedAssist) {
      question.userAnswer = this.userAnswer(question);
      question.userAnswer.state = AnswerState.TIP;
      question.assistState = question.assistState === assistState ? null : assistState;
      question.usedAssist = true;
    }
  }

  public answerQuestion(question: Question, choice: string) {
    if (this.isOpenQuestion(question) && (question.scoredPoints === undefined && question.pointsExplanation) ) {
      return;
    }
    if (question.selectedChoice) {
      return;
    }

    question.selectedChoice = choice;
    question.userAnswer = this.userAnswer(question);

    this.trainingService.postAnswer(question._id, question.userAnswer).subscribe((questionAnswer) => {
      if (questionAnswer.state === AnswerState.CORRECT_FIRST_TRY || questionAnswer.state === AnswerState.PASSED) {
        question.isCorrect = true;
      }
      else {
        question.explanation = questionAnswer.explanation;
        question.isCorrect = false;
      }
      question.isAnswered = true;
      questionAnswer.isAnswered = true;

      this.startTime = new Date();

      this.questionCount = questionAnswer.questionCount;
      this.questionsAnsweredCount = questionAnswer.questionsAnsweredCount;

      this.currentQuestion = questionAnswer;

      if (this.thesis.questions.length > 1 && !this.isLastThesisQuestion()) {
        this.currentQuestionsAnsweredCount = this.questionsAnsweredCount + 1;
      }
    });
  }

  public displayLetter(index: number) {
    return String.fromCharCode(index + 65);
  }

  public setRating(rating: number) {
    if (!this.rating) { this.rating = new Rating(); }
    this.rating.rating = rating;

    setTimeout(() => {
      if (this.txtArea) { this.txtArea.nativeElement.focus(); }
    }, 0);
  }

  public setOpenQuestionRating(question: Question, rating: number) {
    question.scoredPoints = rating;
  }

  public nextQuestion() {
    if (this.training.thesisIds[this.training.thesisIds.length - 1] === this.thesisId || this.training.thesisIds.length === 0) {
      this.router.navigate(['/training', this.subject.encodedName, 'results'], { skipLocationChange: true });
    }
    else {
      const currentIndex = this.training.thesisIds.indexOf(this.thesisId);
      const nextIndex = (currentIndex + 1) % this.training.thesisIds.length;
      this.router.navigate(['/training', this.subject.encodedName, this.training.thesisIds[nextIndex]], { skipLocationChange: true });
      this.loaded = false;
      setTimeout(() => { this.resetThesis(); }, 0);
    }
    this.postRating();

    this.currentQuestionsAnsweredCount = this.thesis.questions.length > 0 ? this.questionsAnsweredCount + 1 : this.questionsAnsweredCount;
  }

  private postRating() {
    if (this.rating && this.rating.rating > 0) {
      this.trainingService.postRating(this.thesisId, this.rating).subscribe(() => {
        this.rating = null;
      });
    }
  }

  public report() {
    this.modalCtrl.setModal(ReportModalComponent, { thesis: this.thesis, subject: this.subject });
  }

  public close() {
    this.modalCtrl.setModal(CloseTrainingModalComponent);
  }

  private userAnswer(question: Question): UserAnswer {
    this.endTime = new Date();
    return {
      thesisId: this.thesisId,
      userAnswer: question.answerInput || question.selectedChoice,
      encodedSubjectName: this.subject.encodedName,
      state: AnswerState.UNANSWERED,
      startDate: this.startTime,
      endDate: this.endTime,
      scoredPoints: question.scoredPoints,
      encodedChapterName: this.training.encodedChapterName
    };
  }

  private resetThesis() {
    setTimeout(() => {
      this.startTime = new Date();
      this.getThesis();
    }, this.animationDuration);
  }

  private elementReplace() {
    setTimeout(() => {
      const divs = this.elementRef.nativeElement.querySelectorAll('.html-img');
      divs.forEach.call(divs, (div: Element) => {
        const imageLink = div.querySelector('img').getAttribute('src');
        div.insertAdjacentHTML('beforeend', '<button class="btn-enlarge dynamic-bgcolor">Vergroten</button>');
        div.querySelector('button').addEventListener('click', () => this.openPicture(imageLink));
      });
    }, 0);
  }

  public openPicture(imageLink: string) {
    this.modalCtrl.setModal(PictureModalComponent, { imageLink });
  }

  public canDeactivate() {
    this.loaded = false;
    return timer(this.animationDuration).pipe(mapTo(true)).toPromise();
  }

  private removeAnsweredQuestions() {
    const lastQuestionIndex = this.thesis.questions.findIndex((question: Question) => question._id === this.training.lastQuestionId);
    if (lastQuestionIndex > 0) {
      this.thesis.questions = this.thesis.questions.slice(lastQuestionIndex);
    }
  }

  public answerOpenQuestion(question: Question) {
    question.submitted = true;
    if (question.answerInput) {
      question.openQuestionIsAnswered = true;
    }
  }

  public isLastThesisQuestion() {
    const currentQuestionIsAnswered = !!this.currentQuestion?.isAnswered;
    const currentQuestionIsLastQuestion = this.thesis.questions.slice(-1)[0]._id === (this.currentQuestion?.questionId);
    return currentQuestionIsAnswered && currentQuestionIsLastQuestion;
  }

  public progressWidth() {
    if (this.questionCount && this.questionsAnsweredCount) {
      return `${(this.questionsAnsweredCount) / (this.questionCount) * 100}%`;
    }
    if (this.questionCount === 0) {
      return '100%';
    }
    return '0%';
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
