<div class="card">
  <header *ngIf="!notFound">
    Tip van de dag
  </header>
  <div class="content" *ngIf="!notFound">
    <div class="title">
      {{title}}
    </div>
    <div class="text">
      {{description}}
    </div>
  </div>
</div>