import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Question } from './question';
import { Rating } from './rating';
import { Report } from './report';
import { Results } from './results';
import { Section } from './section';
import { Thesis } from './thesis';
import { ThesisCategory } from './thesis-category';
import { Training } from './training';
import { UserAnswer } from './user-answer';

@Injectable()
export class TrainingService {
  private trainingChange: BehaviorSubject<Training> = new BehaviorSubject<Training>(null);
  public trainingChange$ = this.trainingChange.asObservable();

  private sectionsChange: BehaviorSubject<Section[]> = new BehaviorSubject<Section[]>(null);
  public sectionsChange$ = this.sectionsChange.asObservable();

  private thesisChange: BehaviorSubject<Thesis> = new BehaviorSubject<Thesis>(null);
  public thesisChange$ = this.thesisChange.asObservable();

  constructor(private http: HttpClient) {}

  getTrainingProgress(encodedSubjectName: string): Observable<Training> {
    return this.http.get<Training>(`/training/${encodedSubjectName}`);
  }

  postTraining(encodedSubjectName: string, sections: string[], encodedChapterName: string, thesisCategory?: string): Observable<Training> {
    const options = { headers: new HttpHeaders(), params: new HttpParams() };
    options.headers = options.headers.append('Authorization', 'true');
    if (sections) {
      options.params = options.params.append('encodedSectionName', sections.join(','));
    }

    options.params = options.params.append('encodedChapterName', encodedChapterName);

    if (thesisCategory) {
      options.params = options.params.append('category', thesisCategory);
    }
    return this.http.post<Training>(`/training/${encodedSubjectName}`, {}, options);
  }

  getThesis(thesisId: string): Observable<Thesis> {
    return this.http.get<Thesis>(`/thesis/${thesisId}`);
  }

  saveResults(encodedSubjectName: string): Observable<Results> {
    return this.http.post<Results>(`/subjects/${encodedSubjectName}/training/results`, {});
  }

  getLastResult(encodedSubjectName: string): Observable<Results> {
    return this.http.get<Results>(`/subjects/${encodedSubjectName}/training/last-result`);
  }

  getLastResults(encodedSubjectName: string): Observable<Results> {
    return this.http.get<Results>(`/subjects/${encodedSubjectName}/training/last-results`);
  }

  postReport(thesisId: string, report: Report): Observable<Rating> {
    const _report = Object.assign({}, report);
    _report.reasons = Array.from(report.reasons);
    return this.http.post<Rating>(`/thesis/${thesisId}/report`, _report);
  }

  postAnswer(questionId: string, userAnswer: UserAnswer): Observable<Question> {
    return this.http.post<Question>(`/question/${questionId}/answers`, userAnswer);
  }

  postRating(thesisId: string, rating: Rating): Observable<Rating> {
    return this.http.post<Rating>(`/thesis/${thesisId}/rating`, rating);
  }

  getThesisCategories(): Observable<ThesisCategory[]> {
    return this.http.get<ThesisCategory[]>(`/thesis-categories`);
  }


  setCurrentSections(sections: Section[]) {
    this.sectionsChange.next(sections);
  }

  removeCurrentSections() {
    this.sectionsChange.next(null);
  }

  setCurrentTraining(training: Training) {
    this.trainingChange.next(training);
  }

  removeCurrentTraining() {
    this.trainingChange.next(null);
  }

  setCurrentThesis(thesis: Thesis) {
    this.thesisChange.next(thesis);
  }

  removeCurrentThesis() {
    this.thesisChange.next(null);
  }
}
