import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';

import { School, User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'exo-choose-school',
  templateUrl: 'choose-school.html',
  styleUrls: ['choose-school.scss'],
})
export class ChooseSchoolComponent implements OnInit, AfterViewInit {
  @Input() user: User;
  @Input() mode: string;
  @Output() out = new EventEmitter<any>();
  @ViewChild('inputEl') inputEl: ElementRef;

  public error = '';

  public loaded = false;
  private schoolIdOld = '';

  public school: School;
  private schoolSuggestion: School;
  private schools: School[];

  searchResults = [];

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    if (this.user.school) {
      this.schoolIdOld = this.user.school._id;
    }

    forkJoin([
      this.getSchools(),
      this.getSchoolSuggestion(),
    ]).subscribe(() => {
      this.afterLoaded();
    }, (err) => {
      this.afterLoaded();
    });
  }

  afterLoaded() {
    setTimeout(() => {
      this.setSchool();
      this.loaded = true;
    }, 300);
  }

  ngAfterViewInit(): void {
    if (this.inputEl) {
      this.inputEl.nativeElement.focus();
    }
  }

  getSchools() {
    return this.userService.getSchools().subscribe((schools: School[]) => {
      this.schools = schools;
    });
  }

  getSchoolSuggestion() {
    return this.userService.getSchoolSuggestions().subscribe((schoolSuggestion: School) => {
      this.schoolSuggestion = schoolSuggestion;
    });
  }

  setSchool() {
    if (this.user.school) {
      this.school = this.user.school;
    }
    else if (this.schoolSuggestion) {
      this.school = this.schoolSuggestion;
    }
  }

  schoolsList() {
    return this.schools;
  }

  selectSchool(school: School) {
    this.school = school;
  }

  onSubmit(schoolId: string) {
    if (this.schoolIdOld === this.school._id) {
      this.out.emit({ dismiss: true });
    }
    else if (schoolId) {
      this.userService.patchUser({ schoolId: this.school._id }).subscribe((school: School) => {
        this.out.emit({ school: this.school });
        this.out.emit({ dismiss: true });
      },
      ((error: any) => {
        this.error = 'Er is iets misgegeaan';
      }));
    }
  }

}
