import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: 'cart.page.html',
  styleUrls: ['cart.page.scss'],
})

export class CartPageComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }
}
