<div class="modala" *ngIf="products.length > 0">
  <exo-button-deprecated
    [style]="'icon-plain'"
    [icon]="'/assets/nav-icons/close.svg'"
    class="close-modal-btn"
    (click)="close()"
  ></exo-button-deprecated>

  <div>
    <span class="text-title"
      >{{products.length > 1 ? 'Koop de volgende producten' : 'Koop het volgende
      product'}} van dit vak om verder te gaan met {{buyableType === 'training'
      ? 'oefenen' : 'leren'}}</span
    >
  </div>

  <ul>
    <li *ngFor="let product of products">
      <img src="{{apiUrl}}{{product.cover}}" />
      <div>
        <span class="text-name">{{product.name}}</span>
        <span class="text-description">{{product.description}}</span>
      </div>
      <exo-button
        [text]="product.price | price"
        classes="btn-regular dynamic-bgcolorf"
        (click)="addToCart(product)"
      ></exo-button>
    </li>
  </ul>

  <span class="text-no-online-exam" *ngIf="noOnlineExam"
    >Digitaal oefenen niet beschikbaar bij dit vak</span
  >

  <div class="buyable-block-btn">
    <exo-button
      text="Sluiten"
      classes="btn-regular dynamic-bgcolorf"
      (click)="close()"
    ></exo-button>
  </div>
</div>
