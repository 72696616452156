import { Tip } from './tip';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class TipsService {
  private tipOfTheDay: ReplaySubject<Tip> = new ReplaySubject(1);

  constructor(private http: HttpClient) { }

  getTips(id: string): Observable<Tip[]> {
    return this.http.get<Tip[]>(`/subject/${id}/tips`);
  }

  putTip(tip: Tip): Observable<Tip> {
    return this.http.put<Tip>('/tips', tip);
  }

  deleteTip(tip: Tip): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: tip,
    };
    return this.http.delete<Tip>('/tips', options);
  }

  getTipOfTheDay(): Observable<Tip> {
    this.http.get<Tip>('/daily/tip').subscribe((tip) => {
      this.tipOfTheDay.next(tip);
    });

    return this.tipOfTheDay;
  }

  // TODO: rewrite to decent observable flow
  refreshTips(): void {
    this.getTipOfTheDay();
  }
}
