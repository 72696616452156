import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, merge, Subscription } from 'rxjs';
import * as io from 'socket.io-client';

import { environment } from '../environments/environment';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';
const isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

@Injectable()
export class TrackingService {
  isEnabled = false;
  socket;
  mousePosition = '[0, 0]';
  oldMousePosition = '[0, 0]';
  mouseListener: Subscription;
  trackingInterval;
  trackingIntervalInMinutes = 1;

  constructor(
    private auth: HttpAuthInterceptor,
    private router: Router,
  ) { }

  init() {
    if (this.isEnabled || !this.auth.getToken()) {
      return;
    }
    this.connect();
  }

  private connect() {
    const options = {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${this.auth.getToken().token}`,
          },
        },
      },
    };

    this.socket = io(environment.SOCKET_TRACKING, options);

    this.socket.on('connect_error', (err) => {
      console.log('tracking error!');
      console.log(err);
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;
    });

    this.socket.on('connect', () => {
      console.log('tracking connected!');
      this.isEnabled = true;
      this.listenMousemove();
      this.setTracking();
    });

    this.socket.on('reconnect', () => {
      console.log('tracking reconnected!');
    });

    this.socket.on('disconnect', () => {
      console.log('tracking disconnect!');
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;
    });
  }

  setTracking() {
    if (this.trackingInterval) {
      return;
    }
    this.trackingInterval = setInterval(() => {
      const currentPath = this.router.url;

      if (this.isAtTrackingRoute(currentPath) && this.oldMousePosition !== this.mousePosition) {
        this.emitTracking(currentPath);
        this.oldMousePosition = this.mousePosition;
      }

    }, this.trackingIntervalInMinutes * 60 * 1000);
  }

  isAtTrackingRoute(currentPath: string): boolean {
    const trackingRoutes = ['/subject/', '/training/'];
    return trackingRoutes.some(route => currentPath.includes(route));
  }

  /**
   * This makes sure the back-end does not incorrectly split the tracking string: {path}:{randomString}.
   * Fixes: https://coffeeit.atlassian.net/browse/EXO-3109
   */
  cleanPathFromColon(path: string) {
    return path.replace(/:/g,'');
  }

  emitTracking(currentPath: string) {
    const r = Math.random().toString(36).substring(7);
    const cleanedPath = this.cleanPathFromColon(currentPath);
    this.socket.emit('TimeTracking', `${cleanedPath}:${r}`);
  }

  listenMousemove() {
    if(!isMobile.any()) {
      this.mouseListener = fromEvent(document.body, 'mousemove').subscribe((e: any) => {
        this.mousePosition = [e.pageX, e.pageY].toString();
      });
    } else {
      const moveEvent$ = fromEvent(document.body, 'pointermove');
      const touchEvent$ = fromEvent(document.body, 'pointerdown');
      this.mouseListener = merge(moveEvent$, touchEvent$).subscribe((e: any) => {
        this.mousePosition = [e.pageX, e.pageY].toString();
      });
    }
  }

  destroy() {
    if (this.socket) {
      this.socket.close();
    }
    if (this.mouseListener) {
      this.mouseListener.unsubscribe();
    }
    clearInterval(this.trackingInterval);
    this.trackingInterval = null;
    this.isEnabled = false;
  }

}
