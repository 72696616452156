<div class="widget">
  <header>
    <span
      *ngIf="!componentMode"
      (click)="out.emit({back: true})"
      class="fa fa-arrow-left"
    ></span>
    <span
      *ngIf="componentMode"
      style="left: initial; right: 30px"
      (click)="out.emit({dismiss: true})"
      class="fa fa-times"
    ></span>
    <p>Welk onderdeel is het meest relevant voor jou?</p>
  </header>
  <div class="content">
    <div class="splitter">
      <div class="split">
        <div class="align-center">
          <img src="/assets/experimental.svg" alt="cadeau" />
          <span class="title">Hoe werkt het?</span>
          <ul>
            <li>
              Open het vak met het onderdeel dat het meest relevant is voor jou
              en selecteer dit onderdeel
            </li>
            <li>Je kunt maximaal 1 onderdeel in totaal selecteren</li>
            <li>
              Op basis van jouw keuze kunnen wij ons aanbod beter op jou
              afstemmen
            </li>
          </ul>
        </div>
      </div>
      <div class="split">
        <exo-sections-list
          [subjects]="subjects"
          [courseLevel]="courseLevel"
          (selectedSectionChange)="selectedSection=$event"
        ></exo-sections-list>
      </div>
    </div>
  </div>
  <div class="footer">
    <exo-button
      classes="btn btn-regular"
      text="Volgende"
      disabled="{{!selectedSection}}"
      (clickEvent)="onNext()"
    ></exo-button>
  </div>
</div>
