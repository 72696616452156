<div
  [hidden]="!notificationIsShown"
  class="modal-wrapper"
  [class.secondary]="isSecondary"
>
  <div
    class="modal-container"
    [@modal]="show"
    (@modal.done)="deleteComponent($event)"
  >
    <div class="modal-component" #modalComponent>
      <div class="modal">
        <div #dynamicComponentContainer></div>
      </div>
    </div>
  </div>
  <div [@backdrop]="show" class="backdrop"></div>
</div>
