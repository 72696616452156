import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../environments/environment';

@Injectable()
export class EnvironmentInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // check whether url is relative
    if (request.url.startsWith('/')) {
      const absoluteReq = request.clone({
        url: environment.API_URL + request.url,
      });
      return next.handle(absoluteReq);
    }
    return next.handle(request);
  }
}
