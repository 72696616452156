<div class="brand">
	<div class="text">
		<h1>Mijn ExamenOverzicht account</h1>
		<hr/>
		<h2>Slagen voor het eindexamen!</h2>
		<a class="btn webshop" href="https://examenoverzicht.nl" target="blank">Naar de webshop</a>
	</div>
</div>

<div class="widget">
  <header>
    Automatisch uitgelogd
  </header>

  <div class="content">
    We zien dat een ander apparaat met jouw account is ingelogd
    <br/>
    <br/> Was jij dit niet? Wijzig dan je wachtwoord.
    <br/>
    <br/>
    <button class="btn btn-primary" [routerLink]="['/login']">Terug naar login</button>
  </div>
</div>