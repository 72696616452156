<div class="notifications" [hidden]="!elShow">
  <div
    class="badge badge-modal"
    [@modal]="animateBadge"
    (@modal.done)="destroyBadge($event)"
  >
    <button (click)="closeBadge()" class="close-button">Sluiten</button>
    <img [src]="badge?.icon" />
    <span class="title">{{ badge?.name }}</span>
    <span class="description">{{ badge?.description }}</span>
    <button class="badges-button" (click)="gotoBadges()">
      Bekijk mijn badges
    </button>
  </div>

  <div class="confetti" *ngIf="animateConfetti">
    <ng-lottie
      [options]="options"
      [width]="'100%'"
      [height]="'100%'"
    ></ng-lottie>
  </div>
  <div [@backdrop]="elShow" class="backdrop"></div>
</div>
