import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';

import { Oldexam } from '../../../../shared/oldexams/oldexam';
import { OldexamsService } from '../../../../shared/oldexams/oldexams.service';

@Component({
  selector: 'exo-oldexam-list',
  templateUrl: 'oldexam-list.html',
  styleUrls: ['oldexam-list.scss'],
})
export class OldexamListComponent implements OnInit {
  oldexams: Oldexam[];
  err;

  constructor(private oldexamsService: OldexamsService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.parent.parent.paramMap.subscribe((params: ParamMap) => {
      const slug = params.get('slug');

      this.oldexamsService.getOldexams(slug).pipe(map(oldexams => oldexams.sort((a, b) => b.year - a.year))).subscribe((oldexams) => {
        this.oldexams = oldexams;

      },
      (err) => {
        if (err.status === 404) {
          this.err = 'Geen oude examens beschikbaar';
        }
        console.log(err);
      });
    });
  }
}
