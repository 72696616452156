import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'exo-choices',
  templateUrl: 'choices.html',
  styleUrls: ['choices.scss'],
})
export class ChoicesComponent implements OnInit {
  @Input() icon: string;

  constructor() {
  }

  ngOnInit() {
  }

}
