import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pipe, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Cart } from '../../../../shared/cart/cart';
import { CartService } from '../../../../shared/cart/cart.service';
import { environment } from '../../../../shared/environments/environment';
import { ProductService } from '../../../../shared/products/product.service';
import { ProductBundle } from '../../../../shared/products/productBundle';
import { Subject } from '../../../../shared/subjects/subject';

@Component({
  selector: 'exo-subject-buyables',
  templateUrl: 'buyables.html',
  styleUrls: ['buyables.scss'],
})
export class SubjectyBuyablesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() subject: Subject;
  public buyables = [];
  public products = [];
  public apiUrl = environment.API_URL;

  private cart: Cart;
  private cartSubscription: Subscription;

  constructor(
    private router: Router,
    private cartService: CartService,
    private productService: ProductService,
  ) {
  }

  ngOnInit() {
    this.cartSubscription = this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
      this.filterBuyables();
    });
  }

  ngOnChanges() {
    this.getBuyables();
  }

  getBuyables() {
    this.productService.getBuyAbleProductBundlesForUserBySubjectId(this.subject._id).subscribe((products) => {
      this.buyables = products.sort((a, b) => b.price - a.price);
      this.filterBuyables();
    });
  }

  addToCart(product) {
    this.cartService.addToCart(product);
    this.router.navigate(['/cart']);
    this.filterBuyables();
  }

  filterBuyables() {
    // Remove products that already are in the cart
    this.products = this.buyables.filter((product: ProductBundle) => {
      return !this.cart.items.find((cartItem: ProductBundle) => {
        return cartItem._id === product._id;
      });
    });
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
  }

}
