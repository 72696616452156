import { Component, OnDestroy, OnInit } from '@angular/core';

import { environment } from '../../../shared/environments/environment';
import { ExercisesService } from '../../../shared/exercises/exercises.service';
import { Product } from '../../../shared/products/product';
import { ProductService } from '../../../shared/products/product.service';
import { Subject } from '../../../shared/subjects/subject';
import { SubjectService } from '../../../shared/subjects/subject.service';
import { Summary } from '../../../shared/summaries/summary';
import { SummaryService } from '../../../shared/summaries/summary.service';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'exo-summaries',
  templateUrl: 'summaries.html',
  styleUrls: ['summaries.scss'],
})
export class SummariesComponent implements OnInit, OnDestroy {
  apiUrl = environment.API_URL;
  origin = environment.WEBSHOP_URL;
  products: Product[] = [];
  buyable = [];
  subjects: Subject[];
  loading = true;
  sub = [];

  constructor(
    private summaryService: SummaryService,
    private exerciseService: ExercisesService,
    private subjectService: SubjectService,
    private userService: UserService,
    private productService: ProductService,
  ) { }

  ngOnInit() {
    this.sub.push(this.subjectService.getSubjects().subscribe((subjects) => {
      this.subjects = subjects;
    }));

    this.sub.push(this.productService.getProducts().subscribe((products: Product[]) => {
      this.parseProducts(products);
    }));

    this.sub.push(this.summaryService.getBuyableSummaries().subscribe((summaries) => {
      summaries.map((summary: Summary) => {
        if (summary.sku.indexOf('-digitaal') || summary.sku.indexOf('-combi') || summary.sku.indexOf('-hardcopy')) {
          summary.sku = summary.sku[0].split('-', summary.sku[0].split('-').length - 1).join('-');
        }
        return summary;
      });
      this.buyable = summaries;
    }));
  }

  parseProducts(products) {
    this.products = products.map(product => ({
      ...product,
      subjectLinked: !!(typeof product.subjectId === 'string' ? product.subjectId : product.subjectId[0]),
      subjectSlug: product.encodedSubjectName
    }));
  }

  addedSummary(event: any) {
    if (this.products) {
      this.products.push(event);
    } else {
      this.products = [event];
    }
  }

  linkCourse(product: Product, index: number) {
    // tslint:disable-next-line: no-parameter-reassignment
    const subjects = this.subjects.map((subject: any) => subject = { _id: subject._id });
    subjects.push({ _id: product.subjectId });

    this.sub.push(this.userService.editSubjects(subjects).subscribe(() => {
      this.products[index].subjectLinked = true;
      this.subjectService.reloadSubjects();
    },
      (err) => {
        console.log(err);
      }));

  }

  ngOnDestroy() {
    for (const sub of this.sub) {
      sub.unsubscribe();
    }
  }

  productPath(product: any) {
    return product.subjectLinked ? ['/subject', product.subjectSlug] : null;
  }
}
