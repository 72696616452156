import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as io from 'socket.io-client';

import { environment } from '../environments/environment';
import { UserService } from '../user/user.service';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';

import { UserBadge } from './badge';
import { BadgeCtrl } from './badge-notification.controller';

@Injectable()
export class BadgesNotificationService {
  isEnabled = false;
  socket;

  constructor(
    private auth: HttpAuthInterceptor,
    private badgeCtrl: BadgeCtrl,
    private userService: UserService,
  ) { }

  init() {
    if (this.isEnabled || !this.auth.getToken()) {
      return;
    }

    this.badgesFeatureCheck().subscribe((isSupported: boolean) => {
      if (isSupported) {
        this.connect();
      }
    });
  }

  private connect() {
    const options = {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${this.auth.getToken().token}`,
          },
        },
      },
    };

    this.socket = io(environment.SOCKET_BADGES, options);

    this.socket.on('connect_error', (err) => {
      console.log('BADGE CONNECT ERROR!');
      console.log(err);
    });

    this.socket.on('connect', () => {
      this.isEnabled = true;
      console.log('badge connected!');
    });

    this.socket.on('reconnect', () => {
      console.log('badge reconnected!');
    });

    this.socket.on('disconnect', () => {
      console.log('badge disconnect!');
    });

    this.socket.on('exo.badge.completed', (userBadge: UserBadge) => {
      if (this.isEnabled) {
        const badge = {
          icon: userBadge.badge.icon,
          name: userBadge.badge.name,
          description: userBadge.badge.description,
        };
        this.badgeCtrl.setBadge(badge);
      }
    });
  }

  badgesFeatureCheck() {
    return this.userService.supportedFeatures$.pipe(map(features => features.includes('badges')));
  }

  destroy() {
    if (this.socket) {
      this.socket.close();
    }
    this.isEnabled = false;
  }

}
