<header class="top">
  <img src="/assets/logo.png" height="60"/>
</header>

<div class="widget">
  <header>
    Wachtwoord resetten
  </header>

  <div class="content">
    <form [formGroup]="passwordForm" (ngSubmit)="send()">
      <label for="password">Wachtwoord</label>
      <input class="form-control" autocomplete="new-password" type="password" formControlName="password" id="password" placeholder="Wachtwoord" />

      <label for="repeatPassword">Herhaal wachtwoord</label>
      <input class="form-control" autocomplete="new-password" type="password" formControlName="repeatPassword" id="repeatPassword" placeholder="Herhaal wachtwoord" />

      <div class="status" *ngIf="passwordForm.controls.password.errors?.minlength">
        Wachtwoord moet minimaal 8 tekens bevatten.
      </div>
      <div class="status" *ngIf="passwordForm.controls.password.errors?.pattern">
        Wachtwoord moet minimaal een letter en een speciaal teken of cijfer bevatten.
      </div>
      <div class="status" *ngIf="passwordForm.controls.password.errors?.forbiddenWord">
        Wachtwoord mag niet de woorden 'examenoverzicht', 'password' of 'wachtwoord' bevatten.
      </div>
      <div class="status" *ngIf="passwordForm.controls.password.touched && passwordForm.value.password !== passwordForm.value.repeatPassword">
        Wachtwoorden komen niet overeen.
      </div>

      <button class="btn btn-primary" type="submit" [disabled]="passwordForm.invalid || passwordForm.value.password !== passwordForm.value.repeatPassword">Verzenden</button>

    </form>
  </div>
</div>
