import { Component, Input, OnInit } from '@angular/core';

import { Subject } from '../../../shared/subjects/subject';

import { SubjectService } from './../../../shared/subjects/subject.service';
import { Report } from './../../../shared/training/report';
import { Thesis } from './../../../shared/training/thesis';
import { TrainingService } from './../../../shared/training/training.service';
import { ModalController } from './../../modal/modal.controller';

@Component({
  selector: 'exo-report',
  templateUrl: 'report.html',
  styleUrls: ['report.scss'],
})
export class ReportModalComponent implements OnInit {
  @Input() thesis: Thesis;
  @Input() subject: Subject;

  public report = new Report();
  public sent = false;

  constructor(
    private modalCtrl: ModalController,
    private trainingService: TrainingService,
  ) {
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.trainingService.postReport(this.thesis._id, this.report).subscribe(() => {
      this.sent = true;
    });
  }

  public selectReason(reason: string) {
    if (this.report.reasons.has(reason)) {
      this.report.reasons.delete(reason);
    }
    else {
      this.report.reasons.add(reason);
    }
  }

  public reasonIsActive(reason: string) {
    return this.report.reasons.has(reason);
  }

  public close() {
    this.modalCtrl.closeModal();
  }

}
