<button class="{{style}} {{size}} {{extra}} {{extra2}} {{extra3}} dynamic-bgcolor"
[class.no-radius]="noRadius"
[class.dynamic-bgcolorf]="style === 'select' && active"
[class.active]="active"
[disabled]="disabled"
(click)="onSubmit()">
  <div>
    <span *ngIf="icon" class="figure" [ngStyle]="{'background-image': 'url(' + icon + ')'}"></span>
    <span *ngIf="label" class="label" [innerHTML]="label"></span>
  </div>
  <span *ngIf="labelSecond" class="label-second"[innerHTML]="labelSecond"></span>
</button>
