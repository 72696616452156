import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { AuthService } from '../../../shared/auth/auth.service';
import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { environment } from '../../../shared/environments/environment';
import { PaymentResponse, PaymentStatus } from '../../../shared/payment/payment';
import { PaymentService } from '../../../shared/payment/payment.service';
import { DeliveryAddress } from '../../../shared/payment/paymentOption';

@Component({
  selector: 'exo-cart-shipping-form',
  templateUrl: 'shipping-form.html',
  styleUrls: ['shipping-form.scss'],
})
export class CartShippingFormComponent implements OnInit, OnDestroy {
  private user: any;
  private cart: Cart;
  private cartSubscription: Subscription;
  public shippingForm: FormGroup;
  public errorMsg: string;
  public isSubmitted;

  constructor(
    private cartService: CartService,
    private paymentService: PaymentService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.cartService.cart$.pipe(first()).subscribe((cart: Cart) => {
      if (cart.shippingAdres) {
        this.setForm(cart.shippingAdres);
      }
    });

    this.authService.getUser().pipe(first()).subscribe((user: any) => {
      this.user = {
        id: user._id,
        email: user.email,
        firstName: this.shippingForm.value.firstName,
        lastName: this.shippingForm.value.lastName,
      };
    });

    this.cartSubscription = this.cartService.cart$.subscribe(((cart: Cart) => {
      this.errorMsg = '';
      this.cart = cart;
    }));

    this.setForm();
  }

  createPayment(deliveryAddress: DeliveryAddress) {
    this.paymentService.createPayment({
      amount: {
        value: this.cart.price.grandTotal.toFixed(2).toString(),
        currency: 'EUR',
      },
      description: 'ExamenOverzicht in-app betaling',
      method: this.cart.paymentOption.id,
      order: {
        deliveryAddress,
        bundles: this.cart.items.map(product => product._id),
        user: this.user,
      },
      redirectUrl: `${environment.WEB_URL}/payment/status`,
      discountCode: this.cart.discount ? this.cart.discount.code : '',
      issuer: this.cart.paymentIssuer,
    }).subscribe((res: PaymentResponse) => {
      this.cartService.setPaymentStatus(PaymentStatus.open);
      this.cartService.setPaymentId(res.payment.id);

      if(res.payment.method === 'payment_link') {
        this.cartService.setPaymentLink(res.payment.paymentLink);
        this.router.navigate(['payment', 'link']);
        return;
      }

      window.open(res.payment._links.checkout.href, '_blank');
      this.router.navigate(['payment', 'status']);
    }, (err) => {
      switch (err.status) {
        case 500: this.errorMsg = 'Er is iets misgegaan'; break;
        default: this.errorMsg = err.error.message; break;
      }
    });
  }

  setForm(shippingAddress?: any) {
    this.shippingForm = this.formBuilder.group({
      firstName: [shippingAddress && shippingAddress.firstName || '', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]],
      lastName: [shippingAddress && shippingAddress.lastName || '', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]],
      email: [shippingAddress && shippingAddress.email || '', [Validators.required, Validators.email]],
      street: [shippingAddress && shippingAddress.street || '', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]],
      houseNumber: [shippingAddress && shippingAddress.houseNumber || '', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
      addition: [shippingAddress && shippingAddress.addition || '', [Validators.pattern(/^[a-z0-9 ,.'-]+$/i)]],
      postalCode: [shippingAddress && shippingAddress.postalCode || '', [Validators.required, Validators.pattern(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)]],
      city: [shippingAddress && shippingAddress.city || '', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]],
      address: [shippingAddress && shippingAddress.address || '', []],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    this.errorMsg = '';
    if (this.shippingForm.invalid) {
      this.errorMsg = 'Niet alle velden zijn juist ingevuld';
      return;
    }
    if (this.cart.items.length < 1) {
      this.errorMsg = 'Je winkelmand is leeg';
      return;
    }

    const formValues = this.shippingForm.value;
    this.cartService.setShippingAddress(formValues);

    const deliveryAddress = {
      country: 'Nederland',
      street: formValues.street,
      postalCode: formValues.postalCode.replace(/\s/g, '').toUpperCase(),
      city: formValues.city,
      streetNumber: formValues.houseNumber,
      addition: formValues.addition,
    };

    this.user.firstName = formValues.firstName;
    this.user.lastName = formValues.lastName;

    this.createPayment(deliveryAddress);
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }

  inputInvalid(inputName: string) {
    const formCtrl = this.shippingForm.get(inputName);
    if ((formCtrl.touched || this.isSubmitted) && formCtrl.invalid) {
      return true;
    }
    return false;
  }
}
