import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';

import { Product } from './product';
import { ProductBundle } from './productBundle';

@Injectable()
export class ProductService {
  constructor(
    private http: HttpClient,
  ) { }

  getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>('/user/products').pipe(
      map(products => products.map(product => ({ ...product, cove: environment.API_URL + product.cover }))),
    );
  }

  getProductBundles(): Observable<ProductBundle[]> {
    return this.http.get<ProductBundle[]>('/productbundles');
  }

  getProductBundlesBySubjectId(subjectId: string): Observable<ProductBundle[]> {
    return this.http.get<ProductBundle[]>(`/productbundles/${subjectId}`);
  }

  getBuyAbleProductBundlesForUser(): Observable<ProductBundle[]> {
    return this.http.get<ProductBundle[]>('/user/productbundles/buyable');
  }

  getBuyAbleProductBundlesForUserBySubjectId(subjectId: string): Observable<ProductBundle[]> {
    return this.http.get<ProductBundle[]>(`/user/productbundles/buyable/${subjectId}`).pipe(map((productBundles) => {
      productBundles.sort((a, b) => b.price - a.price);
      return productBundles;
    }));
  }

  pairProducts(accessCode: string, userId: string): Observable<any> {
    return this.http.put('/user/product/pair', { accessCode, userId });
  }

  deactivateProductCode(productCode: string): Observable<any> {
    return this.http.post('/code/deactivate', { code: productCode });
  }

  getUserProducts(userId: string): Observable<Product[]> {
    return this.http.get<Product[]>(`/user/${userId}/products`);
  }

  pairUserProduct(accessCode: string, userId: string): Observable<any> {
    return this.http.put(`/user/${userId}/product/pair`, { accessCode });
  }

  deleteUserProduct(productId: string, userId: string): Observable<any> {
    return this.http.delete(`/user/${userId}/products/${productId}`);
  }

}
