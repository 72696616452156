import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "../../../shared/subjects/subject";
import { Section } from "../../../shared/training/section";

@Component({
  selector: "exo-subject-select",
  templateUrl: "subject-select.html",
  styleUrls: ["subject-select.scss"],
})
export class SubjectSelectComponent implements OnInit {
  @Output() out = new EventEmitter<any>();
  @Input() courseLevel: string;
  @Input() componentMode
  @Input() subjects: Subject[];
  selectedSection: {
    encodedSectionName: string;
    encodedSubjectName: string;
  };

  constructor() { }

  ngOnInit() { }

  onNext() {
    this.out.emit({
      difficultSections: [this.selectedSection.encodedSectionName],
      difficultSubjects: [this.selectedSection.encodedSubjectName]
    });
  }
}
