import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { PaymentStatus } from '../../../shared/payment/payment';
import { PaymentService } from '../../../shared/payment/payment.service';

@Component({
  templateUrl: 'payment-status.page.html',
  styleUrls: ['payment-status.page.scss'],
})

export class PaymentStatusPageComponent implements OnInit {
  cart: Cart;
  paymentStatus: any;
  statusDescription: string;
  statusIcon: string;
  statusButton: string;
  private timeInterval: Subscription;

  intervalInSec = 5;
  maxIntervalTimes = 30;

  constructor(
    private paymentService: PaymentService,
    private cartService: CartService,
  ) { }

  
  ngOnInit() {
    this.setStatus(PaymentStatus.open);
    this.cartService.cart$.pipe(take(1)).subscribe((cart: Cart) => {
      this.cart = cart;
      if (cart.paymentId) {
        this.checkPaymentInterval(cart.paymentId);
      }
      else {
        this.setStatus(PaymentStatus.empty);
      }
    });
  }

  setStatus(status: PaymentStatus) {
    this.paymentStatus = status;
    switch (this.paymentStatus) {
      case PaymentStatus.paid:
        this.statusIcon = '/assets/icons/check.svg';
        this.statusDescription = 'Je betaling is succesvol, veel plezier met je aankoop!';
        this.cartService.clearCart();
        break;
      case PaymentStatus.open:
        this.statusIcon = '/assets/icons/dollar.svg';
        this.statusDescription = 'Het kan enkele minuten duren voordat je betaling is goedgekeurd.';
        break;
      case PaymentStatus.failed:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je betaling is niet gelukt, probeer het opnieuw!';
        break;
      case PaymentStatus.canceled:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je hebt de betaling geannuleerd.';
        break;
      case PaymentStatus.expired:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je betaling is verlopen.';
        break;
      case PaymentStatus.empty:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Betaling niet gevonden.';
        break;
    }
  }

  private checkPaymentInterval(paymentId: string) {
    let times = 0;
    this.timeInterval = interval(this.intervalInSec * 1000).pipe(startWith(0), switchMap(() => this.paymentService.getPayment(paymentId)))
      .subscribe((res: any) => {
        times = times + 1;

        this.setStatus(res.status);

        if (res.status !== PaymentStatus.open) {
          this.timeInterval.unsubscribe();
        }

        if (times === this.maxIntervalTimes) {
          this.setStatus(PaymentStatus.failed);
        }
      },
        err => console.log('HTTP Error', err));
  }

}
