<div class="modala">
  <div class="modala-header">
    <h3 class="title">Rapporteer vraag</h3>
    <exo-button-deprecated [style]="'icon-plain'" [icon]="'/assets/nav-icons/close.svg'" class="close-modal" (click)="close()"></exo-button-deprecated>
  </div>

  <div>

    <div class="report-title-block">
      <div>
        <h3 class="title dynamic-color">{{thesis.title}}</h3>
        <h3 class="report-title">{{thesis.exam}}</h3>
      </div>
      <span>{{subject?.name}} ({{subject?.courseLevel}})</span>
    </div>

    <div>
      <div class="sent" [class.show]="sent">
        <div>
          <span class="sent-icon" [inlineSVG]="'/assets/icons/email-sent.svg'"></span>
          <span>Je melding is verzonden!</span>
        </div>
      </div>
      <span class="report-title st-20 sb-6">Wat is er mis met deze vraag?</span>

      <exo-button-deprecated [style]="'select'" [label]="'De vraag is onjuist'" [size]="'small'" class="glued"
      [active]="reasonIsActive('incorrectQuestion')"
      (click)="selectReason('incorrectQuestion')">
      </exo-button-deprecated>

      <exo-button-deprecated [style]="'select'" [label]="'Onjuiste categorie'" [size]="'small'" class="glued"
      [active]="reasonIsActive('incorrectCatergorie')"
      (click)="selectReason('incorrectCatergorie')">
      </exo-button-deprecated>

      <exo-button-deprecated [style]="'select'" [label]="'De vraag is te moeilijk'" [size]="'small'" class="glued"
      [active]="reasonIsActive('difficultQuestion')"
      (click)="selectReason('difficultQuestion')">
      </exo-button-deprecated>

      <exo-button-deprecated [style]="'select'" [label]="'De vraag is te onduidelijk'" [size]="'small'" class="glued"
      [active]="reasonIsActive('unclearQuestion')"
      (click)="selectReason('unclearQuestion')">
      </exo-button-deprecated>
    </div>

    <span class="report-title st-8 sb-6">Toelichting</span>
    <textarea rows="7" class="txtarea sb-20" [(ngModel)]="report.explanation"></textarea>
  </div>

  <div class="modala-footer">
    <exo-button-deprecated [label]="'Verzenden'" [style]="'rounded'" (clickEvent)="onSubmit()"></exo-button-deprecated>
  </div>

</div>
