import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { DatalayerService } from './../../services/datalayer/datalayer.service';

@Component({
  selector: 'exo-profile',
  templateUrl: 'profile.page.html',
  styleUrls: ['profile.page.scss'],
  animations: [
    trigger('routerAnimation', [
      transition('* <=> *', [
        // Initial state of new route
        query(':enter',
              style({
                position: 'absolute',
                width: 'calc(100% - 30px)',
                opacity: 0,
                transform: 'translateY(10px)',
              }),
              { optional: true }),
        query(':leave',
              animate('400ms ease-in',
                      style({
                        position: 'absolute',
                        width: 'calc(100% - 30px)',
                        opacity: 0,
                        transform: 'translateY(-10px)',
                      }),
          ),
              { optional: true }),
        query(':enter',
              animate('400ms ease-out',
                      style({
                        opacity: 1,
                        transform: 'translateY(0)',
                      }),
          ),
              { optional: true }),
      ]),
    ]),
  ],
})
export class ProfilePageComponent implements OnInit {

  constructor(
    private datalayerService: DatalayerService,
  ) {

  }

  ngOnInit() {
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  sendDatalayer(label: string) {
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: 'profile-menu',
      eventAction: 'navigate',
      eventLabel: label,
    });
  }

}
