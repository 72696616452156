import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

import { PaymentStatus } from '../payment/payment';
import { Product } from '../products/product';
import { ProductBundle } from '../products/productBundle';

import { Cart, Discount } from './cart';

@Injectable()
export class CartService {
  public cart: BehaviorSubject<any> = new BehaviorSubject<Cart>(this.loadCart() || new Cart())
  public cart$ = this.cart.asObservable().pipe(switchMap(async (cart) => {
    const userId = await this.getUserId();
    return (cart.userId === userId) ? cart : new Cart();
  }));
  readonly SHIPPING_COST = 3;
  readonly FREE_SHIPPING_REQUIREMENT_PRICE = 30;
  private userId: string;

  constructor(
    private authService: AuthService,
  ) { }

  async getUserId() {
    return this.userId = (await this.authService.getStoragedUser())._id;
  }

  addToCart(product: ProductBundle) {
    const cart = this.loadCart();
    const cartProductFound = cart.items.find((cartProduct: ProductBundle) => cartProduct._id === product._id);

    if (!cartProductFound) {
      cart.items = [...cart.items, product];
      cart.price = this.calculateTotal(cart);
    }

    this.saveCart(cart);
  }

  deleteFromCart(product: ProductBundle) {
    const cart = this.loadCart();
    const cartProductFoundIndex = cart.items.findIndex((cartProduct: ProductBundle) => cartProduct._id === product._id);

    if (cartProductFoundIndex !== -1) {
      cart.items.splice(cartProductFoundIndex, 1);
      cart.price = this.calculateTotal(cart);
    }

    this.saveCart(cart);
  }

  calculateTotal(cart: Cart): any {
    let subTotal = cart.items.reduce((accumulator, { price }) => {
      return accumulator + (price);
    }, 0);

    if (cart.discount) {
      subTotal = subTotal - Math.abs(cart.discount.amount);
    }

    const shippingCost = subTotal > this.FREE_SHIPPING_REQUIREMENT_PRICE ? 0 : this.SHIPPING_COST;
    const grandTotal = subTotal + shippingCost;

    return {
      subTotal,
      shippingCost,
      grandTotal,
    };
  }

  addDiscount(discount: Discount) {
    const cart = this.loadCart();
    cart.discount = discount;
    cart.price = this.calculateTotal(cart);
    this.saveCart(cart);
  }

  clearDiscount() {
    const cart = this.loadCart();
    cart.discount = null;
    cart.price = this.calculateTotal(cart);
    this.saveCart(cart);
  }

  setPaymentOption(paymentOption) {
    const cart = this.loadCart();
    cart.paymentOption = paymentOption;
    this.saveCart(cart);
  }

  setPaymentIssuer(paymentOptionId: string) {
    const cart = this.loadCart();
    cart.paymentIssuer = paymentOptionId;
    this.saveCart(cart);
  }

  setPaymentStatus(paymentStatus: PaymentStatus) {
    const cart = this.loadCart();
    cart.status = paymentStatus;
    this.saveCart(cart);
  }

  setPaymentId(paymentId: string) {
    const cart = this.loadCart();
    cart.paymentId = paymentId;
    this.saveCart(cart);
  }

  setPaymentLink(paymentLink: string) {
    const cart = this.loadCart();
    cart.paymentLink = paymentLink;
    this.saveCart(cart);
  }

  setShippingAddress(shippingAdres: any) {
    const cart = this.loadCart();
    cart.shippingAdres = shippingAdres;
    this.saveCart(cart);
  }

  clearCart() {
    const cart = this.loadCart();
    const newCart = new Cart();
    // Keep shipping address
    newCart.shippingAdres = cart.shippingAdres;
    this.saveCart(newCart);
  }

  loadCart(): Cart {
    const cart: Cart = JSON.parse(localStorage.getItem('cart')) || new Cart();
    return (!this.userId || this.userId === cart.userId) ? cart : new Cart();
  }

  saveCart(cart: Cart) {
    cart.userId = this.userId;
    this.cart.next(cart);
    return localStorage.setItem('cart', JSON.stringify(cart));
  }
}
