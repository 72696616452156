<button
  class="btn-button {{classes}}"
  [disabled]="disabled === 'true'"
  [type]="type || 'button'"
  [style.margin]="space"
  [style.backgroundColor]="bgColor"
  (click)="onClick()"
>
  <span
    *ngIf="icon"
    class="icon"
    [ngStyle]="{ '-webkit-mask-image': 'url(' + icon + ')', '-webkit-mask-size': iconSize }"
  ></span>
  <span *ngIf="text" class="btn-text" [innerHTML]="text"></span>
  <span *ngIf="textSub" class="btn-text-sub" [innerHTML]="textSub"></span>
</button>
