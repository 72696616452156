import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { IndexWidgetService } from '../../components/index-widget/index-widget.service';
import { DefinitionService } from '../../shared/definitions/definition.service';
import { Subject } from '../../shared/subjects/subject';
import { SubjectService } from '../../shared/subjects/subject.service';

import { DatalayerService } from './../../services/datalayer/datalayer.service';

@Component({
  selector: 'exo-subject',
  templateUrl: 'subject.page.html',
  styleUrls: ['subject.page.scss'],
  animations: [
    trigger('popup', [
      state('*', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-10px)' })),
      ]),
    ]),
    trigger('routerAnimation', [
      transition('* <=> *', [
        // Initial state of new route
        query(':enter',
          style({
            position: 'absolute',
            width: 'calc(100%)',
            opacity: 0,
            transform: 'translateY(10px)',
          }),
          { optional: true }),
        query(':leave',
          animate('400ms ease-in',
            style({
              position: 'absolute',
              width: 'calc(100%)',
              opacity: 0,
              transform: 'translateY(-10px)',
            }),
          ),
          { optional: true }),
        query(':enter',
          animate('400ms ease-out',
            style({
              opacity: 1,
              transform: 'translateY(0)',
            }),
          ),
          { optional: true }),
      ]),
    ]),

  ],
})
export class SubjectPageComponent implements OnInit {
  edit = false;
  grade: number;
  date: Date;
  days: number;
  subject: Subject;
  menuActive = false;

  hasDefinitions: boolean;

  subjectMenu = [
    {
      path: 'home',
      name: 'Home',
    },
    {
      path: 'definitions',
      name: 'Begrippenlijst',
    },
    {
      path: 'exercises',
      name: 'Oefenboek',
    },
    {
      path: 'tips',
      name: 'Tips',
    },
    {
      path: 'videos',
      name: 'Videos',
    },
    {
      path: 'oldexams',
      name: 'Oude examens',
    },
  ];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.edit = false;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subjectService: SubjectService,
    private definitionService: DefinitionService,
    private indexService: IndexWidgetService,
    private datalayerService: DatalayerService,
    private changeRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      const slug = paramMap.get('slug');
      this.sendDatalayer(slug, 'courses-menu');

      this.subjectService.getSubjects().pipe(first()).subscribe((subjects) => {
        if (this.subject = subjects.find(subject => subject.encodedName === slug)) {
          this.date = new Date(this.subject.timeslots['1'].startDate);

          const oneDay = 24 * 60 * 60 * 1000;

          this.days = Math.round(Math.abs((this.date.getTime() - Date.now()) / oneDay));

          this.getDefintion(this.subject._id);
        } else {
          this.router.navigate(['dashboard']);
        }
      },
        (err) => {
          console.log(err);
        });
    });
  }

  ngAfterViewChecked(): void { this.changeRef.detectChanges(); }

  getDefintion(id: string) {
    this.definitionService.getDefinition(id).subscribe(() => {
      this.hasDefinitions = true;
    }, () => {
      this.hasDefinitions = false;
    });
  }

  editGrade() {
    this.subjectService.setGrade(this.subject._id, this.grade).subscribe((data) => {
      this.subject.grade = data.grade;
      this.edit = false;
      this.grade = null;
    },
      (err) => {
        console.log(err);
      });
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  get showWidget() {
    return Boolean(this.indexService.index && this.indexService.index.length);
  }

  sendDatalayer(label: string, category: string = 'course-menu', action: string = 'navigate') {
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

}
