import { animate, state, style, transition, trigger } from '@angular/animations';

export const modalAnimation = 
  trigger('modal', [
    state('0', style({
      opacity: 0,
      transform: 'translateY(10px)',
    })),
    state('1', style({
      opacity: 1,
      transform: 'translateY(0px)',
    })),
    transition('* => *', animate('200ms ease')),
  ]);


export const backdropAnimation = [
  trigger('backdrop', [
    state('0', style({
      opacity: 0,
    })),
    state('1', style({
      opacity: 1,
    })),
    transition('* => *', animate('200ms ease')),
  ]),
];