<div class="row">
  <div class="col-md-12">
    <div class="card spaceless">
      <header>Oude examens</header>

      <exo-loader *ngIf="!oldexams && !err"></exo-loader>

      <div class="oldexam-content">
        <div class="row-year" *ngFor="let oldexam of oldexams">
          <div class="col-4">
            <div class="year">
              <span>{{oldexam.year}}</span>
            </div>
          </div>
          <div class="col-4">
            <div [routerLink]="[oldexam.year, '1']">
              <span>1e tijdvak</span>
            </div>
          </div>
          <div class="col-4">
            <div [routerLink]="[oldexam.year, '2']">
              <span>2e tijdvak</span>
            </div>
          </div>
        </div>
      </div>

      <div class="oldexam-content" *ngIf="err">{{err}}</div>
    </div>
  </div>
</div>
