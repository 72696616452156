import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { CartService } from '../../../shared/cart/cart.service';
import { environment } from '../../../shared/environments/environment';
import { ProductService } from '../../../shared/products/product.service';
import { ProductBundle } from '../../../shared/products/productBundle';
import { BuyableType } from '../../../shared/training/buyable';
import { ModalController } from '../../modal/modal.controller';

@Component({
  selector: 'exo-buyable-modal',
  templateUrl: 'buyable-modal.html',
  styleUrls: ['buyable-modal.scss'],
})
export class BuyableModalComponent implements OnInit, OnDestroy {
  @Input() subject: any;
  @Input() buyableType: BuyableType;
  public products = [];
  public apiUrl = environment.API_URL;
  private cartSubscription: Subscription;
  public noOnlineExam = false;

  constructor(
    private modalCtrl: ModalController,
    private cartService: CartService,
    private productService: ProductService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.productService.getBuyAbleProductBundlesForUserBySubjectId(this.subject._id).pipe(first()).subscribe((buyables) => {
      let products = buyables.sort((a, b) => (a.price > b.price ? -1 : 1));

      if (this.buyableType === BuyableType.all) {
        products = buyables.filter((a) => a.description.includes('Samenvatting + ExamenChallenge'));
      }
      if (this.buyableType === BuyableType.training || products.length === 0) {
        products = buyables.filter((a) => a.description.includes('Samenvatting + ExamenChallenge') || a.description.includes('ExamenChallenge'));
      }
      if (this.buyableType === BuyableType.summary || products.length === 0) {
        products = buyables.filter((a) => (a.description.includes('Samenvatting + ExamenChallenge') || a.description.includes('Samenvatting' )) && a.description !== 'Oefenboek');
      }

      this.products = products.slice(0, 2);
    });
  }

  addToCart(product: ProductBundle) {
    this.cartService.addToCart(product);
    this.router.navigate(['/cart']);
    this.close();
  }

  close() {
    this.modalCtrl.closeModal();
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
  }
}
