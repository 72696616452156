import { Amount, Order, PaymentLink } from './paymentOption';

/* https://docs.mollie.com/reference/v2/payments-api/create-payment */
export interface Payment {
  amount: Amount;
  description: string;
  method: string;
  order: Order;
  redirectUrl: string;
  discountCode?: string;
  issuer?: string;
  webhookUrl?: string;
  locale?: string;
  metadata?: string;
  sequenceType?: string;
  customerId?: string;
  mandateId?: string;
  profileId?: string;
  testmode?: boolean;
}

export interface PaymentResponse {
  payment: {
    _links: {
      self: PaymentLink,
      checkout: PaymentLink,
      documentation: PaymentLink,
    };
    resource: string;
    id: string;
    mode: string;
    createdAt: string;
    amount: Amount;
    description: string;
    method: any;
    metadata: any;
    status: string;
    isCancelable: boolean;
    expiresAt: string;
    details: any;
    profileId: string;
    sequenceType: string;
    redirectUrl: string;
    webhookUrl: string;
    paymentLink?: string;
  };
  order: Order;
}

export enum PaymentStatus {
  open = 'open',
  paid = 'paid',
  failed = 'failed',
  canceled = 'canceled',
  expired = 'expired',
  empty = 'empty',
}
