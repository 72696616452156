import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "exo-button",
  templateUrl: "button.html",
  styleUrls: ["button.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() space: string;
  @Input() icon: string;
  @Input() iconSize = "14px";
  @Input() text: string;
  @Input() textSub: string;
  @Input() classes: string;
  @Input() type: string;
  @Input() disabled: any;
  @Input() bgColor: string;
  @Input() action: any;
  @Output() clickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  public onClick() {
    this.clickEvent.emit();
  }
}
