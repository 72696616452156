<div class="container" *ngIf="subject">

  <div class="row">

    <div class="col-md-12 course-header">
      
      <div class="course-details">
        <img src="{{subject.subjectImage}}" alt="vak-cover"/>
        <div>
          <h1 class="TextSubjectTitle">{{subject.name}}</h1>
          <span class="TextSubjectSubtitle">{{date | date:'d MMM'}} ({{days}} {{days === 1 ? 'dag' : 'dagen'}})</span>
        </div>
      </div>
   
      <div class="right" [style.padding-right]="subject.grade ? '70px' : '0px'" (click)="edit = true; editGrade(); sendDatalayer('', 'grade', 'calculate');">
        <span class="fa fa-pencil"></span>
        <span class="TextSubjectRequiredScore">{{subject.grade ? 'B' : 'Bereken b'}}enodigde cijfer</span>
        <span class="dynamic-color TextSubjectRequiredScoreGrade" [class.grade]="subject.grade">{{subject.grade | number}}</span>
        <div *ngIf="edit" [@popup] class="editGrade">
          Welk cijfer sta je voor {{subject.name}}?
          <form (ngSubmit)="editGrade()">
            <input autofocus class="form-control" [(ngModel)]="grade" name="grade" step="0.1" min="1" max="10" type="number" placeholder="Ik sta een..."
            />
            <button class="btn btn-regular" type="submit" [disabled]="grade > 10 || grade < 1 || ((grade * 10) % 1) !== 0">Pas toe</button>
          </form>
        </div>
      </div>
      
      <div *ngIf="edit" class="overlay" (click)="edit = false"></div>

    </div>

  </div>
  
  <div class="row">
    <div class="col-md-12" >
      <div class="card" [style.margin]="'24px 0 24px 0'">
        <nav [class.active]="menuActive" (click)="menuActive = !menuActive">
          <a class="menu-tab-current TextSubjectMenuItem" *ngFor="let menuItem of subjectMenu" routerLinkActive="active" [routerLink]="['/subject', subject.encodedName, menuItem.path]">{{menuItem.name}}</a>
          <a class="menu-tab TextSubjectMenuItem" *ngFor="let menuItem of subjectMenu" routerLinkActive="active" [routerLink]="['/subject', subject.encodedName, menuItem.path]">{{menuItem.name}}</a>
        </nav>
      </div> 
    </div>
  </div>  
 
  <div class="container subject-outlet" [@routerAnimation]="getRouteAnimation(route)">
    <router-outlet #route="outlet" style="position: relative;"></router-outlet>
  </div>

</div>
