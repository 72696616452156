<div class="card spaceless">
  <header>
    Inhoud
  </header>
  <div class="list">
    <div *ngFor="let category of categories; let i = index" class="category">
      <h1 class="category-name" (click)="scroll(getSelectorName(category.index), category.index)">{{category.content | stripHtml}}</h1>
      <div *ngFor="let child of category.children">
        <a class="category-sub" [style.color]="getColor(getSelectorName(i, child.index))" (click)="scroll(getSelectorName(i, child.index), child.index)">
          {{child.content | stripHtml}}
        </a>
      </div>
    </div>
  </div>
</div>