<div class="widget">
  <header>
    <span *ngIf="!component" (click)="out.emit({back: true})" class="fa fa-arrow-left"></span>
    <span *ngIf="component" style="left: initial; right: 30px" (click)="out.emit({dismiss: true})" class="fa fa-times"></span>
    <p>Kies je niveau</p>
  </header>
  <div class="content">
    <exo-loader *ngIf="!courseLevels"></exo-loader>
    <button *ngFor="let courseLevel of courseLevels" [class.hide]="courseLevel.name == userCourseLevel" class="btn btn-primary" (click)="out.emit({courseLevel: courseLevel.name})">{{courseLevel.name}}</button>
  </div>
</div>
