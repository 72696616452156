import { Component } from '@angular/core';

@Component({
  templateUrl: 'logout.page.html',
  styleUrls: ['logout.page.scss'],
})

export class LogoutPageComponent {

  constructor() {
  }

}
