import {
  animate,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as io from 'socket.io-client';

import { AuthService } from '../../shared/auth/auth.service';
import { BadgesNotificationService } from '../../shared/badges/badge.notification.service';
import { Cart } from '../../shared/cart/cart';
import { CartService } from '../../shared/cart/cart.service';
import { environment } from '../../shared/environments/environment';
import { Subject } from '../../shared/subjects/subject';
import { SubjectService } from '../../shared/subjects/subject.service';
import { TrackingService } from '../../shared/tracking/tracking.service';

import { DatalayerService } from './../../services/datalayer/datalayer.service';

@Component({
  templateUrl: 'root.page.html',
  styleUrls: ['root.page.scss'],
  animations: [
    trigger('popupAnimation', [
      state('*', style({ opacity: 1, transform: 'translateY(0)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-10px)' })),
      ]),
    ]),
    trigger('routerAnimation', [
      transition('* => *', [
        // Initial state of new route
        query(':enter',
              style({
                position: 'fixed',
                width: '100%',
                opacity: 0,
                transform: 'translateY(10px)',
              }),
              { optional: true }),
        query(':leave',
              animate('400ms ease-in',
                      style({
                        position: 'fixed',
                        width: '100%',
                        opacity: 0,
                        transform: 'translateY(-10px)',
                      }),
          ),
              { optional: true }),
        query(':enter',
              animate('400ms ease-out',
                      style({
                        opacity: 1,
                        transform: 'translateY(0)',
                      }),
          ),
              { optional: true }),
      ]),
    ]),
    trigger('menuAnimation', [
      state('*', style({ height: '*' })),
      transition(':enter', [
        style({ height: '0' }),
        animate('200ms ease-out', style({ height: '*' })),
      ]),
      transition(':leave', [
        style({ height: '*' }),
        animate('200ms ease-out', style({ height: '0' })),
      ]),
    ]),
  ],
})
export class RootPageComponent implements OnInit, OnDestroy {
  normalSubjects: Subject[];
  extraSubjects: Subject[];

  activeRoute: string;
  rootRoute: string;

  user;

  showsubjects = false;
  showdropdown = false;
  extraSubject: Subject;
  lockEnter = false;
  menu = false;
  socket;

  subscription: Subscription;

  sub: Subscription;

  cart: Cart;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.showdropdown = false;
  }

  constructor(
    private subjectService: SubjectService,
    private router: Router,
    private authService: AuthService,
    private datalayerService: DatalayerService,
    private badgesNotificationService: BadgesNotificationService,
    private trackingService: TrackingService,
    private cartService: CartService,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = event.url;
        this.rootRoute = event.url.split('/')[1];
      }
    });
  }

  ngOnInit() {
    this.sub = this.subjectService.getSubjects().subscribe((subjects) => {
      this.normalSubjects = [];
      this.extraSubjects = [];

      subjects.sort((a, b) => (a.name < b.name ? -1 : (a.name > b.name ? 1 : 0))).forEach((subject) => {
        if (subject.category === 'Extra vakken') {
          this.extraSubjects.push(subject);
        } else {
          this.normalSubjects.push(subject);
        }
      });
    },
    (err) => {
      console.log(err);
      this.normalSubjects = [];
      this.extraSubjects = [];
    });

    this.subscription = this.authService.getUser().subscribe((user) => {
      this.user = user;
      if (!this.socket) { this.initSocketIO(user); }
      this.loadCart();
    });
  }

  initSocketIO(user) {
    this.socket = io.connect(environment.API_URL, { secure: true, rejectUnauthorized: false });

    this.socket.emit('register', this.user._id);
    this.socket.on('registered', (event) => {
      this.socket.on('device', () => {
        this.router.navigate(['logout']);
      });
    });
  }

  toggleDropdown() {
    this.showdropdown = !this.showdropdown;
  }

  getRouteAnimation(outlet) {
    return outlet.activatedRouteData.animation;
  }

  logout() {
    this.trackingService.destroy();
    this.badgesNotificationService.destroy();
    this.authService.logout();
    this.router.navigate(['login']);
  }

  ngOnDestroy() {
    if (this.socket) { this.socket.disconnect(); }
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  showpopup(subject: Subject) {
    this.extraSubject = subject;
  }

  closepopup(event) {
    if ((event.target.tagName === 'DIV' && event.target.classList.contains('popup')) ||
      event.target.tagName === 'SPAN' || event.target.tagName === 'BUTTON') {
      this.extraSubject = null;
    }
  }

  loadCart() {
    this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
    });
  }

  sendDatalayer(label: string, category: string = 'main-menu', action: string = 'navigate') {
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
}
