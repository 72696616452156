<header>
  <img src="/assets/logo.png" height="60" />
  <div class="progress" [style.width]="progress + '%'"></div>
</header>

<div [ngSwitch]="state">
  <exo-choose-usertype
    *ngSwitchCase="1"
    (out)="data($event)"
  ></exo-choose-usertype>
  <exo-choose-gender *ngSwitchCase="2" (out)="data($event)"></exo-choose-gender>
  <exo-course-level *ngSwitchCase="3" (out)="data($event)"></exo-course-level>
  <exo-select-courses
    *ngSwitchCase="4"
    [courseLevel]="courseLevel"
    (out)="data($event)"
    [displayMode]="'component'"
  ></exo-select-courses>
  <exo-subject-select
    *ngSwitchCase="5"
    [courseLevel]="courseLevel"
    [subjects]="subjects"
    [courses]="courses"
    (out)="data($event)"
  ></exo-subject-select>
  <exo-choose-goals *ngSwitchCase="6" (out)="data($event)"></exo-choose-goals>

  <exo-validate-email
    *ngSwitchCase="7"
    [email]="registerCredentials.email"
    (out)="data($event)"
  ></exo-validate-email>

  <exo-already-purchased
    *ngSwitchCase="8"
    (out)="data($event)"
  ></exo-already-purchased>
  <exo-add-summaries *ngSwitchCase="9" (out)="data($event)"></exo-add-summaries>
</div>
