import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'exo-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  @Input() sliderValue: number;
  @Input() min = 1;
  @Input() max = 3;
  @Input() labels: string[];

  constructor(
  ) { }

  sliderChange(value: string) {
    // tslint:disable-next-line: radix
    this.valueChange.emit(parseInt(value));
  }
}
