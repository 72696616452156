import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Video } from './video';

@Injectable()
export class VideosService {
  private videoOfTheDay: ReplaySubject<Video> = new ReplaySubject(1);

  constructor(private http: HttpClient) { }

  getVideos(id: string): Observable<Video[]> {
    return this.http.get<Video[]>(`/subject/${id}/videos`);
  }

  putVideo(video: Video): Observable<Video> {
    return this.http.put<Video>('/videos', video);
  }

  deleteVideo(video: Video): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: video,
    };
    return this.http.delete<Video>('/videos', options);
  }

  getVideoOfTheDay(): Observable<Video> {
    this.http.get<Video>('/daily/video').subscribe(video => {
      this.videoOfTheDay.next(video);
    });

    return this.videoOfTheDay;
  }

  refreshVideos(): void {
    this.getVideoOfTheDay();
  }
}
