import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'exo-image-uploader',
  templateUrl: './image-uploader.html',
  styleUrls: ['./image-uploader.scss'],
})
export class ImageUploaderComponent implements OnInit {
  @Output() picture: EventEmitter<any> = new EventEmitter();

  dropboxClass = 'idle';
  loading = false;
  img: string;
  el: any;
  elImg: any;
  imageSet = false;
  imageFile: any;
  imageError = false;
  errorMsg = 'Er is iets fout gegaan bij het uploaden';
  errorType: any;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.el = this.elementRef.nativeElement.querySelector('.input-file');
    this.elImg = this.elementRef.nativeElement.querySelector('.image-file');
  }

  uploadClick() {
    this.el.click();
  }

  checkImage(evt) {
    let file;
    if (evt.type === 'change') {
      file = evt.target.files[0];
    } else {
      file = evt.dataTransfer.items[0].getAsFile();
    }

    const reader = new FileReader();

    const getExtension = function (filename) {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    };

    reader.onload = (data: any) => {

      const fileType = getExtension(file.name).toLowerCase();
      const image = new Image();
      image.src = data.target.result;

      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        image.onload = (imgData: any) => {

          if (image.naturalHeight <= 600 && image.naturalWidth <= 800) {
            this.imageFile = image;
            this.picture.emit(image.src);
            this.imageSet = true;
            this.imageError = false;
          } else {
            this.imageError = true;
            this.errorType = 'dimension';
          }
        };

      } else {
        this.imageError = true;
        this.errorType = 'type';
      }

    };

    if (file) {
      reader.readAsDataURL(file);
    }

  }

  dragenter(evt) {
    evt.preventDefault();
    this.dropboxClass = 'active';
  }

  dragleave(evt) {
    evt.preventDefault();
    this.dropboxClass = 'idle';
  }

  dragover(evt) {
    evt.preventDefault();
    this.dropboxClass = 'active';
  }

  drop(evt) {
    evt.preventDefault();
    this.checkImage(evt);
  }

}
