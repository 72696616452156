import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from './../../modal/modal.controller';

@Component({
  selector: 'exo-picture',
  templateUrl: 'picture.html',
  styleUrls: ['picture.scss'],
})
export class PictureModalComponent implements OnInit {
  @Input() imageLink: string;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
  }

  public close() {
    this.modalCtrl.closeModal();
  }

}
