<div class="widget">
  <exo-loader *ngIf="!user"></exo-loader>

  <header *ngIf="user">
    Profiel
    <a class="btn btn-link header-content-right" (click)="editProfile = true"
      >Pas profiel aan</a
    >
  </header>

  <div *ngIf="user" class="content">
    <div class="image" (click)="openChangePictureModal()">
      <div
        class="img"
        [style.background-image]="'url(' + (user.profileImage ? user.profileImage : './assets/profile-picture.png') + ')'"
      ></div>
      <div class="overlay">
        <span class="fa fa-pencil"></span>
      </div>
    </div>

    <div class="details">
      <div>{{user.name}} ({{user.nickname?.value}})</div>
      <div>{{user.email ? user.email : ''}}</div>
      <div class="signedup" *ngIf="created">lid sinds: {{created}}</div>
    </div>
  </div>

  <div *ngIf="user" class="content-list">
    <div class="detail" (click)="editGender = true">
      <div class="detail-label">Geslacht:</div>
      <div class="detail-answer">
        <span *ngIf="user.gender === Gender.male">Man</span>
        <span *ngIf="user.gender === Gender.female">Vrouw</span>
        <span *ngIf="user.gender === Gender.other">Anders</span>
        <span
          *ngIf="typeof(user.gender) === 'undefined' || user.gender === null"
          >Geef je geslacht aan</span
        >
      </div>
    </div>

    <div class="detail" (click)="editSchool = true">
      <div class="detail-label">School:</div>
      <div class="detail-answer">
        {{user.school ? user.school.name : 'Voeg je school toe'}}
      </div>
    </div>

    <div class="detail" (click)="editUsertype = true">
      <div class="detail-label">Account type:</div>
      <div class="detail-answer">
        <span *ngIf="user.accountType === AccountType.student">Leerling</span>
        <span *ngIf="user.accountType === AccountType.teacher">Leraar</span>
        <span *ngIf="user.accountType === AccountType.parent">Ouder</span>
        <span *ngIf="typeof(user.accountType) === 'undefined'"
          >Geef je account door</span
        >
      </div>
    </div>
  </div>
</div>

<exo-feature name="badges">
  <div class="widget">
    <header>
      Badges
      <a
        class="editcourselevel btn btn-link header-content-right"
        [routerLink]="['/profile/badges']"
        >Bekijk alle badges</a
      >
    </header>
    <div class="badges">
      <img src="/assets/badges/badge-simple.svg" />
      <span *ngIf="badgesCount > 1"
        >Je hebt {{badgesCount}} badges behaald</span
      >
      <span *ngIf="badgesCount === 1">Je hebt 1 badge behaald</span>
      <span *ngIf="badgesCount === 0">Je heb nog geen badges behaald</span>
    </div>
  </div>
</exo-feature>

<div class="widget">
  <header>
    Niveau
    <a
      class="editcourselevel btn btn-link header-content-right"
      (click)="showCourseLevelSelect = true; sendDatalayer('profile', 'button', 'adjust-level');"
      >Pas niveau aan</a
    >
  </header>
  <div class="content">
    {{courseLevel || 'Je hebt nog geen niveau gekozen'}}
  </div>
</div>

<div class="widget">
  <exo-loader *ngIf="subjectsLoading"></exo-loader>
  <exo-subjects
    [subjects]="normalSubjects"
    [extraSubjects]="extraSubjects"
    *ngIf="!subjectsLoading"
  ></exo-subjects>
</div>
<div class="widget" *ngIf="extraSubjects && extraSubjects.length > 0">
  <exo-subjects [extra]="true" [subjects]="extraSubjects"></exo-subjects>
</div>
<div class="widget">
  <header>Account verwijderen</header>
  <div class="content">
    <button class="btn btn-primary" (click)="deleteAccount = true">
      Verwijder account
    </button>
  </div>
</div>

<exo-course-level
  *ngIf="showCourseLevelSelect"
  [@popup]
  [component]="true"
  [userCourseLevel]="courseLevel"
  (out)="data($event)"
></exo-course-level>
<div
  class="popup"
  *ngIf="showCourseLevelSelect"
  [@overlay]
  (click)="showCourseLevelSelect = false"
></div>

<exo-select-courses
  *ngIf="popupIsVisible"
  [@popup]
  [curCourses]="subjects"
  [courseLevel]="courseLevel"
  (out)="data($event)"
  [displayMode]="'modal'"
></exo-select-courses>
<div class="popup" *ngIf="popupIsVisible" [@overlay] (click)="popupIsVisible = false"></div>

<div class="widget editProfile" [@popup] *ngIf="editProfile">
  <header>
    <span
      style="left: initial; right: 30px"
      (click)="closeModal()"
      class="fa fa-times"
    ></span>
    <span
      *ngIf="password"
      [@slideleft]
      (click)="password = false; status = null; errstatus = null"
      class="fa fa-arrow-left"
    ></span>
    <p>{{password ? 'Wachtwoord' : 'Profiel aanpassen'}}</p>
  </header>
  <div class="content form">
    <form #profileForm="ngForm" [@slideleft] *ngIf="!password">
      <label for="name">Naam</label>
      <input
        class="form-control"
        type="text"
        name="name"
        id="name"
        [(ngModel)]="name"
        [value]="user.name ? user.name : ''"
      />

      <label for="nickname">Gebruikersnaam</label>
      <input
        class="form-control"
        type="text"
        name="nickname"
        id="nickname"
        [(ngModel)]="nickname"
        [value]="user.nickname?.value ? user.nickname?.value : ''"
      />

      <label for="email">E-mailadres</label>
      <input
        class="form-control"
        type="text"
        name="email"
        id="email"
        [(ngModel)]="email"
        [value]="user.email"
        email
      />

      <div
        *ngIf="email !== user.email || name !== user.name || nickname !== user.nickname.value"
        style="overflow: hidden"
        [@expand]
      >
        <label style="margin-top: 12px" for="password">Wachtwoord</label>
        <input
          [class.ng-invalid]="curpasswordErr"
          class="form-control"
          type="password"
          name="curpassword"
          id="curpasswordemail"
          [(ngModel)]="credentials.current"
          minlength="8"
          pattern=".*[\d\W]+.*"
        />
      </div>
    </form>

    <form #passForm="ngForm" [@slideleft] *ngIf="password && !status">
      <label for="password">Huidig wachtwoord</label>
      <input
        [class.ng-invalid]="curpasswordErr"
        class="form-control"
        type="password"
        name="current"
        id="current"
        [(ngModel)]="credentials.current"
        minlength="6"
      />

      <label for="password">Nieuw wachtwoord</label>
      <input
        class="form-control"
        type="password"
        name="password"
        id="password"
        [(ngModel)]="credentials.password"
        #cred="ngModel"
        minlength="8"
        pattern=".*[\d\W]+.*"
      />

      <label for="repeatpassword">Herhaal wachtwoord</label>
      <input
        class="form-control"
        [class.notsame]="credentials.repeat !== credentials.password"
        type="password"
        name="repeatpassword"
        id="repeatpassword"
        [(ngModel)]="credentials.repeat"
        minlength="8"
      />
    </form>

    <div
      class="status"
      [class.err]="errstatus"
      *ngIf="errstatus || status"
      [@slideleft]
    >
      {{errstatus ? errstatus : status}}
    </div>

    <div
      class="status err"
      *ngIf="passForm?.controls.password.errors?.minlength"
    >
      Wachtwoord moet minimaal 8 tekens bevatten.
    </div>
    <div
      class="status err"
      *ngIf="passForm?.controls.password.errors?.maxlength"
    >
      Wachtwoord mag maximaal 72 karakters bevatten.
    </div>
    <div class="status err" *ngIf="passForm?.controls.password.errors?.pattern">
      Wachtwoord moet minimaal een letter en een speciaal teken of cijfer
      bevatten.
    </div>
    <div
      class="status err"
      *ngIf="passForm?.controls.password.errors?.forbiddenWord"
    >
      Wachtwoord mag niet de woorden 'examenoverzicht', 'password' of
      'wachtwoord' bevatten.
    </div>
    <div
      class="status err"
      *ngIf="passForm?.controls.password.touched && credentials.repeat !== credentials.password"
    >
      Wachtwoorden komen niet overeen.
    </div>
  </div>
  <div
    *ngIf="!password && canEditPassword"
    class="content middle"
    (click)="editPassword()"
  >
    Wachtwoord wijzigen
  </div>

  <button
    *ngIf="!loading"
    class="btn btn-primary"
    (click)="editUserOrClose()"
    [disabled]="!canSubmit || profileForm?.invalid"
  >
    {{status === 'Wachtwoord gewijzigd' ? 'Sluiten' : 'Wijzigen'}}
  </button>

  <exo-loader *ngIf="loading"></exo-loader>
</div>

<div class="widget changePicture" [@popup] *ngIf="changePicture">
  <header>
    <p>Profielfoto wijzigen</p>
  </header>
  <div class="content">
    <exo-image-uploader (picture)="pictureObject($event)"></exo-image-uploader>
    <exo-loader *ngIf="loading"></exo-loader>
    <button
      *ngIf="!loading"
      class="btn btn-primary"
      (click)="editProfilePicture()"
    >
      Wijzigen
    </button>
  </div>
</div>

<div
  class="widget deleteAccount"
  [class.delete]="delete"
  [@popup]
  *ngIf="deleteAccount"
>
  <header>
    <p>Account verwijderen</p>
  </header>
  <div class="content">
    <p>
      Weet je zeker dat je je account wil verwijderen? Hiermee worden ook al je
      digitale aankopen verwijderd.<br />
    </p>
    <p>
      Typ hieronder 'verwijder' om te bevestigen dat je je account definitief
      wil verwijderen.
    </p>
    <input
      class="form-control"
      type="text"
      [(ngModel)]="confirm"
      name="delete"
      id="delete"
    />
    <div class="status err" *ngIf="errstatus" [@slideleft]>{{errstatus}}</div>
    <exo-loader *ngIf="loading"></exo-loader>
    <button
      class="btn btn-primary"
      *ngIf="!loading"
      (click)="deleteAccountPermanently()"
    >
      Definitief verwijderen
    </button>
  </div>
</div>

<exo-validate-email
  *ngIf="verification"
  [@popup]
  [component]="true"
  [email]="user.email"
  (out)="next($event)"
></exo-validate-email>

<div
  class="popup"
  *ngIf="editProfile || verification"
  [@overlay]
  (click)="closeModal()"
></div>
<div
  class="popup"
  *ngIf="changePicture"
  [@overlay]
  (click)="changePicture = false"
></div>
<div
  class="popup"
  [class.delete]="delete"
  *ngIf="deleteAccount"
  [@overlay]
  (click)="deleteAccount = false"
></div>

<div class="widget editSchool" [@popup] *ngIf="editSchool">
  <exo-choose-school
    [user]="user"
    [mode]="'popup'"
    (out)="close($event)"
  ></exo-choose-school>
</div>
<div
  class="popup"
  *ngIf="editSchool"
  [@overlay]
  (click)="editSchool = false"
></div>

<div class="widget editGender" [@popup] *ngIf="editGender">
  <exo-choose-gender
    [user]="user"
    [mode]="'popup'"
    (out)="close($event)"
  ></exo-choose-gender>
</div>
<div
  class="popup"
  *ngIf="editGender"
  [@overlay]
  (click)="editGender = false"
></div>

<div class="widget editUsertype" [@popup] *ngIf="editUsertype">
  <exo-choose-usertype
    [user]="user"
    [mode]="'popup'"
    (out)="close($event)"
  ></exo-choose-usertype>
</div>
<div
  class="popup"
  *ngIf="editUsertype"
  [@overlay]
  (click)="editUsertype = false"
></div>
