import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CartService } from '../../../shared/cart/cart.service';

import { environment } from '../../../shared/environments/environment';
import { Exercise } from '../../../shared/exercises/exercises';
import { ExercisesService } from '../../../shared/exercises/exercises.service';
import { ProductService } from '../../../shared/products/product.service';
import { ProductBundle } from '../../../shared/products/productBundle';
import { Subject } from '../../../shared/subjects/subject';
import { SubjectService } from '../../../shared/subjects/subject.service';

declare var Box;

@Component({
  selector: 'exo-exercises',
  templateUrl: 'exercises.html',
  styleUrls: ['exercises.scss'],
})

export class ExercisesComponent implements OnInit {
  origin = environment.WEBSHOP_URL;

  loading = true;
  notFound = false;

  exercise: Exercise;
  buyLink: string;
  buyableProduct: ProductBundle;

  subject: Subject;
  viewerHeight = 0;

  constructor(
    private route: ActivatedRoute,
    private exercisesService: ExercisesService,
    private subjectService: SubjectService,
    private cartService: CartService,
    private productService: ProductService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.loading = true;
      this.exercise = null;

      const slug = params.get('slug');

      this.subjectService.getSubjectBySlug(slug).subscribe((subject) => {
        this.subject = subject;
        this.getExercises(subject._id);
        this.getBuyableProduct(subject._id);
      });
    });
  }

  getBuyableProduct(subjectId: string) {
    this.productService.getBuyAbleProductBundlesForUserBySubjectId(subjectId).pipe(first()).subscribe((products) => {
      this.buyableProduct = products.find((product)=> product.description === 'Oefenboek');
    });
  }

  getExercises(id: string) {
    this.exercisesService.getExercisesBySubjectId(id).subscribe((exercise: Exercise) => {
      if (exercise.sku.indexOf('-digitaal') || exercise.sku.indexOf('-combi') || exercise.sku.indexOf('-hardcopy')) {
        this.buyLink = `${this.origin}/${exercise.sku[0].split('-', exercise.sku[0].split('-').length - 1).join('-')}`;
      }
      this.exercise = exercise;
      this.loadBox();
    }, (err) => {
      this.loading = false;
      this.notFound = true;
    });
  }

  loadBox() {
    this.loading = false;
    const preview = new Box.Preview();

    preview.show(this.exercise.doc_id, this.exercise.token.access_token, {
      container: '.preview-container',
      showDownload: false,
      header: 'none',
      viewers: {
        CSV: {
          disableTextLayer: true,
        },
        Document: {
          disableTextLayer: true,
        },
        Presentation: {
          disableTextLayer: true,
        },
        Markdown: {
          disableTextLayer: true,
        },
        Text: {
          disableTextLayer: true,
        },
      },
    });

    preview.addListener('load', (data) => {
      if (data.error) {
        this.notFound = true;
        return;
      }
    });

  }

  addToCart() {
    this.cartService.addToCart(this.buyableProduct);
    this.router.navigate(['/cart']);
  }

}
