import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PaymentLinkComponent } from '../components/url-links/payment/payment.component';
import { BadgesPageComponent } from '../pages/badges/badges.page';
import { CartPageComponent } from '../pages/cart/cart.page';
import { DashboardPageComponent } from '../pages/dashboard/dashboard.page';
import { LoginPageComponent } from '../pages/login/login.page';
import { ResetPasswordPageComponent } from '../pages/login/resetpassword.page';
import { UnblockAccountPageComponent } from '../pages/login/unblockaccount.page';
import { LogoutPageComponent } from '../pages/logout/logout.page';
import { PaymentLinkPageComponent } from '../pages/payment/link/payment-link.page';
import { PaymentStatusPageComponent } from '../pages/payment/status/payment-status.page';
import { DevicesComponent } from '../pages/profile/devices/devices.component';
import { DisclaimerComponent } from '../pages/profile/disclaimer/disclaimer.component';
import { FaqComponent } from '../pages/profile/faq/faq.component';
import { ProfilePageComponent } from '../pages/profile/profile.page';
import { ProfileComponent } from '../pages/profile/profile/profile.component';
import { SummariesComponent } from '../pages/profile/summaries/summaries.component';
import { RegisterPageComponent } from '../pages/register/register.page';
import { RootPageComponent } from '../pages/root/root.page';
import { SchedulePageComponent } from '../pages/schedule/schedule.page';
import { DefinitionsComponent } from '../pages/subject/definitions/definitions.component';
import { ExercisesComponent } from '../pages/subject/exercises/exercises.component';
import { HomeComponent } from '../pages/subject/home/home';
import { OldexamListComponent } from '../pages/subject/oldexams/oldexam-list/oldexam-list.component';
import { OldexamComponent } from '../pages/subject/oldexams/oldexam/oldexam.component';
import { OldexamsComponent } from '../pages/subject/oldexams/oldexams.component';
import { SubjectPageComponent } from '../pages/subject/subject.page';
import { SummaryComponent } from '../pages/subject/summary/summary.component';
import { TipsComponent } from '../pages/subject/tips/tips.component';
import { VideosComponent } from '../pages/subject/videos/videos.component';

import { CanDeactivateGuard } from './../guards/can-deactivate-guard';
import { PaymentSuccessPageComponent } from './../pages/mobile/payment-success/payment-success.page';
import { QuestionTrainingPageComponent } from './../pages/training/question-page/question.page';
import { ResultsTrainingPageComponent } from './../pages/training/results-page/results.page';
import { TrainingPageComponent } from './../pages/training/training.page';
import { AuthGuard } from './authguard';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
  }
}

const appRoutes: Routes = [
  {
    path: 'training/:encodedName',
    component: TrainingPageComponent,
    children: [
      {
        path: '',
        redirectTo: '/subject/:encodedName',
        pathMatch: 'full',
      },
      {
        path: 'results',
        component: ResultsTrainingPageComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: ':thesisId',
        component: QuestionTrainingPageComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'logout',
    component: LogoutPageComponent,
  },
  {
    path: 'register',
    component: RegisterPageComponent,
  },
  {
    path: 'reset/:key',
    component: ResetPasswordPageComponent,
  },
  {
    path: 'unblock/:key',
    component: UnblockAccountPageComponent,
  },
  {
    path: 'payment',
    children: [
      { path: '', component: PaymentLinkComponent },
    ],
  },
  {
    path: '',
    component: RootPageComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardPageComponent, data: { animation: 'dashboard' } },
      {
        path: 'profile',
        component: ProfilePageComponent,
        children: [
          { path: '', component: ProfileComponent, data: { animation: 'profileinfo' } },
          { path: 'devices', component: DevicesComponent, data: { animation: 'devices' } },
          { path: 'disclaimer', component: DisclaimerComponent, data: { animation: 'disclaimer' } },
          { path: 'faq', component: FaqComponent, data: { animation: 'faq' } },
          { path: 'summaries', component: SummariesComponent, data: { animation: 'summaries' } },
        ],
        data: { animation: 'profile' },
      },
      { path: 'profile/badges', component: BadgesPageComponent, data: { animation: 'badges' } },
      {
        path: 'cart',
        component: CartPageComponent,
      },
      {
        path: 'payment',
        children: [
          { path: 'status', component: PaymentStatusPageComponent },
          { path: 'link', component: PaymentLinkPageComponent },
        ],
      },
      { path: 'schedule', component: SchedulePageComponent, data: { animation: 'schedule' } },
      { path: 'subject/:slug/summary', component: SummaryComponent, data: { animation: 'summary' } },
      {
        path: 'subject/:slug',
        component: SubjectPageComponent,
        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', component: HomeComponent, data: { animation: 'home' } },
          { path: 'exercises', component: ExercisesComponent, data: { animation: 'exercises' } },
          { path: 'definitions', component: DefinitionsComponent, data: { animation: 'definitions' } },
          { path: 'tips', component: TipsComponent, data: { animation: 'tips' } },
          { path: 'videos', component: VideosComponent, data: { animation: 'videos' } },
          {
            path: 'oldexams',
            component: OldexamsComponent,
            children: [
              { path: '', component: OldexamListComponent },
              { path: ':year/:period', component: OldexamComponent },
              { path: ':year/:period/:type', component: OldexamComponent },
            ],
            data: { animation: 'oldexams' },
          },
        ],
        data: { animation: 'subject', shouldReuse: false },
      },
    ],
  },
  {
    path: 'mobile',
    children: [
      { path: 'payment-success', component: PaymentSuccessPageComponent },
    ],
  },
  { path: '**', redirectTo: '' },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
