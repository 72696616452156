import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Subject, SubjectWithSections } from '../subjects/subject';

import { setCoverUrlToSummaries, setCoverUrlToSummary } from '../util/helpers';

import { Summary } from './summary';

@Injectable()
export class SummaryService {
  constructor(private http: HttpClient) {}

  pairSummary(accessCode: string, orderId?: string): Observable<Summary> {
    return this.http.put('/user/product/pair', { accessCode, orderId }).pipe(map((res) => setCoverUrlToSummary(res)));
  }

  addSummaryManually(accessCode: string, orderId?: string): Observable<Summary> {
    return this.http.put('/user/product/add', { accessCode, orderId }).pipe(map((res) => setCoverUrlToSummary(res)));
  }

  getSummaries(): Observable<Summary[]> {
    return this.http.get('/user/summaries').pipe(map((res) => setCoverUrlToSummaries(res)));
  }

  getInactiveSummaries(): Observable<Summary[]> {
    return this.http.get('/user/summaries/inactive').pipe(map((res) => setCoverUrlToSummaries(res)));
  }

  getSummary(subjectId: string): Observable<Summary> {
    return this.http.get(`/user/${subjectId}/summaries`).pipe(map((res) => setCoverUrlToSummary(res)));
  }

  getSummaryBySubjectEncodedName(encodedSubjectName: string): Observable<any> {
    return this.http.get(`/summaries/topics/${encodedSubjectName}`);
  }

  getSummarySections(encodedSubjectName: string): Observable<any> {
    return this.http.get(`/summaries/topics/${encodedSubjectName}/sections`);
  }

  getSections(subjectIds: string[]): Observable<SubjectWithSections[]> {
    return this.http.get(`/v2/courseLevel/subjects/sections?subjectIds[]=${subjectIds.join('&subjectIds[]=')}`)
      .pipe(map((subjects: SubjectWithSections[]) => subjects.map((subject) => ({
        ...subject,
        subjectImage: environment.API_URL + subject.subjectImage
      }))));
  }

  getSummaryBySubjectEncodedNameAdmin(subjectId: string): Observable<Summary> {
    return this.http.get(`/subject/${subjectId}/summaries`).pipe(map((res) => setCoverUrlToSummary(res)));
  }

  getSummaryCoverBySubjectId(subjectId: string): Observable<string> {
    return this.getSummaryBySubjectEncodedName(subjectId).pipe(map((summary) => summary.cover));
  }

  addSummaries(summaries: string[]): Observable<any> {
    return this.http.put('/user/products/add', { summaries });
  }

  hasSummaries(): Observable<boolean> {
    return this.getSummaries().pipe(
      mergeMap((activeSummaries) => {
        return this.getInactiveSummaries().pipe(
          map((inactiveSummaries) => {
            return activeSummaries.length > 0 || inactiveSummaries.length > 0;
          }),
        );
      }),
    );
  }

  getBuyableSummaries(): Observable<Summary[]> {
    return this.http.get('/user/summaries/buyable').pipe(map((res) => setCoverUrlToSummaries(res)));
  }

  uploadAnswersPDF(summaryId: string, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('answer', file, file.name);

    return this.http.put(`/summaries/${summaryId}/answers`, formData);
  }

  getTopic(encodedSubjectName: string, encodedTopicName: string): Observable<any> {
    return this.http.get(`/summaries/topics/${encodedSubjectName}/topics/${encodedTopicName}`);
  }
}
