import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { Cart } from '../../../../shared/cart/cart';
import { CartService } from '../../../../shared/cart/cart.service';
import { environment } from '../../../../shared/environments/environment';
import { ProductService } from '../../../../shared/products/product.service';
import { ProductBundle } from '../../../../shared/products/productBundle';
import { Subject } from '../../../../shared/subjects/subject';

@Component({
  selector: 'exo-summary-buyables',
  templateUrl: 'buyables.html',
  styleUrls: ['buyables.scss'],
})
export class SummaryBuyablesComponent implements OnInit, OnChanges {
  @Input() subject: Subject;
  public buyables = [];
  public products = [];
  private cart: Cart;
  public apiUrl = environment.API_URL;

  constructor(
    private cartService: CartService,
    private productService: ProductService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
      this.filterBuyables();
    });
  }

  ngOnChanges() {
    this.getBuyables();
  }

  getBuyables() {
    this.productService.getBuyAbleProductBundlesForUserBySubjectId(this.subject._id).pipe(first()).subscribe((products) => {
      this.buyables = products;
      this.filterBuyables();
    });
  }

  addToCart(product) {
    this.cartService.addToCart(product);
    this.router.navigate(['/cart']);
    this.filterBuyables();
  }

  filterBuyables() {
    this.products = this.buyables.filter((product: ProductBundle) => {
      return !this.cart.items.find((cartItem: ProductBundle) => {
        return cartItem._id === product._id;
      }) && product.description !== 'Oefenboek';
    }).slice(0, 2);
  }

}
