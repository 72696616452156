import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'exo-row',
  templateUrl: 'row.html',
  styleUrls: ['row.scss'],
})
export class RowComponent {
  @Input() full: boolean;
  @Input() width: string;
  @Input() space: string;
  @Input() gridSpace: string;
  @Input() gridCols: string;
  @Input() styles: string;

  constructor() {
  }
}
