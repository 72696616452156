<div class="widget">
  <header>
    <p>Valideer je e-mailadres</p>
  </header>
  <div class="content">
    <div class="info">We hebben je net een mail gestuurd met een code.<br/>Vul deze hier in om je {{component ? 'nieuwe e-mailadres' : 'account'}} te bevestigen</div>

    <div class="input-boxes" *ngIf="!loading">
      <input (keyup)="next($event)" (paste)="next($event)" type="text" maxlength="6" oninput="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="key" #input/>
    </div>

    <div class="status" *ngIf="status">
      {{status}}
    </div>

    <exo-loader *ngIf="loading"></exo-loader>

    <div class="no-code">
      <div *ngIf="!sent">
        Geen code ontvangen?
        <a class="btn-link" (click)="resend()">Stuur code opnieuw</a>
      </div>
      <div *ngIf="sent">
        We hebben je een nieuwe code gestuurd
      </div>
    </div>
  </div>
</div>
