import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../shared/environments/environment';

import { BaseLoginProvider } from './entities/base-login-provider';
import { LoginProvider } from './entities/login-provider';
import { SocialUser } from './entities/user';
import { FacebookLoginProvider, GoogleLoginProvider } from './providers';

export interface SocialAuthServiceConfigItem {
  id: string;
  provider: LoginProvider;
}

@Injectable()
export class SocialAuthService {

  private static readonly LOGIN_PROVIDER_NOT_FOUND: string = 'Login provider not found';

  // tslint:disable-next-line: prefer-array-literal
  private providers = new Array<SocialAuthServiceConfigItem>();

  private _user: SocialUser = null;
  private _authState: BehaviorSubject<SocialUser> = new BehaviorSubject(null);

  get authState(): Observable<SocialUser> {
    return this._authState.asObservable();
  }

  constructor() {
    const facebook = new FacebookLoginProvider(environment.SOCIAL.FACEBOOK.CLIENT_ID);
    const google = new GoogleLoginProvider(environment.SOCIAL.GOOGLE.CLIENT_ID);

    this.initializeProvider(facebook, 'FACEBOOK');
    this.initializeProvider(google, 'GOOGLE');
  }

  initializeProvider(provider: BaseLoginProvider, providerId: string) {

    this.providers.push({ provider, id: providerId });

    provider.initialize().then((user: SocialUser) => {
      user.provider = providerId;

      this._user = user;
      this._authState.next(user);
    }).catch((err) => { });
  }

  signIn(providerId: string): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      const providerObject = this.providers.find(provider => provider.id === providerId);

      if (providerObject) {
        providerObject.provider.signIn().then((user: SocialUser) => {
          user.provider = providerId;
          resolve(user);

          this._user = user;
          this._authState.next(user);
        });
      } else {
        reject(SocialAuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      const providerId = this._user.provider;
      const providerObject = this.providers.find(provider => provider.id === providerId);
      if (providerObject) {
        providerObject.provider.signOut().then(() => {
          this._user = null;
          this._authState.next(null);

          resolve();
        });
      } else {
        reject(SocialAuthService.LOGIN_PROVIDER_NOT_FOUND);
      }
    });
  }

}
