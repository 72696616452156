import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  templateUrl: 'payment-success.page.html',
  styleUrls: ['payment-success.page.scss'],
})

export class PaymentSuccessPageComponent implements OnInit {
  showMessage = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.openApp();
      this.showRedirect();
    },         5000);
  }

  showRedirect() {
    this.showMessage = true;
  }

  openApp() {
    this.document.location.href = 'examenoverzicht://examenoverzicht.nl';
  }
}
