import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'exo-card',
  templateUrl: 'card.html',
  styleUrls: ['card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardComponent {
  @Input() full: boolean;
  @Input() space: string;
  @Input() classes: string;

  constructor() {
  }
}
