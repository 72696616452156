<div class="row" *ngIf="subject">
  <div class="col-md-7">
    <div class="card learn-next-card">
      <button
        class="btn-card-next dynamic-bgcolorf"
        (click)="openSummary(firstUnreadSection)"
      >
        <span class="main-text" *ngIf="progressPercentageSummary !== 1"
          >{{progressPercentageSummary > 0 ? 'Doorgaan': 'Start'}} met
          leren</span
        >
        <span class="main-text" *ngIf="progressPercentageSummary === 1"
          >Opnieuw leren</span
        >
        <span class="sub-text">{{firstUnreadSection?.sectionTitle}}</span>
      </button>

      <button
        class="btn-card-next dynamic-bgcolorf"
        (click)="openTraining(firstUntrainedPassedSection || firstPracticeSectionAvailable)"
        *ngIf="practiceModuleActive && firstPracticeSectionAvailable"
      >
        <span class="main-text" *ngIf="progressPercentageTraining !== 1"
          >{{progressPercentageTraining > 0 || trainingProgress?.lastThesisId ?
          'Doorgaan': 'Start'}} met oefenen</span
        >
        <span class="main-text" *ngIf="progressPercentageTraining === 1"
          >Opnieuw oefenen</span
        >
        <span class="sub-text"
          >{{firstUntrainedPassedSection?.sectionTitle}}</span
        >
      </button>
    </div>

    <span class="text TextSubjectIndexChapter" [style.margin]="'24px 0 16px 0'"
      >Alle onderdelen</span
    >

    <div
      class="card sections-card"
      *ngFor="let chapter of summarySections?.chapters"
      [style.margin]="'0 0 16px 0'"
    >
      <header>
        <span class="TextSubjectIndexChapter">{{chapter.chapterTitle}}</span>
      </header>

      <div class="content">
        <ul>
          <li
            (focusout)="selectedSection = ''"
            *ngFor="let section of chapter.sections"
          >
            <div
              class="section-detail"
              [class.locked]="(isSummaryPreview && isTrainingPreview) && !section.preview"
              (click)="showSectionOptions(section)"
            >
              <div>
                <span
                  class="lock"
                  *ngIf="(isSummaryPreview && isTrainingPreview) && !section.preview"
                ></span
                ><span class="TextSubjectIndexItem"
                  >{{section.sectionTitle}}</span
                >
              </div>
              <div>
                <span
                  class="TextSubjectSectionScore"
                  [class.less-grade]="section.sectionRating < 5.5"
                  *ngIf="section.sectionRating"
                  >{{section.sectionRating | score}}</span
                >
                <span
                  class="chevron"
                  [class.active]="selectedSection === section.encodedSectionName"
                ></span>
              </div>
            </div>
            <div
              class="section-actions"
              *ngIf="selectedSection === section.encodedSectionName && (!isSummaryPreview || !isTrainingPreview || section.preview)"
            >
              <a
                class="TextSubjectSectionAction dynamic-color"
                (click)="openSummary(section)"
                >Leren</a
              >
              <a
                *ngIf="section.hasPracticeModule && practiceModuleActive"
                class="TextSubjectSectionAction dynamic-color"
                (click)="openTraining(section, chapter.encodedChapterName, true)"
                >Oefenen</a
              >
            </div>
            <div
              class="section-topics"
              *ngIf="selectedSection === section.encodedSectionName && (!isSummaryPreview || !isTrainingPreview || section.preview)"
            >
              <ul>
                <li *ngFor="let topic of section.topics">
                  <div *ngIf="topic.topicTitle">
                    <span
                      class="topic-bullet {{getTopicRatingLvl(topic.rating)}}"
                    ></span>
                    <span class="topic-name">{{topic.topicTitle}}</span>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col-md-5">
    <exo-subject-buyables [subject]="subject"></exo-subject-buyables>

    <div class="card score-process-card" *ngIf="loaded">
      <div class="content">
        <div>
          <exo-chart
            class="circle-chart-large"
            [id]="'chart-score'"
            [size]="120"
            [score]="summarySections?.subjectRating || '-'"
          ></exo-chart>
          <span class="TextProgressText" [style.margin]="'12px 0 0 0'"
            >Huidige cijfer</span
          >
        </div>
        <div *ngIf="progressPercentage">
          <exo-chart
            class="circle-chart-large"
            [id]="'chart-progression'"
            [size]="120"
            [score]="progressPercentage"
            [showPercentage]="true"
          ></exo-chart>
          <span class="TextProgressText" [style.margin]="'12px 0 0 0'"
            >Voortgang</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
