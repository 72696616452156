<header>
  {{extra ? 'Extra vakken' : 'Mijn vakken'}}
  <a
    class="btn btn-link header-content-right"
    *ngIf="!extra"
    (click)="popupIsVisible = true; sendDatalayer('profile', 'button', 'adjust-course');"
    >Pas vakkenpakket aan</a
  >
</header>
<div class="content">
  <ul class="list-group" *ngIf="subjects && subjects.length > 0">
    <li
      class="list-group-item pointer"
      *ngFor="let subject of subjects"
      [routerLink]="['/subject', subject.encodedName]"
    >
      <div class="subject-code">
        <img src="{{subject.subjectImage}}" />
      </div>
      <div class="info">
        <div class="subject">{{subject.name}}</div>
        <div *ngIf="subject.grade" class="grade">
          Benodigd cijfer: {{subject.grade | number}}
        </div>
      </div>
      <div class="buttons" *ngIf="!extra">
        <a
          [routerLink]="['/subject', subject.encodedName, 'summary']"
          class="btn btn-primary"
          >Samenvatting</a
        >
      </div>
      <div class="level" *ngIf="subject.courseLevel !== courseLevel">
        {{subject.courseLevel}}
      </div>
    </li>
  </ul>
  <span *ngIf="subjects && subjects.length === 0"
    >Geen vakken geselecteerd. Voeg ze toe aan je account.</span
  >
</div>

<div class="modal-intern" *ngIf="popupIsVisible" [@popup]>
  <div class="backdrop" (click)="popupIsVisible = false;"></div>
  <div class="modal-component">
    <exo-select-courses
      *ngIf="popupIsVisible"
      [curCourses]="allSubjects"
      [courseLevel]="courseLevel"
      (out)="data($event)"
      [displayMode]="'modal'"
    ></exo-select-courses>
  </div>
</div>
