<div class="exercise-root" *ngIf="loaded" [@routetrans]>
  <div class="first">
    <div class="exc-header">
      <exo-button-deprecated
        [style]="'icon'"
        [icon]="'/assets/nav-icons/close.svg'"
        class="btn-close"
        (click)="close()"
      ></exo-button-deprecated>
      <h3>
        Vraag ({{currentQuestionsAnsweredCount}}/{{currentQuestionsCount}})
      </h3>
    </div>

    <div
      class="content content-scroll a-top"
      [class.simple]="isSimpleQuestion(thesis.questions[0]) || isDictationQuestion(thesis.questions[0])"
    >
      <div class="question-container">
        <span class="title-sub" [mathjax]="thesis?.exam"></span>
        <h4 class="title dynamic-color" [mathjax]="thesis?.title"></h4>

        <span
          class="dictation-word"
          *ngIf="isDictationQuestion(thesis.questions[0])"
          [mathjax]="thesis.questions[0]?.translation"
        ></span>
        <div class="body" [mathjax]="thesis?.html"></div>
      </div>
    </div>
  </div>

  <div class="second">
    <div class="exc-header">
      <exo-button-deprecated
        [style]="'icon'"
        [icon]="'/assets/nav-icons/flag.svg'"
        class="btn-report"
        (click)="report()"
      ></exo-button-deprecated>
      <h3>Antwoord</h3>
    </div>

    <div
      class="content content-scroll a-top questions"
      [class.simple]="isSimpleQuestion(thesis.questions[0]) || isDictationQuestion(thesis.questions[0])"
    >
      <div class="questions-container">
        <div>
          <div
            *ngFor="let question of thesis?.questions; let i = index"
            class="question-block"
            [class.nospace]="!question?.isCorrect"
          >
            <ng-container
              *ngIf="thesis?.questions[i -1]?.isAnswered || i === 0"
            >
              <div class="border-line"></div>
              <p
                class="question"
                *ngIf="question.title"
                [mathjax]="question.title"
              ></p>
              <p
                class="introduction"
                [mathjax]="question.introduction"
                *ngIf="question.introduction"
              ></p>

              <!-- MULTIPLECHOICE QUESTION -->
              <ul class="choices">
                <li *ngFor="let choice of question?.choices; let i = index">
                  <exo-button-deprecated
                    (click)="answerQuestion(question, choice)"
                    [style]="'choice'"
                    [class.penone]="question.isAnswered"
                    [label]="displayLetter(i)"
                    [labelSecond]="choice"
                    [extra]="question.isAnswered && question?.isCorrect && question.selectedChoice === choice ? 'good' : ''"
                    [extra2]="question.isAnswered && !question?.isCorrect && question.selectedChoice === choice ? 'danger' : ''"
                    [extra3]="(isSimpleQuestion(question) || isDictationQuestion(question)) ? 'simple stretch' : ''"
                  ></exo-button-deprecated>
                </li>
              </ul>

              <!-- DICTATION AND OPEN QUESTION -->
              <span class="label" *ngIf="isOpenQuestion(question)">Antwoord</span>
              <div *ngIf="isDictationQuestion(question) || isOpenQuestion(question)">
                <div
                  class="question-input"
                  [class.answered]="question.isAnswered || question.openQuestionIsAnswered"
                  [class.incorrect]="!question?.isCorrect && !isOpenQuestion(question)"
                  [class.correct]="question?.isCorrect"
                >
                  <span
                    role="textbox"
                    contenteditable
                    class="txtarea"
                    (input)="question.answerInput = $event.target.innerText"
                    autofocus
                  ></span>
                  <span
                    class="error-message"
                    *ngIf="question.submitted && !question.answerInput"
                    >Verplicht invullen</span
                  >
                </div>

                <!-- OPEN QUESTION -->
                <div *ngIf="isOpenQuestion(question)">
                  <div *ngIf="question.explanation">
                    <span class="label" *ngIf="question.openQuestionIsAnswered"
                      >Het juiste antwoord is</span
                    >
                    <p
                      class="hint-answer"
                      *ngIf="question.openQuestionIsAnswered"
                      [mathjax]="question.explanation"
                    ></p>
                  </div>
                  <div *ngIf="question.openQuestionIsAnswered">
                    <span class="label" [style.margin]="'20px 0 0 0'">
                      Voor deze vraag kun je {{question.totalPoints}} punt{{question.totalPoints > 1 ? 'en' : ''}}
                      halen<br />
                      Hoeveel punten zou jij jezelf geven?
                    </span>
                    <p
                      class="hint-answer"
                      [mathjax]="question.pointsExplanation"
                      *ngIf="question.pointsExplanation"
                    ></p>
                    <exo-answer-rating
                      [style.margin]="'16px 0 0 0'"
                      [maxRating]="question.totalPoints"
                      [editable]="!question.isAnswered"
                      [rating]="question.scoredPoints"
                      (ratingSet)="setOpenQuestionRating(question, $event)"
                    ></exo-answer-rating>
                  </div>

                  <exo-button-deprecated
                    *ngIf="!question.openQuestionIsAnswered"
                    (click)="answerOpenQuestion(question)"
                    [label]="'Antwoord bevestigen'"
                    [style]="'large compact stretch'"
                    [style.margin]="'24px 0 0 0'"
                  ></exo-button-deprecated>
                </div>

                <!-- BUTTON FOR DICTATION AND OPEN QUESTION -->
                <exo-button-deprecated
                  *ngIf="(question.openQuestionIsAnswered && !question.isAnswered) || (isDictationQuestion(question) && !question.isAnswered)"
                  (click)="answerQuestion(question, question.answerInput)"
                  [disabled]="isDictationQuestion(question) ? !question.answerInput : question.scoredPoints === undefined"
                  [label]="isOpenQuestion(question) ? 'Punten indienen' : 'Controleren'"
                  [style]="'large compact stretch'"
                  [style.margin]="'24px 0 0 0'"
                ></exo-button-deprecated>
              </div>

              <!-- WHEN QUESTION IS ANSWERED -->
              <span
                class="correct"
                [class.incorrect]="!question?.isCorrect"
                *ngIf="!isOpenQuestion(question) && (question.isAnswered && !isDictationQuestion(question)) || (isDictationQuestion(question) && question?.isCorrect)"
              >
                <span>{{question?.isCorrect ? 'Helemaal goed' : 'Fout'}}</span>
                <img
                  src="/assets/nav-icons/{{question?.isCorrect ? 'check.svg' : 'incorrect.svg'}}"
                  alt="uitslag"
                />
              </span>

              <div
                class="st-24 assist-block"
                [class.sb-8]="question.assistState"
                *ngIf="!isDictationQuestion(question) && !isOpenQuestion(question) && question.isAnswered && !question?.isCorrect && (question?.hint || question?.explanation && !isOpenQuestion(question))"
              >
                <exo-button-deprecated
                  *ngIf="!isOpenQuestion(question) && question?.hint"
                  class="assist-btn"
                  [icon]="'/assets/nav-icons/tips.svg'"
                  [label]="'Bekijk hint'"
                  [style]="'standard'"
                  [active]="question.assistState === 'hint'"
                  (click)="showAssist(question, 'hint')"
                ></exo-button-deprecated>

                <exo-button-deprecated
                  *ngIf="!isOpenQuestion(question) && question?.explanation"
                  [icon]="'/assets/nav-icons/samenvatting.svg'"
                  [label]="'Bekijk antwoord'"
                  [style]="'standard'"
                  [active]="question.assistState === 'answer'"
                  (click)="showAssist(question, 'answer')"
                  [extra]="'no-wrap'"
                ></exo-button-deprecated>
              </div>
              <p
                class="hint-answer"
                *ngIf="question?.hint && question.assistState === 'hint' && !question?.isCorrect"
                [mathjax]="question?.hint"
              ></p>
              <p
                class="hint-answer"
                [class.dictation]="isDictationQuestion(question)"
                *ngIf="(isDictationQuestion(question) && question?.explanation && question.isAnswered && !question?.isCorrect) ||
              question?.explanation && question.assistState === 'answer'"
                [mathjax]="question?.explanation"
              ></p>
            </ng-container>
          </div>

          <div *ngIf="isLastThesisQuestion()">
            <div>
              <span class="rating-heading">Beoordeel deze vraag</span>
              <exo-rating
                [editable]="true"
                [rating]="rating?.rating"
                (ratingSet)="setRating($event)"
              ></exo-rating>
            </div>

            <div *ngIf="rating && rating?.rating <= 3">
              <span class="review-heading"
                >Wat kan er beter aan deze vraag?</span
              >
              <textarea
                class="review-question-txtarea"
                rows="6"
                #txtArea
                [(ngModel)]="rating.message"
              ></textarea>
            </div>

            <exo-button-deprecated
              class="disblock st-20"
              [label]="'Volgende vraag'"
              [size]="'large'"
              [extra]="'stretch'"
              [noRadius]="true"
              (click)="nextQuestion()"
            ></exo-button-deprecated>
          </div>
        </div>
      </div>
    </div>

    <div class="progress-bar">
      <div
        class="progress dynamic-bgcolor"
        [ngStyle]="{'width': progressWidth()}"
      ></div>
    </div>
  </div>
</div>
