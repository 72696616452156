<div class="row">
  <div class="col-md-12">
    <div class="card spaceless">
      <header>
        Oefenboek
        <div>
          <a
            class="btn header-content-right buy-btn"
            *ngIf="exercise?.preview && buyableProduct"
            (click)="addToCart()"
            >Kopen</a
          >
        </div>
      </header>

      <div class="content exercises" *ngIf="exercise || !loading">
        <exo-loader *ngIf="loading"></exo-loader>

        <div class="not-found" *ngIf="notFound">
          Voor dit vak is er geen oefenboek.
        </div>

        <div
          class="preview-container"
          [hidden]="loading"
          *ngIf="!notFound"
        ></div>
      </div>
    </div>
  </div>
</div>
