<div class="modal-popup">
  <exo-button-deprecated
    [style]="'icon-plain'"
    [icon]="'/assets/nav-icons/close.svg'"
    class="close-modal"
    (click)="close()"
  ></exo-button-deprecated>

  <h3 class="PopupTitle title">Wat betekent welke kleur in de samenvatting?</h3>

  <div class="texts">
    <span class="grade-text high">Je bent heel goed bezig!</span>
    <span class="grade-text mid">Je zit al op de goede weg</span>
    <span class="grade-text low">Er is nog veel werk aan de winkel</span>
  </div>

  <div class="button-actions">
    <exo-button
      classes="btn-accent"
      text="Sluiten"
      (click)="close()"
    ></exo-button>
  </div>
</div>
