import { Subject } from './subject';

export class Exam {
  startDate: Date;
  endDate: Date;

  position: Position;
  subject: Subject;

  constructor(subject: Subject, startDate?: Date, endDate?: Date, position?: Position) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.position = position || new Position(0, 0, { total: 1, this: 0 });
    this.subject = subject;
  }
}

class Position {
  top: number;
  bottom: number;
  overlapDepth: {
    total: number;
    this: number;
  };

  constructor(top: number, bottom: number, overlapDepth: { total: number, this: number }) {
    this.top = top;
    this.bottom = bottom;
    this.overlapDepth = overlapDepth;
  }
}
