import { HttpHeaders } from '@angular/common/http';

import { environment } from '../environments/environment';
import { Summary } from '../summaries/summary';

export function setCoverUrlToSummary(summary): Summary {
  summary.cover = environment.API_URL + summary.cover;
  return summary;
}

export function setCoverUrlToSummaries(summaries): Summary[] {
  summaries.forEach((summary) => {
    summary.cover = environment.API_URL + summary.cover;
  });
  return summaries;
}

export const addAuthHeader = (options = { headers: new HttpHeaders() }) => {
  if (!options.headers) {
    options.headers = new HttpHeaders();
  }
  if (!options.headers.get('Authorization')) {
    options.headers = options.headers.append('Authorization', 'true');
  }
  return options;
};
