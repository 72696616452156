<div class="container">
  <div class="row">
    <div class="col-md-8 cart-side-left">
      <h3 class="TextTitleRegular" [style.margin]="'0 0 12px 0'">
        Je bestelling
      </h3>
      <exo-cart-order></exo-cart-order>
      <exo-cart-discount></exo-cart-discount>
      <exo-cart-buyables></exo-cart-buyables>
    </div>

    <div class="col-md-4 cart-side-right">
      <exo-cart-shipping-form></exo-cart-shipping-form>
    </div>
  </div>
</div>
