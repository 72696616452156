import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Leaderboard, LeaderBoardResponse } from './leaderboard';

@Injectable()
export class LeaderboardService {
  constructor(private http: HttpClient) {}

  getLeaderboard(): Observable<LeaderBoardResponse> {
    return this.http.get<LeaderBoardResponse>('/v2/user/leaderboard');
  }
}
