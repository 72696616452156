import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { DeviceService } from '../../components/device/device.service';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '../../components/sociallogin';
import { AuthService } from '../../shared/auth/auth.service';
import { BadgesNotificationService } from '../../shared/badges/badge.notification.service';
import { RegisterService } from '../../shared/register/register.service';
import { TrackingService } from '../../shared/tracking/tracking.service';
import { Device } from '../../shared/user/device';

@Component({
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
  animations: [
    trigger('login', [

      state('*', style({ opacity: 1, transform: 'translateY(-50%)' })),

      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-47%)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(-50%)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(-50%)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-47%)' })),
      ]),
      transition('* => shake', animate('250ms ease-in-out', keyframes([
        style({ transform: 'translateY(-50%) translateX(0%)' }),
        style({ transform: 'translateY(-50%) translateX(-5%)' }),
        style({ transform: 'translateY(-50%) translateX(5%)' }),
        style({ transform: 'translateY(-50%) translateX(-5%)' }),
        style({ transform: 'translateY(-50%) translateX(0%)' }),
      ]))),
    ]),
  ],
})

export class LoginPageComponent implements OnInit, OnDestroy {
  register: boolean;
  credentials: {
    email: string,
    password: string,
  } = { email: '', password: '' };

  registerForm: FormGroup;
  checked: boolean;
  transition: string;
  status: string;
  emailResend: string;
  redirect: string;
  forgot = false;
  wrongEmail = false;
  sent = false;
  alreadyExists = false;
  resent = false;
  wrongCredentials = false;
  error: string;
  device = new Device();

  sub: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private registerService: RegisterService,
    private deviceService: DeviceService,
    private badgesNotificationService: BadgesNotificationService,
    private trackingService: TrackingService,
  ) {
    this.registerForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(72),
        Validators.pattern(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/),
        this.forbiddenPasswordValidator(['examenoverzicht', 'wachtwoord', 'password']),
      ]),
      repeatPassword: new FormControl(),
      conditions: new FormControl(),
    });
  }

  forbiddenPasswordValidator(forbiddenWords: string[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return forbiddenWords.some(word => control.value.toLowerCase().includes(word)) ? { forbiddenWord: { value: control.value } } : null;
    };
  }

  ngOnInit() {
    this.sub.push(this.route.queryParamMap.subscribe((params: ParamMap) => {
      // Defaults to false if no query param provided.
      this.register = params.get('register') === 'true';
      if (this.register) {
        this.transition = 'void';
      } else {
        this.transition = 'active';
      }
    }));

    this.deviceService.getFingerprint().then((device) => {
      this.device = device;
    });

    this.sub.push(this.route.paramMap.subscribe((params: ParamMap) => {
      this.redirect = params.get('redirect') ? params.get('redirect') : 'dashboard';
    }));

    setTimeout(() => {
      this.onChange();
    }, 0);
  }

  ngOnDestroy() {
    for (const sub of this.sub) {
      sub.unsubscribe();
    }
  }

  onSubmit() {
    this.authService.login(this.credentials, this.device).subscribe((data) => {
      if (data) {
        this.router.navigate([this.redirect]);
      } else {
        this.router.navigate(['register'], {
          queryParams: {
            email: this.credentials.email,
            password: this.credentials.password,
          }, skipLocationChange: true,
        });
      }
    },
    (err) => {
      return this.handleError(err);
    });
  }

  handleError(err) {
    switch (err.status) {
      case 401:
        this.error = 'Onbekende email/wachtwoord combinatie.';
        this.wrongCredentials = true;
        this.transition = 'shake';
        break;
      case 403:
        this.router.navigate(['register'], { queryParams: { verify: true, email: this.credentials.email }, skipLocationChange: true });
        break;
      case 406:
        this.error = `Account geblokkeerd wegens te veel mislukte inlogpogingen.
                      Er is een mail verstuurd naar ${this.credentials.email} met instructies om je account te deblokkeren.`;
        break;
      case 409:
        this.error = `Je kan op maximaal 3 apparaten inloggen. Log in op je
                      andere apparaat en beheer je instelling bij apparaat instellingen in je profiel.`;
        break;
      default:
        this.wrongCredentials = true;
        this.transition = 'shake';
        this.error = 'Er is iets verkeerd gegaan. Probeer het later opnieuw.';
        break;
    }
  }

  setBackToUnchecked() {
    if (this.transition === 'shake') {
      this.transition = 'active';
    }
  }

  resendEmail(secondTime: boolean) {
    this.registerService.resend(this.emailResend).subscribe((data) => {
      this.sent = true;
      this.wrongEmail = false;
      if (secondTime) {
        this.resent = true;
        setTimeout(() => {
          this.resent = false;
        },         10000);
      }
    },
    (err) => {
      this.wrongEmail = true;
      console.log(err);
    });
  }

  formRegister() {

    this.alreadyExists = false;
    if (this.registerForm.controls.email.status === 'VALID') {
      this.registerService.checkEmail(this.registerForm.value.email).subscribe((data) => {
        if (data) {
          this.alreadyExists = data;
        }
        else {
          this.router.navigate(['register'], {
            queryParams: {
              email: this.registerForm.controls.email.value,
              password: this.registerForm.controls.password.value,
            }, skipLocationChange: true,
          });
        }
      },
      (err) => {
        console.log(err);
      });
    }
  }

  onChange() {
    this.alreadyExists = false;
    if (this.registerForm.controls.email.status === 'VALID') {
      this.registerService.checkEmail(this.registerForm.value.email).subscribe((data) => {
        if (data) {
          this.alreadyExists = data;
        }
      },
      (err) => {
        console.log(err);
      });
    }
  }

  getUser(userSocial) {
    this.authService.getUser().subscribe((user) => {
      if (!user.courseLevel) {
        this.router.navigate(['register'], {
          queryParams: {
            accessToken: userSocial.token,
            provider: userSocial.provider,
          }, skipLocationChange: true,
        });
        return;
      }
      this.router.navigate(['dashboard']);
    });
  }

  socialLogin(provider) {
    this.sub.push(this.socialAuthService.authState.subscribe((user: any) => {
      if (user && user.provider === provider) {
        this.authService.socialLogin({ provider: provider.toLowerCase(), accessToken: user.token }, this.device).subscribe((data) => {
          this.getUser(user);
        },
        (err) => {
          if (err.error.code === 'NotFound') {
            console.log(err);
          }
          else {
            this.handleError(err.error);
          }
        });
      } else {
        switch (provider) {
          case 'GOOGLE':
            this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
            break;
          case 'FACEBOOK':
            this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
            break;
          default:
            return;
        }
      }
    }));
  }
}
