import { Component, OnInit } from '@angular/core';

import { BadgeComponent } from '../../components/badge/badge.component';
import { ModalController } from '../../components/modal/modal.controller';
import { UserBadge } from '../../shared/badges/badge';
import { BadgesService } from '../../shared/badges/badge.service';

@Component({
  selector: 'page-badges',
  templateUrl: 'badges.page.html',
  styleUrls: ['./badges.page.scss'],
})
export class BadgesPageComponent implements OnInit {
  superBadges: any[] = [];

  constructor(
    private badgesService: BadgesService,
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    this.badgesService.getBadges().subscribe((userBadges) => {
      this.superBadges = this.badgesFiltered(userBadges);
    });
  }

  openBadge(userBadge: UserBadge) {
    const displayBadge = JSON.parse(JSON.stringify(userBadge));
    displayBadge.badge.icon = userBadge.badge.icon;
    this.modalCtrl.setModal(BadgeComponent, {
      userBadge: displayBadge,
      detailed: true,
    });
  }

  getCurrentBadge(superBadge) {
    const achievedBadge =  superBadge.userBadges.slice().reverse().find(userBadge => userBadge.isUnlocked === true);
    const notYetAchievedBadge =  superBadge.userBadges.slice().find(userBadge => userBadge.isUnlocked === false);



    // (gold)
    if (achievedBadge && !notYetAchievedBadge) {
      return achievedBadge;
    }

    // bonze silver
    if (achievedBadge && notYetAchievedBadge) {
      const displayBadge = JSON.parse(JSON.stringify(notYetAchievedBadge));
      displayBadge.nextBadgeIcon = notYetAchievedBadge.badge.icon;
      displayBadge.badge.icon = achievedBadge.badge.icon;
      return displayBadge;
    }

     // locked
    if (!achievedBadge) {
      const displayBadge = JSON.parse(JSON.stringify(notYetAchievedBadge));
      displayBadge.nextBadgeIcon = notYetAchievedBadge.badge.icon;
      displayBadge.badge.icon = '/assets/badges/badge-locked.svg';
      return displayBadge;
    }

  }

  badgesFiltered(userBadges: UserBadge[]) {
    return userBadges.reduce((superBadges, userBadge) => {
      const { badge } = userBadge;
      const superBadgeIndex = superBadges.findIndex(superBadge => superBadge.name === badge.name);

      if (superBadgeIndex === -1) {
        superBadges.push({
          name: badge.name,
          userBadges: [userBadge],
        });
        return superBadges;
      }

      const updatedUserBadge = [...superBadges[superBadgeIndex].userBadges, userBadge];
      superBadges[superBadgeIndex].userBadges = updatedUserBadge;
      return superBadges;
    }, []);
  }

}
