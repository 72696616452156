export class Device {
  identifier: string;
  description: string;
  platform: string;
}

export interface OutdatedModel {
  appVersion: string;
  apiVersion: string;
  requiredVersion: string;
  satisfies: boolean;
}
