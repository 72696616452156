import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Section } from "../../shared/subjects/section";
import { Subject, SubjectWithSections } from "../../shared/subjects/subject";
import { SummaryService } from "../../shared/summaries/summary.service";

@Component({
  selector: "exo-sections-list",
  templateUrl: "sections-list.html",
  styleUrls: ["sections-list.scss"],
})
export class SectionsListComponent implements OnInit {
  @Input() subjects: Subject[];
  @Input() courseLevel: string;
  @Input() mode: string;
  @Output() selectedSectionChange = new EventEmitter();

  subjectsWithSections: SubjectWithSections[] = null;
  selectedSubject = null;
  selectedSection = null;

  constructor(private summaryService: SummaryService) { }

  ngOnInit() {
    this.summaryService.getSections(this.subjects.map(subject => subject._id)).subscribe((subjectsWithSections) => {
      this.subjectsWithSections = subjectsWithSections;
    });
  }

  collapseSubject(subject) {
    if (this.selectedSubject === subject) {
      this.selectedSubject = null;
      return;
    }
    this.selectedSubject = subject;
  }

  selectSection(section: Section) {
    this.selectedSection = section;
    this.selectedSectionChange.emit(section);
  }
}
