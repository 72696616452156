<header>
  Product koppelen
</header>
<div class="content">
  <form (ngSubmit)="onSubmit()">
    <div class="form-group">

      <label for="productCode">Productcode</label>
      <input class="form-control" type="text" id="productCode" name="productCode" [(ngModel)]="productCode" />

      <div class="status" *ngIf="status" [class.success]="success">
        {{status}}
      </div>

      <button class="btn" type="submit">Product koppelen</button>
    </div>
  </form>
</div>
