import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SocialAuthService } from './socialauth.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    SocialAuthService,
  ],
})
export class SocialLoginModule { }
