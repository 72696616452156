<div [class.simple-mode]="simpleMode">
  <h3
    *ngIf="products.length > 0"
    class="TextTitleRegular"
    [style.margin]="'32px 0 12px 0'"
  >
    Producten interessant voor jou
  </h3>

  <exo-card class="buyables-card" *ngFor="let product of products">
    <div class="content">
      <div class="flex">
        <figure class="product-figure">
          <img src="{{apiUrl}}{{product.cover}}" />
        </figure>
        <div>
          <span class="TextSubjectCheckoutTitle">{{product.description}}</span>
          <span class="TextSubjectCheckoutSubject"
            >{{product.name}} {{product.courseLevels | joinComma}}</span
          >
        </div>
      </div>

      <exo-button
        text="{{product.price | price}}"
        classes="btn-regular"
        [bgColor]="product.subjectColors[0]?.color"
        (click)="addToCart(product)"
      ></exo-button>
    </div>
  </exo-card>
</div>
