<div class="card" *ngIf="products.length > 0">
  <div class="content">
    <div class="buyable" *ngFor="let product of products" >
      <figure class="product-figure">
        <img src="{{apiUrl}}{{product.cover}}"/>
      </figure>
      <div class="product-details">
        <span class="TextSubjectCheckoutTitle">{{product.description}}</span>
        <span class="TextSubjectCheckoutSubject">{{product.name}} {{product.courseLevels | joinComma}}</span>
      </div>

      <exo-button text="{{product.price | price}}" classes="btn-regular dynamic-bgcolorf" (click)="addToCart(product)"></exo-button>
    </div>
  </div>
</div>