import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';

import { Payment, PaymentResponse, PaymentStatus } from './payment';
import { PaymentOption } from './paymentOption';

@Injectable()
export class PaymentService {
  constructor(
    private auth: HttpAuthInterceptor,
    private http: HttpClient,
  ) { }
  isEnabled = false;
  socket;

  paymentStatus: any;
  statusDescription: string;
  statusIcon: string;
  statusButton: string;

  init() {
    if (this.isEnabled || !this.auth.getToken()) {
      return;
    }

    this.connect();
  }

  private connect() {
    const options = {
      transportOptions: {
        polling: {
          extraHeaders: {
            authorization: `Bearer ${this.auth.getToken().token}`,
          },
        },
      },
    };

    this.socket = io(environment.SOCKET_URL, options);

    this.socket.on('connect_error', (err) => {
      console.log('payment error!');
      console.log(err);
    });

    this.socket.on('connect', () => {
      console.log('payment connected!');
      this.isEnabled = true;
    });

    this.socket.on('reconnect', () => {
      console.log('payment reconnected!');
    });

    this.socket.on('disconnect', () => {
      console.log('payment disconnect!');;
    });

    this.socket.on('order-service.event.order.paid', (data) => {
      this.setStatus(PaymentStatus.paid);
    });

    this.socket.on('order-service.event.order.deleted', (data) => {
      this.setStatus(PaymentStatus.canceled);
    });
  }

  setStatus(status: PaymentStatus) {
    this.paymentStatus = status;
    switch (this.paymentStatus) {
      case PaymentStatus.paid:
        this.statusIcon = '/assets/icons/check.svg';
        this.statusDescription = 'Je betaling is succesvol, veel plezier met je aankoop!';
        // this.cartService.clearCart();
        break;
      case PaymentStatus.open:
        this.statusIcon = '/assets/icons/dollar.svg';
        this.statusDescription = 'Het kan enkele minuten duren voordat je betaling is goedgekeurd.';
        break;
      case PaymentStatus.failed:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je betaling is niet gelukt, probeer het opnieuw!';
        break;
      case PaymentStatus.canceled:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je hebt de betaling geannuleerd.';
        break;
      case PaymentStatus.expired:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Je betaling is verlopen.';
        break;
      case PaymentStatus.empty:
        this.statusIcon = '/assets/icons/close.svg';
        this.statusDescription = 'Betaling niet gevonden.';
        break;
    }
  }

  getPayment(paymentId: string): Observable<Payment> {
    return this.http.get<Payment>(`/payments/${paymentId}`);
  }

  getPaymentOptions(): Observable<PaymentOption[]> {
    return this.http.get<PaymentOption[]>('/v2/payment/options');
  }

  createPayment(body: Payment): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>('/payments', body);
  }

  getDiscount(code: string, productBundles: string[]): Observable<any> {
    const productBundlesQueryString = productBundles.reduce((prev, current) => `${prev}&productBundles=${current}`, '');
    return this.http.get(`/discount?code=${code}${productBundlesQueryString}`);
  }

}
