export const environment = {
  production: false,
  API_URL: 'https://api.test.examenoverzicht.aws.coffeeit.nl',
  WEB_URL: 'https://test.examenoverzicht.aws.coffeeit.nl',
  SOCKET_URL: 'https://order.api.test.examenoverzicht.aws.coffeeit.nl',
  SOCKET_TRACKING: 'https://tracking.api.test.examenoverzicht.aws.coffeeit.nl',
  SOCKET_BADGES: 'https://gamification.api.test.examenoverzicht.aws.coffeeit.nl',
  WEBSHOP_URL: 'https://exovt.nl',
  BASE_HREF: '/',
  ORIGIN: window.location.origin,
  SENTRY_DSN: 'https://2b341395465c4e91b5bd50e0775ce450@o1037271.ingest.sentry.io/6005047',
  GOOGLE_ANALYTICS: 'UA-135466311-2',
  SOCIAL: {
    FACEBOOK: {
      CLIENT_ID: '1154889721263562',
    },
    GOOGLE: {
      CLIENT_ID:
        '643320290758-93autteso6svv94b05noll5an3e9ljel.apps.googleusercontent.com',
    },
  },
  MOBILE: {
    GOOGLE_ANALYTICS: 'UA-135466311-1',
    SOCIAL: {
      FACEBOOK: {
        CLIENT_ID: '960798547339348',
      },
      GOOGLE: {
        CLIENT_ID:
          '643320290758-93autteso6svv94b05noll5an3e9ljel.apps.googleusercontent.com',
      },
    },
  },
};
