<div class="brand">
	<div class="text">
		<h1>Mijn ExamenOverzicht account</h1>
		<hr/>
		<h2>Slagen voor het eindexamen!</h2>
		<a class="btn webshop" href="https://examenoverzicht.nl" target="blank">Naar de webshop</a>
	</div>
</div>

<div *ngIf="!register && !forgot" class="widget" [@login]="transition"
    (@login.done)="setBackToUnchecked()">
	<div class="content">
		<img class="logo" src="/assets/logo.png" height="60"/>
		<hr/>

		<div class="social row">
			<div class="col-12">
				<a class="btn facebook" (click)="socialLogin('FACEBOOK')">Facebook</a>
			</div>
			<div class="col-12">
    		<a class="btn google" (click)="socialLogin('GOOGLE')">Google</a>
			</div>
		</div>
    <hr/>

		<form #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">E-mail</label>
				<input class="form-control" [class.invalid]="wrongCredentials" type="email" placeholder="E-mail" [(ngModel)]="credentials.email" name="email" id="email" email autofocus />

        <label for="password">Wachtwoord</label>
        <a class="btn-link float-right" (click)="forgot = true"><u>Wachtwoord vergeten</u></a>
				<input class="form-control" [class.invalid]="wrongCredentials" type="password" placeholder="Wachtwoord" [(ngModel)]="credentials.password" name="password" id="password" minlength="6" />

				<button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid">Log in</button>
				<button class="btn btn-outline float-right" type="button" (click)="router.navigate(['login'], { queryParams: { register: true } })">Maak een account</button>

				<div *ngIf="error" class="error">{{error}}</div>
      </div>
		</form>
	</div>
</div>

<div *ngIf="forgot" class="widget" [@login]>
	<header>
		<span (click)="forgot = sent; sent = false" class="fa fa-arrow-left"></span>
		Wachtwoord vergeten
	</header>
	<div *ngIf="!sent" class="content">
		<form #resendForm="ngForm" (ngSubmit)="resendEmail(false)">
			<div class="form-group">
				<label for="resendEmail">Vul hier je e-mailadres in en we sturen je instructies om je wachtwoord te resetten</label>
				<input class="form-control" [class.invalid]="wrongEmail" type="email" [(ngModel)]="emailResend" name="resendEmail" id="resendEmail" email autofocus />

				<div *ngIf="wrongEmail" class="wrongEmail">Er is iets verkeerd gegaan, probeer het later opnieuw</div>

				<button class="btn btn-primary" type="submit" [disabled]="resendForm.invalid">Verstuur</button>
			</div>
		</form>
	</div>
	<div *ngIf="sent" class="content">
    Als het door jou ingevulde e-mailadres klopt,<br>hebben we je een e-mail verstuurd met instructies om je wachtwoord te resetten.
		<div class="resend">
			<div *ngIf="!resent">
				Geen mail ontvangen?
				<a class="btn-link" (click)="resendEmail(true)"><u>Verstuur opnieuw</u></a>
			</div>
			<div *ngIf="resent">We hebben je een nieuwe code gestuurd</div>
			<button class="btn btn-primary" (click)="forgot = false; sent = false">Terug naar login</button>
		</div>
	</div>
</div>

<div *ngIf="register" class="widget" [@login]="transition == 'void' ? 'active' : 'void'">
	<div class="content">
		<p>Registreren</p>
		<hr/>

		<div class="social row">
			<div class="col-12">
				<a class="btn facebook" (click)="socialLogin('FACEBOOK')">Facebook</a>
			</div>
			<div class="col-12">
				<a class="btn google" (click)="socialLogin('GOOGLE')">Google</a>
			</div>
		</div>
    <hr/>

		<form [formGroup]="registerForm" (ngSubmit)="formRegister()">
      <label for="email">E-mail</label>
      <span class="float-right" style="color: red" *ngIf="alreadyExists">e-mailadres al in gebruik</span>
      <input class="form-control" type="email" placeholder="E-mail" formControlName="email" id="email" (change)="onChange()" autofocus />

      <label for="password">Wachtwoord</label>
      <input class="form-control" autocomplete="new-password" type="password" placeholder="Wachtwoord" formControlName="password" id="password" />

      <label for="repeatPassword">Herhaal wachtwoord</label>
      <input class="form-control" autocomplete="new-password" [class.notsame]="registerForm.controls.password.touched && registerForm.value.repeatPassword !== registerForm.value.password" type="password" placeholder="Herhaal wachtwoord" formControlName="repeatPassword" id="repeatPassword" />

      <span class="checkbox">
        <input class="form-control" type="checkbox" formControlName="conditions" id="conditions" />
        <label for="conditions"></label>
      </span>
      <span>Ik accepteer de <a class="conditions" href="https://www.examenoverzicht.nl/algemene-voorwaarden" target="_blank">algemene voorwaarden</a></span>

      <div class="status" *ngIf="registerForm.controls.password.errors?.minlength">
        Wachtwoord moet minimaal 8 tekens bevatten.
	  </div>
	  <div class="status" *ngIf="registerForm.controls.password.errors?.maxlength">
        Wachtwoord mag maximaal 72 karakters bevatten.
      </div>
      <div class="status" *ngIf="registerForm.controls.password.errors?.pattern">
        Wachtwoord moet minimaal een letter en een speciaal teken of cijfer bevatten.
      </div>
      <div class="status" *ngIf="registerForm.controls.password.errors?.forbiddenWord">
        Wachtwoord mag niet de woorden 'examenoverzicht', 'password' of 'wachtwoord' bevatten.
      </div>
      <div class="status" *ngIf="registerForm.controls.password.touched && registerForm.value.password !== registerForm.value.repeatPassword">
        Wachtwoorden komen niet overeen.
      </div>

      <button class="btn btn-primary" type="submit" [disabled]="!registerForm.value.conditions || registerForm.invalid || alreadyExists || registerForm.value.password !== registerForm.value.repeatPassword">Registreren</button>
      <button class="btn btn-outline float-right" type="button" (click)="router.navigate(['login'])">Inloggen</button>
		</form>
	</div>
</div>
