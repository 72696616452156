<header>
  <span class="header-text">Maak je profiel compleet 🎉</span>
</header>
<div class="content">

  <div class="stepper">
    <span *ngFor="let item of allStates; index as i;" [class.active]="i < profileDoneCount()"></span>
  </div>

  <div class="step-block" (click)="popupIsVisible = true; popupType = state">
    <button class="add-btn"></button>
    <div class="name-text">
      <span class="next-step-text">Volgende stap</span>
      <span class="step-name-text" *ngIf="state === 'username'">Kies een gebruikersnaam</span>
      <span class="step-name-text" *ngIf="state === 'school'">Voeg je school toe</span>
    </div>

    <span *ngIf="state === 'username'" class="step-icon">😎</span>
    <span *ngIf="state === 'school'" class="step-icon">🎓</span>

  </div>

</div>

<exo-choose-username *ngIf="popupIsVisible && popupType === 'username'" [@popup] (out)="data($event)" [user]="user"></exo-choose-username>
<exo-choose-school *ngIf="popupIsVisible && popupType === 'school'" [@popup] (out)="data($event)" [user]="user"></exo-choose-school>
<div class="popup" *ngIf="popupIsVisible" [@overlay] (click)="popupIsVisible = false"></div>
