import {
    animate,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const SCHEDULE_ANIMTIONS = [
  trigger('popup', [
    state('*', style({ opacity: 1, transform: 'translateY(0)' })),
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(10px)' }),
      animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateY(0)' }),
      animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-10px)' })),
    ]),
  ]),
];
