import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(list: any[], filterText: string): any {
    if (list[0] && !list[0].name) {
      return list
        ? list.filter((item) => {
          return item.search(new RegExp(filterText, 'i')) > -1;
        })
        : [];
    }
    return list;
  }
}
