import { Component, OnInit } from '@angular/core';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { PaymentService } from '../../../shared/payment/payment.service';

@Component({
  selector: 'exo-cart-discount',
  templateUrl: 'discount.html',
  styleUrls: ['discount.scss'],
})
export class CartDiscountComponent implements OnInit {
  public cart: Cart;
  public shippingCost = this.cartService.SHIPPING_COST;
  public freeShippingRequirementPrice = this.cartService.FREE_SHIPPING_REQUIREMENT_PRICE;

  public discountCode: string;
  public discountError: string;

  constructor(
    private cartService: CartService,
    private paymentService: PaymentService,
  ) {
  }

  ngOnInit() {
    this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
    });
  }

  submit() {
    if (!this.discountCode) { return; }
    const products = this.cart.items.map(product => product._id);

    this.paymentService.getDiscount(this.discountCode, products).subscribe((res) => {
      this.discountError = '';

      this.cartService.addDiscount({
        code: this.discountCode,
        amount: parseInt(res.discount),
        description: res.description,
      });
    },
      (error) => {
        this.cartService.clearDiscount();
        switch (error.status) {
          case 404: this.discountError = 'Kortingscode niet gevonden'; break;
          case 409: this.discountError = 'De kortingscode is al gebruikt'; break;
          default: this.discountError = 'Er ging iets mis bij het toepassen van de kortingscode';
        }
      });
  }

}
