import { Component, HostListener, OnInit } from '@angular/core';

import { AuthService } from '../../shared/auth/auth.service';
import { Exam } from '../../shared/subjects/exam';
import { Course, Subject } from '../../shared/subjects/subject';
import { SubjectService } from '../../shared/subjects/subject.service';

import { SCHEDULE_ANIMTIONS } from './schedule.animations';

@Component({
  selector: 'exo-schedule',
  templateUrl: 'schedule.page.html',
  styleUrls: ['schedule.page.scss'],
  animations: SCHEDULE_ANIMTIONS,
})

export class SchedulePageComponent implements OnInit {
  courseLevel: string;
  selectedExam;

  popup = false;
  show = true;
  showdropdown = false;
  slot = '1';

  math: any;

  schedule = [];
  nosubjects = false;

  subjects: Course[];
  mySubjects: Course[];
  extraSubjects: Course[];

  timeslots = ['1'];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.showdropdown = false;
  }

  constructor(private subjectService: SubjectService, private authService: AuthService) { }

  ngOnInit() {
    this.math = Math;
    this.authService.getUser().subscribe((user) => {
      this.courseLevel = user.courseLevel;

      this.subjectService.getCourses(user.courseLevel).subscribe((subjects) => {
        subjects.map((subject) => {
          subject.extra = true;
          subject.color = '#bbbbbb';
          return subject;
        });

        this.extraSubjects = subjects;
      },
      (err) => {
        console.log(err);
      });
    });

    this.subjectService.getSubjects().subscribe((subjects) => {
      this.mySubjects = subjects;
      this.subjects = subjects;
      this.toggleCourses();
    },
    (err) => {
      console.log(err);
    });

    this.subjectService.getShowTimeslots().subscribe((timeslots) => {
      this.timeslots = Array.from({ length: timeslots.numberOfTimeslots }, (v, k) => (k + 1).toString());
    },
    (err) => {
      console.log(err);
    });
  }

  toggle(newValue) {
    this.show = newValue;
    this.toggleCourses();
  }

  toggleCourses() {
    if (this.show) {
      this.subjects = [...this.mySubjects];
      this.buildSchedule();
    } else {
      this.extraSubjects = this.extraSubjects.filter(subject =>
        this.mySubjects.findIndex(mySubject => mySubject._id === subject._id) === -1);

      this.subjects = [...this.mySubjects, ...this.extraSubjects];
      this.buildSchedule();
    }
  }

  toggleTimeslot(slot) {
    this.slot = slot;
    this.buildSchedule();
  }

  showpopup(exam) {
    this.selectedExam = exam;
    this.popup = true;
  }

  buildSchedule() {
    if (!this.subjects || this.subjects.length === 0) {
      this.nosubjects = true;
      return;
    }
    this.nosubjects = false;

    this.schedule = [];

    let firstExamDate: any = Date.parse(this.subjects[0].timeslots[this.slot].startDate);
    let lastExamDate: any = 0;

    for (const subject of this.subjects) {
      firstExamDate = Math.min(firstExamDate, Date.parse(subject.timeslots[this.slot].startDate));
      lastExamDate = Math.max(lastExamDate, Date.parse(subject.timeslots[this.slot].endDate));
    }

    firstExamDate = new Date(firstExamDate).setHours(0, 0, 0, 0);

    for (let i = firstExamDate; i <= lastExamDate; i += 1000 * 60 * 60 * 24) {
      const d = new Date(i);

      if (d.getDay() === 6 || d.getDay() === 0) {
        // only put one weekend entry in the array
        if (d.getDay()) {
          this.schedule.push({
            jsDate: d,
            weekend: true,
            exams: [],
          });
        }
      } else {
        this.schedule.push({
          jsDate: d,
          exams: [],
        });
      }
    }

    this.placeSubjects();
  }

  placeSubjects() {
    for (const subject of this.subjects) {
      const exam = new Exam(subject as Subject);

      let d = new Date(subject.timeslots[this.slot].startDate);

      exam.startDate = d;
      exam.position.top = (60 * d.getHours() + d.getMinutes() - 480) / 5.4;

      d = new Date(subject.timeslots[this.slot].endDate);
      exam.endDate = d;
      exam.position.bottom = 100 - (60 * d.getHours() + d.getMinutes() - 480) / 5.4;

      // place subjects in exam array of right day
      const index = this.schedule.findIndex(date =>
        date.jsDate.toDateString() === new Date(exam.subject.timeslots[this.slot].startDate).toDateString(),
      );

      if (index > -1) {
        this.schedule[index].exams.push(exam);
      }
    }

    this.calcOverlapDepth();
  }

  calcOverlapDepth() {
    this.schedule.forEach((examDate) => {
      // Loop trough all exams on same date
      examDate.exams.forEach((exam) => {
        // Check where the exam overlaps with other exams
        const overlapsWithExams = this.checkOverlay(exam, examDate.exams);
        exam.position.overlapDepth.total = overlapsWithExams.length;

        // Set left indentation of overlapping exams
        for (let i = 0; i < overlapsWithExams.length; i++) {
          overlapsWithExams[i].position.overlapDepth.this = i;
        }
      });
    });
  }

  checkOverlay(exam: Exam, exams: Exam[]) {
    const overlapsWith = [];

    // Check for time intersections with other exam
    exams.forEach((examToCheck) => {
      if (exam.startDate >= examToCheck.startDate && exam.startDate < examToCheck.endDate ||
        exam.endDate <= examToCheck.endDate && exam.endDate > examToCheck.startDate) {
        overlapsWith.push(examToCheck);
      }
    });

    return overlapsWith;
  }

  print() {
    // Fix for bug in typescript 2.8.1. This will be solved with the release of version 2.8.2
    (window as any).print();
  }

}
