<div class="widget">
  <header>
    <span (click)="out.emit({back: true})" class="fa fa-arrow-left"></span>
    <p>Wat voor type leerling ben jij?</p>
  </header>
  <div class="content">
    <div class="goals">
      <button *ngFor="let studentType of studentTypes" class="btn btn-goal"
      [class.btn-primary]="containsStudentType(studentType)"
      (click)="setStudentType(studentType)">{{studentType.name}}</button>
    </div>
  </div>
</div>

<div class="widget">
  <header>
    <p>Hoe goed ben jij al voorbereid voor het eindexamen?</p>
  </header>
  <div class="content">
    <exo-slider [labels]="sliderLabels" [min]="0" [max]="10" [sliderValue]="5" (valueChange)="preparationChange($event)"></exo-slider>
  </div>
  <div class="footer">
    <button class="btn btn-primary" (click)="submit()">Volgende</button>
  </div>
</div>
