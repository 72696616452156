import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { RawDefinitionModel } from '../definitions/models/raw-definition.model';
import { Document } from '../summaries/document';
import { setCoverUrlToSummary } from '../util/helpers';

import { environment } from './../environments/environment';
import { Summary } from './../summaries/summary';
import { Subject } from './subject';

const SUBJECT_CACHE_TIME_MS = 10000;

@Injectable()
export class SubjectService {

  private subjects: ReplaySubject<Subject[]> = new ReplaySubject(1);
  private last_cache_time_ms = 0;

  private subject: Subject;
  private subjectChange: BehaviorSubject<Subject> = new BehaviorSubject<Subject>(null);
  public subjectChange$ = this.subjectChange.asObservable();

  constructor(private http: HttpClient) {
  }

  getSubjects(): ReplaySubject<Subject[]> {
    if (!(Date.now() > this.last_cache_time_ms + SUBJECT_CACHE_TIME_MS)) {
      return this.subjects;
    }

    this.last_cache_time_ms = Date.now();
    this.http.get<{ subject: Subject, grade: number }[]>('/user/subjects').pipe(first()).pipe(
      map(subjects => subjects
        .map(subject => ({ ...subject.subject, grade: subject.grade, subjectImage: environment.API_URL + subject.subject.subjectImage })),
      ))
      .subscribe({
        next: (subjects: Subject[]) => {
          this.subjects.next(subjects);
        },
        error: (err) => {
          this.subjects.next([]);
        }
      });

    return this.subjects;
  }

  reloadSubjects() {
    this.last_cache_time_ms = 0;
    return new Promise((resolve) => {
      this.getSubjects().pipe(first()).subscribe((subjects) => {
        resolve(subjects);
      });
    });
  }

  getCourses(courseLevel: string, categories = ['Exacte vakken', 'Talen', 'Maatschappij vakken', 'Extra vakken']): Observable<any[]> {
    const params = new HttpParams()
      .set('courseLevel', courseLevel)
      .set('categories', categories.join(','));
    return this.http.get<Subject[]>('/courseLevel/subjects', { params })
      .pipe(map(subjects => subjects.map(subject => ({ ...subject, subjectImage: environment.API_URL + subject.subjectImage }))));
  }

  setGrade(subjectId: string, grade: number): Observable<any> {
    return this.http.put(`/user/edit/${subjectId}/grade`, { grade: grade.toString() });
  }

  // subject
  getSubjectById(subjectId: string): Observable<Subject> {
    return this.http.get<Subject>(`/subject/${subjectId}`).pipe(tap((subject) => {
      this.setCurrentSubject(subject);
      this.setDynamicColor(subject);
    }));
  }

  getSubjectBySlug(encodedName: string): Observable<Subject> {
    return this.http.get<Subject>(`/subjects/${encodedName}`).pipe(tap((subject) => {
      this.setCurrentSubject(subject);
      this.setDynamicColor(subject);
    }));
  }

  putSubject(subject: any): Observable<Subject> {
    return this.http.put<Subject>(`/subjects/${subject._id}`, subject);
  }

  // ADMIN API DOCUMENTS ENDPOINTS

  getSummaryBySubjectEncodedNameAdmin(encodedSubjectName: string): Observable<Summary> {
    return this.http.get(`/summaries/${encodedSubjectName}/all`).pipe(map(res => setCoverUrlToSummary(res)));
  }

  getRawDocument(docId: string): Observable<{ html: string }> {
    return this.http.get<{ html: string }>(`/summaries/documents/${docId}`);
  }

  createDocument(encodedSubjectName: string, html: string): Observable<Summary> {
    return this.http.post<Summary>(`/summaries/documents/${encodedSubjectName}`, { html });
  }

  updateDocument(docId: string, html: string): Observable<Summary> {
    return this.http.put<Summary>(`/summaries/documents/${docId}`, { html });
  }

  deleteDocument(docId: string): Observable<Summary> {
    return this.http.delete<Summary>(`/summaries/documents/${docId}`);
  }

  activateSummary(encodedSubjectName: string, docId: string | number): Observable<Summary> {
    return this.http.post(`/summaries/documents/${docId}/activate`, {}).pipe(
      map(res => setCoverUrlToSummary(res)),
    );
  }

  duplicateDocument(docId: string): Observable<Document> {
    return this.http.post<Document>(`/documents/${docId}/duplicate`, {});
  }

  updateSummaryDocument(docId: string, newProperties: object): Observable<Document> {
    return this.http.patch<Document>(`/documents/${docId}`, newProperties);
  }

  uploadAnswersPDF(summaryId: string, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('answer', file, file.name);

    return this.http.put(`/summaries/${summaryId}/answers`, formData);
  }

  // definition
  private getNewDefinitions = (d: RawDefinitionModel[]) => d.find(e => !e['name']) || d[0];

  getDefinitions(subjectId: string): Observable<RawDefinitionModel> {
    return this.http.get(`/subject/${subjectId}/definitions`).pipe(map(this.getNewDefinitions));
  }

  createDefinition(subjectId: string, definition: any): Observable<RawDefinitionModel> {
    return this.http.post(`/subject/${subjectId}/definitions`, definition).pipe(map(this.getNewDefinitions));
  }

  editDefinition(definitionId: string, term: string, definition: string) {
    return this.http.put(`/definitions/${definitionId}`, { term, definition });
  }

  deleteDefinition(definitionId: string): Observable<object> {
    return this.http.delete(`/definitions/${definitionId}`);
  }

  // admin
  getUserSubjects(userId: string): Observable<Subject[]> {
    return this.http.get<Subject[]>(`/user/${userId}/subjects`);
  }

  putUserSubjects(userId: string, subjects: { _id: string }[]): Observable<any> {
    return this.http.put(`/user/${userId}/subjects`, { subjects });
  }

  getCurrentSubject() {
    return this.subject;
  }

  setCurrentSubject(subject: Subject) {
    this.subject = subject;
    this.subjectChange.next(subject);
  }

  removeCurrentSubject() {
    this.subject = null;
    this.subjectChange.next(null);
  }

  // misc
  getShowTimeslots(): Observable<any> {
    return this.http.get(`/show-timeslots`);
  }

  private setDynamicColor(subject: Subject) {
    const css = `
    .dynamic-color { color: ${subject.color} !important; }
    .dynamic-bgcolor { background-color: ${subject.color}; }
    .dynamic-bgcolorf { background-color: ${subject.color} !important; }
    .dynamic-fill { fill: ${subject.color} !important; }
    .dynamic-stroke { stroke: ${subject.color} !important; }`;

    const head = document.head || document.getElementsByTagName('head')[0];
    const style: any = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) { style.styleSheet.cssText = css; }
    else { style.appendChild(document.createTextNode(css)); }
  }

}
