import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ModalController } from '../../../components/modal/modal.controller';
import { TopicModalComponent } from '../../../components/modals/topic/topic-modal.component';
import { gradeScaleGetter } from '../../../services/util/grade-scale-getter';

import { mathRounder } from '../../../services/util/math-rounder';
import { timefy } from '../../../services/util/timefy';
import { Subject } from '../../../shared/subjects/subject';
import { SummaryTopic } from '../../../shared/summaries/summary';
import { Question } from '../../../shared/training/question';
import { Results } from '../../../shared/training/results';

import { SubjectService } from './../../../shared/subjects/subject.service';
import { TrainingService } from './../../../shared/training/training.service';
import { exerciseAnimation } from './../exercise.animations';

@Component({
  selector: 'exo-results-exercise',
  templateUrl: 'results.page.html',
  styleUrls: ['results.page.scss'],
  animations: exerciseAnimation,
})

export class ResultsTrainingPageComponent implements OnInit, OnDestroy {
  sub: Subscription;
  subject: Subject;
  results: Results;
  loaded = false;
  animationDuration = 350;
  message: string;
  mathRounder = mathRounder;
  timefy = timefy;

  constructor(
    private router: Router,
    private subjectService: SubjectService,
    private trainingService: TrainingService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.sub = this.subjectService.subjectChange$.subscribe((subject: Subject) => {
      this.subject = subject;
      if (!this.loaded && this.subject) {
        this.saveResults();
      }
    });
  }

  private saveResults() {
    this.trainingService.saveResults(this.subject.encodedName).subscribe((results: Results) => {
      
      this.results = results;
      this.setResultsMessage(results.averageRating);
      this.loaded = true;
    });
  }

  public close() {
    this.router.navigate(['/subject', this.subject.encodedName, 'home']);
  }

  public canDeactivate() {
    this.loaded = false;
    return timer(this.animationDuration).pipe(mapTo(true)).toPromise();
  }

  private setResultsMessage(score: number) {
    // tslint:disable-next-line: max-line-length
    if (score >= 1 && score < 5.5) { this.message = 'Oei, er valt nog wat te verbeteren aan je cijfer. Maar wees niet ongerust, we gaan jou helpen om beter te scoren op het eindexamen. Hieronder kun je je score per onderdeel zien. Leer voor deze onderwerpen nog een keer de theorie. Lees onze samenvatting en bekijk een uitlegvideo. Zo ga je volgende keer hoger scoren!'; }
    // tslint:disable-next-line: max-line-length
    if (score >= 5.5 && score < 7) { this.message = 'Je hebt een voldoende gescoord. Dat is fijn. Beter een zes zonder stress dan een zeven zonder leven. Maar niet te snel juichen. Er is bij jou nog ruimte voor verbetering. Hieronder kun je je score per onderdeel zien. Zo kun je die onderwerpen extra leren en oefenen, zodat je volgende keer een hogere score haalt.'; }
    // tslint:disable-next-line: max-line-length
    if (score >= 7 && score < 8) { this.message = 'Goed gedaan. Je hebt flink wat vragen goed gemaakt. Je kunt nu vast een leraar bedenken die trots op je is. Hieronder kun je je score per onderdeel zien. Zo kun je die onderwerpen extra leren en oefenen, zodat je volgende keer een hogere score haalt.'; }
    // tslint:disable-next-line: max-line-length
    if (score >= 8) { this.message = 'Uitstekend gedaan! Je hebt een mooie score behaald. Je behoort tot de top. Hieronder kun je je score per onderdeel zien. Ga zo door en dan heb je dat diploma straks binnen! Weet je zeker dat je dan niet bij ons wilt komen werken?'; }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getGradeColor(question: Question) {
    return gradeScaleGetter(question.scoredPoints, question.totalPoints);
  }

  getImprovementColor(rating: number) {
    return rating < 0 ? 'negative' : 'positive';
  }

  openTopic(topic: SummaryTopic) {
    this.modalCtrl.setModal(TopicModalComponent,
      {
        subject: this.subject,
        encodedTopicName: topic.encodedTopicName,
      },
    );
  }
}
