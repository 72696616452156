import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Oldexam } from './oldexam';

@Injectable()
export class OldexamsService {

  constructor(private http: HttpClient) { }

  getOldexams(subjectId: string): Observable<Oldexam[]> {
    return this.http.get<Oldexam[]>(`/subject/${subjectId}/exams`);
  }

  getExamByYear(subjectId: string, year: number): Observable<Oldexam> {
    return this.getOldexams(subjectId).pipe(mergeMap(exams => exams), filter(exam => exam.year === Number(year)));
  }

  createOldExamYear(subjectId: string, year: number, title: string): Observable<Oldexam> {
    return this.http.post<Oldexam>(`/subject/${subjectId}/exam`, { subjectId, year, title });
  }

  deleteOldExamYear(oldexamId: string): Observable<any> {
    return this.http.delete(`/subject/exam/${oldexamId}`);
  }

  uploadOldExamFile(oldexamId: string, formData: FormData): Observable<any> {
    return this.http.post(`/subject/exam/${oldexamId}/examFile`, formData);
  }

  removeOldExamFile(oldexamId: string, period: number, fileType: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { period, fileType },
    };
    return this.http.request('delete', `/subject/exam/${oldexamId}/examFile`, options);
  }

}
