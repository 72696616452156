import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { AuthService } from '../../shared/auth/auth.service';

@Component({
  template: '',
})
export class UnblockAccountPageComponent implements OnInit, OnDestroy {
  key: string;
  sub: any;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {
    this.sub = this.route.paramMap.subscribe((params: ParamMap) => {
      this.key = params.get('key');
      if (!this.key || this.key === '') {
        this.router.navigate(['login']);
      }

      this.authService.unblockAccount(this.key).subscribe((data) => {
        this.router.navigate(['login']);
      },
      (err) => {
        this.router.navigate(['login']);
      });
    },
    (err) => {
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
