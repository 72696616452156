<ul class="subjects-list">
  <li
    [class.active]="selectedSubject === subject"
    *ngFor="let subject of subjectsWithSections"
  >
    <div (click)="collapseSubject(subject)">
      <img [src]="subject.subjectImage" alt="{{subject.name}}" />
      <span>{{subject.name}}</span>
    </div>
    <ul class="sections-list">
      <li
        *ngFor="let section of selectedSubject?.sections"
        (click)="selectSection(section)"
      >
        <span class="section-name">{{section.sectionTitle}}</span>
        <label class="checker">
          <input
            type="radio"
            [checked]="selectedSection?.encodedSectionName === section?.encodedSectionName"
          />
        </label>
      </li>
    </ul>
  </li>
</ul>
