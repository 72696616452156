<div class="card" *ngIf="userBadge">
  <div class="content">
    <img [src]="userBadge?.badge?.icon ? userBadge?.badge?.icon : '/assets/badges/badge-locked.svg'" />

    <div class="block">
      <span class="title">{{userBadge?.badge?.name}}</span>

      <div class="progress-block" *ngIf="userBadge?.progress?.length > 0">
        <div class="progress-bar">
          <span class="progress-struggle" [style.width]="calcProgress(userBadge)"></span>
        </div>
        <span class="amount">{{amount(userBadge)}}/{{targetAmount(userBadge)}}</span>
      </div>

      <button class="badges-link" (click)="gotoBadges()">Bekijk alle badges</button>

    </div>
  </div>
</div>