<header>
  Volgende examen
</header>

<div class="content">
  <div class="subject-code">
    <img src="{{subject.subjectImage}}"/>
  </div>
  <div class="info">
    <div class="subject">
      {{subject.name}}
    </div>
    <div class="days">
      {{days === 0 ? 'Vandaag' : days === 1 ? 'Over 1 dag' : 'Over ' + days + ' dagen'}}
    </div>
    <div *ngIf="subject.grade" class="grade">
      Benodigd cijfer: {{subject.grade | number}}
    </div>
  </div>
</div>

<div class="buttons" *ngIf="subject.category !== 'Extra vakken'">
  <a [routerLink]="['/subject', subject.encodedName, 'summary']" class="btn btn-sm btn-primary">Samenvatting</a>
  <a [routerLink]="['/subject', subject.encodedName, 'definitions']" *ngIf="hasDefinitions" class="btn btn-sm">Begrippenlijst</a>
</div>
