<div class="container">
  <div class="row row-nav">
    <exo-dashboard-nav
      [subjects]="normalSubjects"
      [extraSubjects]="extraSubjects"
      *ngIf="!loading"
    ></exo-dashboard-nav>
  </div>

  <div class="row row-push">
    <div *ngIf="newUser" class="col-md-12" [@newUser]>
      <div class="card newuser">
        <header>
          Welkom bij jouw ExamenOverzicht account
          <span
            class="fa fa-times"
            (click)="registerService.newUser = false; newUser = false"
          ></span>
        </header>

        <div class="content">
          Slagen voor het eindexamen doe je met ExamenOverzicht!
          <br />
          Leer de examenstof, oefen oude examens en check je persoonlijke
          examenrooster.
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div
        class="card"
        *ngIf="user && (user?.nickname && user?.nickname?.isGenerated || !user?.school)"
      >
        <exo-complete-profile
          *ngIf="user"
          [(user)]="user"
          (out)="data($event)"
        ></exo-complete-profile>
      </div>

      <exo-dashboard-continue
        [subjects]="subjects"
        *ngIf="subjects"
      ></exo-dashboard-continue>

      <exo-feature name="badges">
        <exo-badges-featured></exo-badges-featured>
      </exo-feature>

      <div class="card">
        <header>Aanbevolen voor jou</header>
        <exo-cart-buyables simpleMode="true"></exo-cart-buyables>
      </div>

      <div class="card" *ngIf="extraSubjects && extraSubjects.length > 0">
        <exo-subjects [extra]="true" [subjects]="extraSubjects"></exo-subjects>
      </div>
    </div>

    <div class="col-md-5">
      <exo-leaderboard></exo-leaderboard>

      <div class="card" *ngIf="!hasSummaries">
        <exo-add-summary [dashboard]="true"></exo-add-summary>
      </div>

      <div class="card" *ngIf="nextSubject">
        <exo-next-subject [subject]="nextSubject"></exo-next-subject>
      </div>

      <exo-tip></exo-tip>

      <exo-video></exo-video>

      <div class="card" *ngIf="hasSummaries">
        <exo-add-summary [dashboard]="true"></exo-add-summary>
      </div>
    </div>
  </div>
</div>
