<div class="container desktop">
  <header>
    Rooster {{courseLevel}}
    <span *ngIf="schedule.length > 0">{{schedule[0].jsDate | date:'MMM y'}}</span>

    <div class="toggles">
      <div class="courses">
        <span class="checkbox">
          <input class="form-control" (ngModelChange)="toggle($event)" [ngModel]="show" type="checkbox" id="courses" name="courses"/>
          <label for="courses"></label>
        </span>
        Alleen mijn vakken
      </div>
      <div class="timeslots" [class.multiple]="timeslots.length > 1" (click)="showdropdown = true">
        {{slot}}e tijdvak
        <span class="fa fa-caret-down"></span>
      </div>
      <div *ngIf="showdropdown" class="overlay" (click)="showdropdown = false"></div>
      <ul *ngIf="showdropdown" class="dropdown" [@popup]>
        <li *ngFor="let timeslot of timeslots" class="list-group-item" (click)="toggleTimeslot(timeslot); showdropdown = false">
          {{timeslot}}e tijdvak
        </li>
      </ul>
    </div>

    <img class="logo" src="/assets/logo.png" height="60"/>

    <span *ngIf="!nosubjects" class="fa fa-print" (click)="print()">
      <span>Print deze pagina</span>
    </span>
  </header>

  <exo-loader *ngIf="schedule.length === 0 && !nosubjects"></exo-loader>
  <div class="nosubjects" *ngIf="nosubjects">
    Je hebt geen vakken geselecteerd. Voeg ze toe aan je account om je persoonlijke rooster te bekijken.
  </div>

  <div id="printSection" class="outer" *ngIf="schedule.length > 0">
    <ul class="dates">
      <li *ngFor="let date of schedule" [style.width]="100 / schedule.length + '%'">{{date.weekend ? 'weekend' : date.jsDate | date:'EE dd'}}</li>
    </ul>
    <ul class="times">
      <li class="spacer"></li>
      <li>9:00</li>
      <li>10:00</li>
      <li>11:00</li>
      <li>12:00</li>
      <li>13:00</li>
      <li>14:00</li>
      <li>15:00</li>
      <li>16:00</li>
    </ul>

    <div class="inner">
      <ul class="x" *ngFor="let date of schedule; let i = index" [style.width]="100 / schedule.length + '%'" [class.weekend]="date.weekend">
        <li *ngFor="let i of [0,1,2,3,4,5,6,7,8]"></li>
        <ng-container *ngFor="let exam of date.exams">
          <div class="exam" (mouseover)="showpopup(exam)" (mouseleave)="popup = false" [style.top]="exam.position.top + '%'" [style.bottom]="exam.position.bottom + '%'"
            [style.width]="'calc(' + 100 / exam.position.overlapDepth.total + '% - ' + 10 / exam.position.overlapDepth.total + 'px)'"
            [style.left]="exam.position.overlapDepth.this ? 'calc(' + (exam.position.overlapDepth.this * 100 / exam.position.overlapDepth.total) + '% - ' + (-5 + 10 / (exam.position.overlapDepth.total) * exam.position.overlapDepth.this) + 'px)' : '5px'"
            [style.background-color]="exam.subject.color">
            <div class="code">{{exam.subject.code}}</div>
            <div *ngIf="show" class="starttime">{{exam.startDate | date:'HH:mm'}}</div>
            <div *ngIf="show" class="endtime">{{exam.endDate | date:'HH:mm'}}</div>

            <div *ngIf="popup && selectedExam === exam" class="popup widget" [@popup] [class.left]="i / schedule.length > 0.6">
              <header>
                <div class="subject-code">
                  <img src="{{exam.subject.subjectImage}}"/>
                </div>
                <div class="info">
                  <div class="subject">
                    {{exam.subject.name}}
                  </div>
                  <div class="date">
                    {{date.jsDate | date:'EEEE dd MMM'}}
                  </div>
                </div>
                <span *ngIf="exam.subject.courseLevel !== courseLevel">{{exam.subject.courseLevel}}</span>
              </header>

              <div class="content">
                <div class="time">Van {{exam.startDate | date:'HH:mm'}} tot {{exam.endDate | date:'HH:mm'}}</div>
                <div class="duration">Dit examen duurt {{math.floor((exam.endDate - exam.startDate) / 3600000) | number:'1.0-0'}} uur {{(exam.endDate.getMinutes()
                  - exam.startDate.getMinutes() + 60) % 60 === 0 ? '' : ' en ' + (exam.endDate.getMinutes() - exam.startDate.getMinutes()
                  + 60) % 60 + ' minuten'}}</div>
                <div *ngIf="!exam.subject.extra && exam.subject.category !== 'Extra vakken'" class="buttons">
                  <a [routerLink]="['/subject', exam.subject.encodedName, 'summary']" class="btn btn-primary">Samenvatting</a>
                  <a [routerLink]="['/subject', exam.subject.encodedName, 'definitions']" class="btn">Begrippenlijst</a>
                </div>
              </div>
            </div>
            <div *ngIf="popup && selectedExam === exam" class="overlay" [class.left]="i / schedule.length > 0.6"></div>
          </div>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<div class="container mobile">
  <div class="selectSchedule">
    <div class="toggles">
      <div class="courses">
        <span class="checkbox">
          <input class="form-control" (ngModelChange)="toggle($event)" [ngModel]="show" type="checkbox" id="courses" name="courses"/>
          <label for="courses"></label>
        </span>
        Alleen mijn vakken
      </div>
      <div class="timeslots" (click)="showdropdown = true">
        {{slot}}e tijdvak
        <span class="fa fa-caret-down"></span>
      </div>
      <div *ngIf="showdropdown" class="overlay" (click)="showdropdown = false"></div>
      <ul *ngIf="showdropdown" class="dropdown" [@popup]>
        <li *ngFor="let timeslot of timeslots" class="list-group-item" (click)="toggleTimeslot(timeslot); showdropdown = false">
          {{timeslot}}e tijdvak
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="schedule.length > 0">
    <div class="day" *ngFor="let date of schedule" [class.weekend]="date.weekend">
      <div class="date">
        {{date.weekend ? 'weekend' : date.jsDate | date:'EE dd'}}
      </div>
      <ul class="times" *ngIf="!date.weekend">
        <li class="spacer"></li>
        <li>09</li>
        <li>10</li>
        <li>11</li>
        <li>12</li>
        <li>13</li>
        <li>14</li>
        <li>15</li>
        <li>16</li>
      </ul>

      <ul class="x" *ngIf="!date.weekend">
        <li *ngFor="let i of [0,1,2,3,4,5,6,7,8]"></li>
        <ng-container *ngFor="let exam of date.exams">
          <div class="exam" [routerLink]="['/subject', exam.subject.encodedName, 'summary']" [style.top]="exam.position.top + '%'" [style.bottom]="exam.position.bottom + '%'"
            [style.width]="'calc(' + 100 / exam.position.overlapDepth.total + '% - ' + 20 / exam.position.overlapDepth.total + 'px)'"
            [style.left]="exam.position.overlapDepth.this ? 'calc(' + (exam.position.overlapDepth.this * 100 / exam.position.overlapDepth.total) + '% - ' + (-10 + 20 / (exam.position.overlapDepth.total) * exam.position.overlapDepth.this) + 'px)' : '10px'"
            [style.background-color]="exam.subject.color">
            <span *ngIf="exam.subject.courseLevel !== courseLevel">{{exam.subject.courseLevel}}</span>
            <div class="code">{{exam.subject.code}}</div>
            <div *ngIf="show === 'my'" class="starttime">{{exam.startDate | date:'HH:mm'}}</div>
            <div *ngIf="show === 'my'" class="endtime">{{exam.endDate | date:'HH:mm'}}</div>
          </div>
        </ng-container>
      </ul>
    </div>
  </div>
</div>