<div class="badge" [class.detailed]="detailed">

    <exo-button-deprecated [style]="'icon-plain'" [icon]="'/assets/nav-icons/close.svg'" class="close-btn" (click)="closeModal()" *ngIf="detailed"></exo-button-deprecated>

    <div class="badge-body">
        <img [src]="userBadge?.badge?.icon" />
        <span class="title">{{userBadge?.badge?.name}}</span>
        <span class="description" *ngIf="detailed">{{userBadge?.badge?.description}}</span>
    </div>

    <div class="badge-footer" *ngIf="userBadge?.progress?.length > 0 && !detailed">
        <span class="amount">{{amount(userBadge)}}/{{targetAmount(userBadge)}}</span>
        <div class="progress-bar">
            <span class="progress-struggle" [style.width]="calcProgress(userBadge)"></span>
        </div>
    </div>

</div>