<div class="modala">
  <div class="modala-header">
    <h3 class="title">Weet je het zeker?</h3>
    <exo-button-deprecated [style]="'icon-plain'" [icon]="'/assets/nav-icons/close.svg'" class="close-modal" (click)="close()"></exo-button-deprecated>
  </div>

  <div class="pv-20">
    Je kunt altijd verder met de test waar je gebleven was.
  </div>

  <div class="modala-footer">
    <exo-button-deprecated [label]="'Annuleren'" [style]="'rounded'" [size]="'stretch'" [extra]="'plain'" class="sr-8 full" (click)="close()"></exo-button-deprecated>
    <exo-button-deprecated [label]="'Afsluiten'" [style]="'rounded'" [size]="'stretch'" class="sl-8 full" (click)="exit()"></exo-button-deprecated>
  </div>

</div>
