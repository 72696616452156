<div class="row">
  <div class="col-md-12">
    <div class="card spaceless">
      <header>
        Tips
      </header>
      <div class="content tip" *ngFor="let tip of tips">
        <div class="title">
          {{tip.title}}
        </div>
        <p>{{tip.description}}</p>
      </div>
      <div class="content" *ngIf="notFound">
        Geen tips beschikbaar
      </div>
    </div>
  </div>
</div>
