<div class="card" *ngIf="cart">
  <div class="content">
    <span
      class="TextDiscountTotal"
      [style.margin]="'0 0 8px 0'"
      [style.display]="'block'"
      >Kortingscode</span
    >

    <div class="discount-input">
      <form>
        <div class="input-regular">
          <input
            class="form-control"
            type="text"
            name="firstName"
            [(ngModel)]="discountCode"
          />
        </div>

        <span class="discount-btn"
          ><exo-button
            classes="btn-icon-next"
            (click)="submit()"
            icon="/assets/icons/chevron.svg"
          ></exo-button
        ></span>
      </form>
    </div>

    <div
      class="discount-message"
      [style.margin]="'0 0 12px 0'"
      *ngIf="discountError || cart.discount"
    >
      <div class="flex-space" *ngIf="cart.discount">
        <span class="TextDiscountDescription success"
          >{{cart.discount.code}}</span
        >
        <span
          class="TextDiscountDescription success"
          [innerHTML]="cart.discount.amount | price"
        ></span>
      </div>
      <span class="TextDiscountDescription error" *ngIf="discountError"
        >{{discountError}}</span
      >
    </div>

    <div class="flex-space" [style.margin]="'0 0 12px 0'">
      <span class="TextDiscountDescription"
        >Verzendkosten (verzending met PostNL)</span
      >
      <span
        *ngIf="cart.price.subTotal < freeShippingRequirementPrice"
        class="TextDiscountDescription"
        [innerHTML]="shippingCost | price"
      ></span>
      <span
        *ngIf="cart.price.subTotal >= freeShippingRequirementPrice"
        class="TextDiscountDescription"
        >Gratis</span
      >
    </div>

    <div class="flex-space" [style.margin]="'0 0 8px 0'">
      <span class="TextDiscountTotal">Totaal</span>
      <span
        class="TextDiscountTotal"
        [innerHTML]="cart.price.grandTotal | price"
      ></span>
    </div>

    <span class="TextDiscountDescription">Gratis verzending vanaf </span>
    <span
      class="TextDiscountDescription"
      [innerHTML]="freeShippingRequirementPrice | price"
    ></span>
  </div>
</div>
