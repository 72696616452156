import {
  animate,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { AuthService } from "../../shared/auth/auth.service";
import { Subject } from "../../shared/subjects/subject";
import { SubjectService } from "../../shared/subjects/subject.service";
import { User } from "../../shared/user/user";
import { UserService } from "../../shared/user/user.service";

import { DatalayerService } from "./../../services/datalayer/datalayer.service";

@Component({
  selector: "exo-subjects",
  templateUrl: "subjects.html",
  styleUrls: ["subjects.scss"],
  animations: [
    trigger("stagger", [
      transition("* => *", [
        query(
          ":leave",
          [
            stagger(100, [
              animate(
                "1s ease-in-out",
                style({
                  transform: "translateY(10%)",
                })
              ),
            ]),
          ],
          { optional: true }
        ),
        query(
          ":enter",
          [
            style({
              transform: "translateY(-10%)",
            }),
            stagger(100, [
              animate(
                "0.5s ease-in-out",
                style({
                  transform: "translateY(0)",
                })
              ),
            ]),
          ],
          { optional: true }
        ),
      ]),
    ]),
    trigger("popup", [
      state("*", style({ opacity: 1, transform: "translateY(0)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(10px)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(0)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 0, transform: "translateY(-10px)" })
        ),
      ]),
    ]),
    trigger("overlay", [
      state("*", style({ opacity: 1 })),
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-out", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SubjectsComponent implements OnInit, OnChanges {
  @Input() subjects: Subject[];
  @Input() extraSubjects: Subject[];
  @Input() extra;

  allSubjects: Subject[];
  courseLevel: string;
  popupIsVisible = false;
  user: User;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    this.popupIsVisible = false;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private subjectService: SubjectService,
    private datalayerService: DatalayerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subjects || changes.extraSubjects) {
      this.allSubjects = [...(this.subjects ? this.subjects : []), ...(this.extraSubjects ? this.extraSubjects : [])];
    }
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user) => {
      this.user = user;
      this.courseLevel = user.courseLevel;
    });
  }

  data(event) {
    if (event.dismiss === true) {
      this.popupIsVisible = false;
    } else if (event.subjects) {
      this.userService.editSubjects(event.subjects).subscribe(
        (data) => {
          this.subjectService.reloadSubjects().then(() => {
            this.popupIsVisible = false;
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  sendDatalayer(label: string, category = "main-menu", action = "navigate") {
    this.datalayerService.send({
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
}
