<div class="card" *ngIf="subjectProgress?.length > 0">
  <header>Verder gaan met leren</header>
  <div class="content">
    <ul>
      <li *ngFor="let progress of subjectProgress">
        <span
          class="active-line"
          [ngStyle]="{'background-color': progress.subject?.color}"
        ></span>
        <div>
          <span class="text-subject">{{progress.subject?.name}}</span>
          <span class="text-section"
            >{{ progress.progresses[progress.progresses.length -
            1]?.firstUnread?.sectionTitle }}</span
          >
        </div>
        <div class="hover-btn">
          <exo-button
            text="Open hoofdstuk"
            classes="btn-regular"
            [bgColor]="progress.subject?.color"
            (click)="continueSubject(progress)"
          ></exo-button>
        </div>
      </li>
    </ul>
  </div>
</div>
