import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { modalAnimation, backdropAnimation } from '../modal/modal.animations';

import { Badge } from '../../shared/badges/badge';
import { BadgeCtrl } from '../../shared/badges/badge-notification.controller';

@Component({
  selector: 'exo-badges-notification',
  templateUrl: 'badge-notification.component.html',
  styleUrls: ['./badge-notification.component.scss'],
  animations: [modalAnimation, backdropAnimation],
})
export class BadgeNotificationComponent implements OnInit {
  timeAliveMs = 300000;
  tablet = false;
  animateConfetti = false;
  elShow = false;
  badge = null;
  animateBadge = false;

  options: AnimationOptions = {
    path: '/assets/lottie/confetti.json',
    loop: 0,
  };

  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto',
  };

  constructor(
    private badgeCtrl: BadgeCtrl,
    private router: Router,
  ) { }

  ngOnInit() {
    this.badgeCtrl.badgeChanged$.subscribe((badge: Badge) => {
      this.showBadge({
        icon: badge.icon,
        name: badge.name,
        description: badge.description,
      });
    });
  }

  showBadge(badge: any) {
    this.badge = badge;
    this.elShow = true;
    this.animateConfetti = true;

    setTimeout(() => {
      this.animateBadge = true;
    }, 500);
  
    setTimeout(() => {
      this.badge = null;
    }, this.timeAliveMs);
  }

  closeBadge() {
    this.badge = null;
    this.animateConfetti = false;
    this.animateBadge = false;
  }

  destroyBadge(event) {
    if (event.fromState !== 'void' && event.fromState) {
     this.elShow = false;
    }
  }

  gotoBadges() {
    this.router.navigate([`profile/badges`]);
    this.closeBadge();
  }

}
