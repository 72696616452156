import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import * as _ from "lodash";
import { first } from "rxjs/operators";

import { ModalController } from "../../../components/modal/modal.controller";
import { BuyableModalComponent } from "../../../components/modals/buyable-modal/buyable-modal.component";
import { TrainingChoiceModalComponent } from '../../../components/modals/training-choice/training-choice';
import { Subject } from "../../../shared/subjects/subject";
import { SubjectService } from "../../../shared/subjects/subject.service";
import { Summary, SummarySection } from "../../../shared/summaries/summary";
import { SummaryService } from "../../../shared/summaries/summary.service";
import { SummaryProgressService } from "../../../shared/summary-progress/summary-progress.service";
import { SummaryProgress } from '../../../shared/summary-progress/summaryProgress';
import { BuyableType } from "../../../shared/training/buyable";
import { Training } from "../../../shared/training/training";
import { TrainingService } from "../../../shared/training/training.service";
import { ratingColorLabel } from '../../../shared/util/ratingColorLabel';

@Component({
  selector: "exo-home",
  templateUrl: "home.html",
  styleUrls: ["home.scss"],
  animations: [
    trigger("scroller", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".2s ease", style({ opacity: 1 })),
      ]),
      transition(":leave", [animate(".2s ease", style({ opacity: 0 }))]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  public subject: Subject;
  public subjectEncodedName: string;
  public selectedSection: string;
  public firstUnreadSection: SummarySection;
  public firstUntrainedPassedSection: SummarySection;

  private sections: SummarySection[];
  public summarySections: any;
  public isSummaryPreview = true;
  public isTrainingPreview = true;
  public practiceModuleActive = false;
  public summaryProgress;
  private trainingProgress: Training;
  public results = [];
  public averageScore: number;
  public progressPercentage: string;

  public progressPercentageSummary: number;
  public progressPercentageTraining: number;

  public loaded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subjectService: SubjectService,
    private summaryService: SummaryService,
    private trainingService: TrainingService,
    private summaryProgressService: SummaryProgressService,
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.route.parent.paramMap.subscribe((params: ParamMap) => {
      this.loaded = false;
      this.subjectEncodedName = params.get("slug");
      this.subjectService
        .getSubjectBySlug(this.subjectEncodedName)
        .pipe(first())
        .subscribe((subject) => {
            this.subject = subject;
            this.getSummarySections(this.subjectEncodedName);
        });
    });
  }

  getSummarySections(subjectEncodedName: string) {
    this.summaryService
      .getSummarySections(subjectEncodedName)
      .pipe(first())
      .subscribe(async (summarySections: Summary) => {
        this.summarySections = summarySections;
        this.isSummaryPreview = summarySections.previewSummary;
        this.isTrainingPreview = summarySections.previewExercise;
        this.practiceModuleActive = summarySections.practiceModuleActive;
        this.sections = _.uniq(
          _.flatMap(this.summarySections.chapters, "sections")
        );
        await this.getSummaryProgress();
        this.getTrainingProgress();
        this.setFirstUnreadSection();
        this.getResults();
        this.loaded = true;
      });
  }

  getResults() {
    this.trainingService
      .getLastResults(this.subjectEncodedName)
      .subscribe((results: any) => {
        this.results = results.filter(
          (section: SummarySection) => section.encodedSectionName
        );
        this.calcSubjectProgress();
      });
  }

  getTrainingProgress() {
    this.trainingService
      .getTrainingProgress(this.subjectEncodedName)
      .subscribe((trainingProgress: Training) => {
        this.trainingProgress = trainingProgress;
        this.setFirstUntrainedSection();
      });
  }

  openTraining(section: SummarySection, encodedChapterName: string, startNewTraining: boolean) {
    if (section && !section.preview && this.isTrainingPreview) {
      this.openBuyableModal();
      return;
    }

    if (
      !startNewTraining &&
      this.trainingProgress &&
      this.trainingProgress.lastThesisId &&
      this.trainingProgress.encodedSectionName === section.encodedSectionName
    ) {
      this.setTraining({
        ...this.trainingProgress,
        encodedChapterName
      });
      return;
    }

    this.modalCtrl.setModal(TrainingChoiceModalComponent,
      {
        section,
        encodedChapterName,
        subject: this.subject,
      },
    );
  }

  setTraining(training: Training) {
    this.trainingService.setCurrentTraining(training);
    this.router.navigate(
      [
        "training",
        this.subjectEncodedName,
        training.lastThesisId || training.thesisIds[0],
      ],
      { skipLocationChange: true }
    );
  }

  async getSummaryProgress(): Promise<void> {
    return new Promise(async (resolve) => {
      this.summaryProgress = await this.summaryProgressService.loadProgress();
      this.summaryProgress = this.summaryProgress.filter(
        (section: SummaryProgress) => section.encodedSubjectName === this.subjectEncodedName
      );
      resolve();
    });
  }

  openSummary(section: SummarySection) {
    if (section && !section.preview && this.isSummaryPreview) {
      this.openBuyableModal();
      return;
    }
    this.router.navigate(["/subject", this.subjectEncodedName, "summary"], {
      queryParams: { section: section.encodedSectionName },
    });
  }

  calcSubjectProgress() {
    this.progressPercentage = null;
    if (!this.cdr["destroyed"]) {
      this.cdr.detectChanges();
    }

    const sectionsRead = this.sections.filter((section: SummarySection) =>
      this.summaryProgress.find(
        (_section: any) =>
          _section.encodedSectionName === section.encodedSectionName
      )
    );

    this.progressPercentageSummary = sectionsRead.length / this.sections.length;
    this.progressPercentageTraining =
      (this.results.length || 0) /
      (this.sections.filter((section) => section.hasPracticeModule).length);

    this.progressPercentageSummary = isNaN(this.progressPercentageSummary)
      ? 0
      : this.progressPercentageSummary;
    this.progressPercentageTraining = isNaN(this.progressPercentageTraining)
      ? 0
      : this.progressPercentageTraining;

    if (!isFinite(this.progressPercentageTraining)) {
      this.progressPercentageTraining = 0;
    }

    this.progressPercentage = (
      ((this.progressPercentageSummary + this.progressPercentageTraining) / 2) *
      10
    )
      .toFixed(1)
      .toString();
  }

  setFirstUnreadSection() {
    if (this.progressPercentageSummary === 1) {
      // Read all sections
      this.firstUnreadSection = this.sections.find(
        (section: SummarySection) =>
          !section.averageRating || section.averageRating < 5.5
      );
    }

    this.firstUnreadSection = this.sections.find((section: any) => {
      return !this.summaryProgress.find(
        (_section: any) =>
          _section.encodedSectionName === section.encodedSectionName
      );
    });

    if (!this.firstUnreadSection && this.summarySections.chapters.length) {
      this.firstUnreadSection = this.summarySections.chapters[0].sections[0];
    }
  }

  get firstPracticeSectionAvailable() {
    return this.sections?.find((section) => section?.hasPracticeModule);
  }

  setFirstUntrainedSection() {
    if (this.progressPercentageTraining === 1) {
      // Trained all sections
      this.firstUnreadSection = this.sections.find(
        (section: SummarySection) =>
          !section.averageRating || section.averageRating < 5.5 && section?.hasPracticeModule
      );
    }

    if (this.trainingProgress && this.trainingProgress.lastThesisId) {
      this.firstUntrainedPassedSection = this.sections.find(
        (section: any) =>
          section.encodedSectionName ===
          this.trainingProgress.encodedSectionName && section?.hasPracticeModule
      );
      return;
    }

    const nextTrainedSectionIndex = this.sections.findIndex(
      (section: SummarySection) =>
        (!section.sectionRating || section.sectionRating < 5.5) && section?.hasPracticeModule
    );
    this.firstUntrainedPassedSection = this.sections[nextTrainedSectionIndex];
  }

  showSectionOptions(section: SummarySection) {
    if (this.isSummaryPreview && this.isTrainingPreview && !section.preview) {
      this.openBuyableModal();
      return;
    }
    this.selectedSection = section.encodedSectionName;
  }

  openBuyableModal() {
    let buyableType: BuyableType;

    if (this.isSummaryPreview) {
      buyableType = BuyableType.summary;
    }
    if (this.isTrainingPreview) {
      buyableType = BuyableType.training;
    }
    if (this.isSummaryPreview && this.isTrainingPreview) {
      buyableType = BuyableType.all;
    }

    this.modalCtrl.setModal(BuyableModalComponent, {
      subject: this.subject,
      buyableType: buyableType,
    });
  }

  getTopicRatingLvl(rating: number) {
    return ratingColorLabel(rating);
  }
}
