<div class="widget">
  <header>
    <p>Kies een gebruikersnaam</p>
    <span style="left: initial; right: 30px" (click)="out.emit({dismiss: true})" class="fa fa-times"></span>
  </header>

  <div class="content">
    <exo-loader *ngIf="!loaded"></exo-loader>
    <p>
      Kies een gebruikersnaam voor je profiel.
      <strong>PAS OP! Andere kunnen deze zien.</strong>
    </p>
		<form (ngSubmit)="onSubmit(username)" *ngIf="loaded">
      <label for="username">Gebruikersnaam</label>
      <input class="form-control" type="text" placeholder="SherlockHolmie22" id="username" autofocus [(ngModel)]="user.nickname.value" name="username" #inputEl (ngModelChange)="error = ''" />
    </form>

		<div *ngIf="error" class="error">{{error}}</div>
    
  </div>

  <div class="footer">
    <button class="btn btn-cancel" (click)="out.emit({dismiss: true})">Annuleren</button>
    <button class="btn btn-primary" (click)="onSubmit(user.nickname.value)">Opslaan</button>
  </div>
</div>
