import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';

import { modalAnimation } from './modal.animations';
import { ModalComponent } from './../modal/modal.component';
import { ModalController } from './../modal/modal.controller';

@Component({
  selector: 'exo-secondary-modal',
  styleUrls: ['./../modal/modal.component.scss'],
  templateUrl: './../modal/modal.component.html',
  animations: [modalAnimation],
})
export class SecondaryModalComponent extends ModalComponent implements OnInit {
  isSecondary = true;

  constructor(
    private resolverClone: ComponentFactoryResolver,
    private controllerClone: ModalController,
  ) {
    super(
      resolverClone,
      controllerClone,
    );
  }

  ngOnInit() {
    this.controllerClone.secondaryModalChanged$.subscribe((component: any) => {
      this.setModal(component.component, component.inputs, component.outputs);
    });
  }

}
