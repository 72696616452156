<div class="modal-card">
  <div class="modal-card-header">
    <h3 class="title">{{displayTitleText()}}</h3>
    <span class="subtitle">{{displaySubTitleText()}}</span>
    <exo-button
      text="Sluiten"
      classes="btn-icon-close-modal"
      (click)="close()"
      class="modal-card-close"
    ></exo-button>
  </div>

  <div class="modal-card-body">
    <div class="free-sections">
      <ul class="subjects-list">
        <li
          [class.active]="selectedSubject === subject"
          *ngFor="let subject of subjectsSections"
        >
          <div (click)="collapseSubject(subject)">
            <img src="{{subject.subjectImage}}" />
            <span>{{subject.subjectName}}</span>
          </div>
          <ul class="sections-list">
            <li
              *ngFor="let section of subject.sections"
              (click)="selectSection(section)"
            >
              <span class="section-name">{{section.sectionTitle}}</span>
              <span class="locked" *ngIf="section.claimed || section.preview "
                >Ontgrendeld</span
              >

              <label
                class="checker"
                *ngIf="!section.claimed && !section.preview"
              >
                <input
                  type="radio"
                  [checked]="selectedSection?.encodedSectionName === section?.encodedSectionName"
                />
              </label>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <div class="modal-card-footer">
    <exo-button
      text="{{displayButtonText()}}"
      [disabled]="disableSelection().toString()"
      classes="btn-regular"
      (click)="claimSelectedSection()"
    ></exo-button>
  </div>
</div>
