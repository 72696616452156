import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'exo-btn',
  templateUrl: 'btn.html',
  styleUrls: ['btn.scss'],
})
export class BtnComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() styleClass: string;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Output() clickEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  public onSubmit() {
    this.clickEvent.emit();
  }

}
