import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Token } from '../auth/token';
import { CourseLevelModel } from '../subjects/course-level.model';
import { Device } from '../user/device';
import { User } from '../user/user';
import { HttpAuthInterceptor } from '../util/httpAuth.interceptor';

@Injectable()
export class RegisterService {

  constructor(private http: HttpClient, private auth: HttpAuthInterceptor) { }

  public newUser = false;

  register(user: User, device: Device): Observable<any> {
    return this.http.post('/user/register', { ...user, device });
  }

  checkEmail(email: string): Observable<any> {
    return this.http.get(`/user/${email}/exists`);
  }

  resend(email: string): Observable<any> {
    return this.http.post('/user/password/forgot', { email });
  }

  getCourseLevels(): Observable<CourseLevelModel[]> {
    return this.http.get<CourseLevelModel[]>('/courseLevels');
  }

  resendCode(email: string): Observable<any> {
    return this.http.post('/user/register/mail/resend', { email });
  }

  validateCode(code: string, email: string, editEmail: boolean): Observable<Token> {
    if (editEmail) {
      return this.http.put<Token>('/user/edit/profile/verify', { code, email });
    }
    return this.http.put<undefined>('/user/email/verify', { code, email }).pipe(tap((res) => {
      this.auth.setToken(res);
    }));
  }
}
