import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import 'polyfills';

import { AppModule } from './app/app.module';
import { environment } from './shared/environments/environment';

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', environment.API_URL],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  ignoreErrors: [],
  release: environment.API_URL,
  denyUrls: [],
  autoSessionTracking: false,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
