import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { AuthService } from '../../shared/auth/auth.service';

@Component({
  templateUrl: 'resetpassword.page.html',
  styleUrls: ['resetpassword.page.scss'],
})
export class ResetPasswordPageComponent implements OnInit, OnDestroy {
  key: string;
  sub: any;

  password = '';

  passwordForm: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/(?=.*?[0-9])(?=.*?[A-Za-z]).+/),
        this.forbiddenPasswordValidator(['examenoverzicht', 'wachtwoord', 'password']),
      ]),
      repeatPassword: new FormControl(),
    });
  }

  forbiddenPasswordValidator(forbiddenWords: string[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      return forbiddenWords.some(word => control.value.toLowerCase().includes(word)) ? { forbiddenWord: { value: control.value } } : null;
    };
  }

  ngOnInit() {
    this.sub = this.route.paramMap.subscribe((params: ParamMap) => {
      this.key = params.get('key');
      if (!this.key || this.key === '') {
        this.router.navigate(['login']);
      }
    },
    (err) => {
      console.log(err);
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  send() {
    this.authService.passwordReset(this.key, this.passwordForm.value.password).subscribe((data) => {
      this.router.navigate(['login']);
    },
    (err) => {
      console.log(err);
    });
  }
}
