import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, publishReplay, refCount } from 'rxjs/operators';

import { Device } from './device';
import { School, UserEditProfile } from './user';

@Injectable()
export class UserService {
  private _supportedFeatures$: Observable<string[]>;

  constructor(private http: HttpClient) { }

  editCourseLevel(courseLevel: string): Observable<any> {
    return this.http.put('/user/edit/courselevel', { courseLevel });
  }

  editSubjects(subjects: { _id: string }[]): Observable<any> {
    return this.http.put('/user/edit/subjects', { subjects });
  }

  editPassword(password: string, curpassword: string): Observable<any> {
    return this.http.put('/user/edit/profile', { password, curpassword });
  }

  editEmail(email: string, curpassword: string): Observable<any> {
    return this.http.put('/user/edit/email', { email, curpassword });
  }

  editProfile(name?: string, nickname?: string, email?: string, curpassword?: string): Observable<any> {
    return this.http.put('/user/edit/profile', { name, nickname, email, curpassword });
  }

  editUsername(nickname: string): Observable<any> {
    return this.http.put('/user/edit/profile', { nickname });
  }

  editProfilePicture(picture: string): Observable<any> {
    return this.http.put('/user/edit/profilepicture', { picture });
  }

  editProfileAsAdmin(name?: string, newEmail?: string, oldEmail?: string) {
    return this.http.put('/user/admin/edit/profile', { name, newEmail, oldEmail });
  }

  getAllUsers(page: number, amountPerPage: number): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('amountPerPage', amountPerPage.toString());

    const options = {
      params,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.get('/users', options);
  }

  searchForUsers(page: number, amountPerPage: number, keyWord: string,  accessCode: string): Observable<any> {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('amountPerPage', amountPerPage.toString())
      .set('keyWord', keyWord || '')
      .set('accessCode', accessCode || '');

    const options = {
      params,
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    return this.http.get('/users', options);
  }

  getDevices(): Observable<Device[]> {
    return this.http.get<Device[]>('/user/devices');
  }

  deleteDevice(identifier: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { identifier },
    };
    return this.http.request('delete', '/user/device', options);
  }

  deleteDeviceAsAdmin(deviceId: string, email: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { deviceId, email },
    };
    return this.http.request('delete', '/user/admin/device', options);
  }

  deleteAccount(): Observable<any> {
    return this.http.delete('/user');
  }

  verifyEmail(email: string): Observable<any> {
    return this.http.put('/user/admin/email/verify', { email });
  }

  placeReview(stars: number, text: string): Observable<any> {
    return this.http.post('/reviews', { stars, text });
  }

  deleteUserAccount(userId: string) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: [userId],
    };
    return this.http.request('delete', '/users', options);
  }

  get supportedFeatures$() {
    if (!this._supportedFeatures$) {
      this._supportedFeatures$ = this.http.get<string[]>('/auth/features').pipe(
        publishReplay(1),
        refCount(),
        catchError(err => of([])),
      );
    }
    return this._supportedFeatures$;
  }

  resetSupportedFeatures() {
    this._supportedFeatures$ = undefined;
  }

  getSchools(schoolName?: string): Observable<School[]> {
    const query = schoolName ? `?search=${schoolName}` : '';
    return this.http.get<School[]>(`/schools${query ? query : ''}`);
  }

  getSchoolSuggestions(): Observable<School>  {
    return this.http.get<School>('/schools/suggestion');
  }

  patchUser(userEditProfile: UserEditProfile) {
    return this.http.patch('/user/edit/profile', userEditProfile);
  }

  getStudentTypes() {
    return this.http.get<any>('/student-types');
  }

}
