import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input } from '@angular/core';

import { Subject } from '../../shared/subjects/subject';

import { IndexWidgetService } from './index-widget.service';

@Component({
  selector: 'exo-index-widget',
  templateUrl: './index-widget.component.html',
  styleUrls: ['./index-widget.component.scss'],
})
export class IndexWidgetComponent {
  @Input() subject: Subject;
  hovering = false;
  navbarHeight = 88;

  constructor(
    private indexService: IndexWidgetService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  get categories() {
    return this.indexService.index || [];
  }

  getColor(elementId: string) {
    let active = false;
    const scrollTop = this.document.documentElement.scrollTop + this.navbarHeight + 2; // 2px leeway
    const element = document.querySelector(`#${elementId}`);

    const offset = (el) => {
      const rect = el.getBoundingClientRect();
      const scrollTopa = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTopa;
    };

    if (element) {
      const top = offset(element);
      if (scrollTop > top && scrollTop < top + element.clientHeight) {
        active = true;
      }
    }

    return active ? this.subject.color : '';
  }

  scroll(selector: string, alink?: any) {
    let element = document.querySelector(`#${selector}`);

    if (!element) { element = document.querySelector(`.alink${alink}`); }

    const { top } = element.getBoundingClientRect();
    scrollTo({
      top: top + pageYOffset - this.navbarHeight,
    });

  }

  getSelectorName(categoryIndex: number, childIndex?: number) {
    return childIndex ? `h${categoryIndex}c${childIndex}` : `h${categoryIndex}`;
  }

}
