import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Gender, User } from '../../../shared/user/user';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'exo-choose-gender',
  templateUrl: 'choose-gender.html',
  styleUrls: ['choose-gender.scss'],
})

export class ChooseGenderComponent implements OnInit {
  @Input() user: User;
  @Input() mode: string;
  @Output() out = new EventEmitter<any>();

  genders: any[];

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.genders = [
      {
        name: 'Man',
        type: Gender.male,
      },
      {
        name: 'Vrouw',
        type: Gender.female,
      },
      {
        name: 'Anders',
        type: Gender.other,
      },
    ];

  }

  onSubmit(gender: Gender) {
    if (this.mode === 'popup') {
      this.userService.patchUser({ gender }).subscribe(() => {
        this.out.emit({ gender });
      },
      (err) => {
        console.log('Er is iets misgegaan');
      });
      return;
    }
    this.out.emit({ gender });
  }

}
