<div id="dropbox" (click)="uploadClick()" (drop)="drop($event)" (dragenter)="dragenter($event)" (dragleave)="dragleave($event)" (dragover)="dragover($event)" [class]="dropboxClass">
  <div class="loading">

  <div class="image-file" [class.set]="imageSet" [style.background-image]="imageSet ? 'url(' + imageFile.src + ')' : ''"></div>
    <input type="file" class="input-file" (change)="checkImage($event)"/>
  </div>
</div>
<div class="error-messages" *ngIf="imageError">
  <div class="error-msg-name error-msg">
    <span *ngIf="errorType == 'dimension'">Kies een afbeelding met een maximale grootte van 800x600.</span>
    <span *ngIf="errorType == 'type'">Kies een afbeelding met bestandstypes jpeg, jpg of png.</span>
  </div>
</div>
  