import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { environment } from '../../../../shared/environments/environment';
import { OldexamsService } from '../../../../shared/oldexams/oldexams.service';
import { SubjectService } from '../../../../shared/subjects/subject.service';

@Component({
  selector: 'exo-oldexam',
  templateUrl: 'oldexam.html',
  styleUrls: ['oldexam.scss'],
})
export class OldexamComponent implements OnInit {

  period: number;
  year: number;

  exam;
  examkeys: string[];
  curType: string;
  pdfSrc;
  page = 1;

  loading = false;
  typeloading = true;

  constructor(
    private route: ActivatedRoute,
    private oldexamsService: OldexamsService,
    private subjectService: SubjectService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.period = Number(params.get('period'));
      this.year = Number(params.get('year'));

      this.route.parent.parent.paramMap.subscribe((parentParams: ParamMap) => {
        const slug = parentParams.get('slug');

        this.subjectService.getSubjectBySlug(slug).subscribe((subject) => {
          this.getOldExam(subject._id, params);
        });
      });
    });
  }

  getOldExam(id: string, params: any) {
    this.oldexamsService.getExamByYear(id, this.year).subscribe((exam) => {
      this.typeloading = false;
      this.exam = exam;
      const elements = ['assignments', 'attachment', 'answerSheet', 'answers'];
      const examkeys = Object.keys(exam[['firstPeriod', 'secondPeriod'][this.period - 1]]);
      this.examkeys = elements.filter((el: string) => examkeys.find((exameEl: string) => exameEl === el));

      const type = params.get('type');

      if (type) {
        this.show(type);
      }
    },
      (err) => {
        this.typeloading = false;
        console.log(err);
      });
  }

  getTranslation(str: string): string {
    switch (str) {
      case 'assignments':
        return 'Opgaven';
      case 'attachment':
        return 'Bijlage';
      case 'answerSheet':
        return 'Uitwerkbijlage';
      case 'answers':
        return 'Antwoorden';
      default:
        return '';
    }
  }

  show(type: string) {
    this.curType = type;
    this.pdfSrc = `${environment.API_URL}${this.exam[['firstPeriod', 'secondPeriod'][this.period - 1]][type]}`;
    this.loading = true;
  }

  afterLoadComplete(pdf) {
    this.loading = false;
  }

  open(src: string) {
    window.open(src);
  }

}
