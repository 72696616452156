<div class="container">
  <div class="row">
    <div class="col-md-4">
      <div class="widget">
        <div class="content">
          <ul class="list-group">
            <li
              (click)="sendDatalayer('profile')"
              routerLink="/profile"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
              class="list-group-item"
            >
              <span class="icon icon-profile"></span
              ><span class="menu-title">Profiel</span>
            </li>
            <li
              (click)="sendDatalayer('devices')"
              routerLink="devices"
              routerLinkActive="active"
              class="list-group-item"
            >
              <span class="icon icon-devices"></span
              ><span class="menu-title">Gekoppelde apparaten</span>
            </li>
            <li
              (click)="sendDatalayer('products')"
              routerLink="summaries"
              routerLinkActive="active"
              class="list-group-item"
            >
              <span class="icon icon-summary"></span
              ><span class="menu-title">Producten</span>
            </li>
            <li
              (click)="sendDatalayer('faq')"
              routerLink="faq"
              routerLinkActive="active"
              class="list-group-item"
            >
              <span class="icon icon-faq"></span
              ><span class="menu-title">Veelgestelde vragen</span>
            </li>
            <li
              (click)="sendDatalayer('disclaimer')"
              routerLink="disclaimer"
              routerLinkActive="active"
              class="list-group-item"
            >
              <span class="icon icon-disclaimer"></span
              ><span class="menu-title">Disclaimer</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-8" [@routerAnimation]="getRouteAnimation(route)">
      <router-outlet #route="outlet" style="position: relative"></router-outlet>
    </div>
  </div>
</div>
