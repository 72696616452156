import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RegistrationGoal } from '../../../shared/user/user';
import { UserService } from '../../../shared/user/user.service';

@Component({
  selector: 'exo-choose-goals',
  templateUrl: 'choose-goals.html',
  styleUrls: ['choose-goals.scss'],
  animations: [
    trigger('popup', [
      state('*', style({ opacity: 1, transform: 'translateY(-50%)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-48%)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(-50%)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(-50%)' }),
        animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-52%)' })),
      ]),
    ]),
    trigger('overlay', [
      state('*', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ChooseGoalsComponent implements OnInit {
  @Output() out = new EventEmitter<any>();


  showpopup = false;
  loading = true;

  registrationGoal = new RegistrationGoal();

  studentTypes;

  sliderLabels = [
    'Nog niet voorbereid',
    'Ik ben er klaar voor',
  ];

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.getStudentTypes();
    this.loading = false;
  }

  submit() {
    if (typeof this.registrationGoal.preparation !== 'undefined' && this.registrationGoal.studentTypes.length > 0) {
      this.out.emit({
        registrationGoal: this.registrationGoal,
      });
    }
  }

  setStudentType(studentType) {
    if (this.registrationGoal.studentTypes.find(_studentType => studentType._id === _studentType)) {
      this.registrationGoal.studentTypes = this.registrationGoal.studentTypes.filter(item => item !== studentType._id);
    }
    else {
      this.registrationGoal.studentTypes.push(studentType._id);
    }
  }

  preparationChange(preparation: number) {
    this.registrationGoal.preparation = preparation;
  }

  getStudentTypes() {
    this.userService.getStudentTypes().subscribe((studentTypes) => {
      this.studentTypes = studentTypes;
    });
  }

  containsStudentType(studentType) {
    return this.registrationGoal.studentTypes.find(_studentType => studentType._id === _studentType);
  }
}
