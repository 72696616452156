import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ModalController {

  public modalMode = false;
  public modalChanged = new Subject<any>();
  modalChanged$ = this.modalChanged.asObservable();

  public secondaryModalMode = false;
  public secondaryModalChanged = new Subject<any>();
  secondaryModalChanged$ = this.secondaryModalChanged.asObservable();

  constructor(
  ) { }

  setModal(component: any, inputs: any = {}, outputs: any = {}) {
    this.modalChanged.next({ component, inputs, outputs });
    this.modalMode = true;
  }

  setSecondaryModal(component: any, inputs: any = {}, outputs: any = {}) {
    this.secondaryModalChanged.next({ component, inputs, outputs });
    this.secondaryModalMode = true;
  }

  closeModal(force?: boolean) {
    if (force) {
      this.setSecondaryModal(null, {}, {});
      this.secondaryModalMode = false;

      this.setModal(null, {}, {});
      this.modalMode = false;
    } else if (this.secondaryModalMode) {
      this.setSecondaryModal(null, {}, {});
      this.secondaryModalMode = false;
    } else {
      this.setModal(null, {}, {});
      this.modalMode = false;
    }
  }

}
