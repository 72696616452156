<div class="row">
  <div class="col-md-12">
    <div class="card spaceless">
      <header>
        <div>
          <span
            [routerLink]="pdfSrc ? '../' : '../../'"
            class="icon icon-arrow-left"
          ></span>
          Examen {{getTranslation(curType)?.toLowerCase()}} {{period}}e tijdvak
          <span class="year">&thinsp;{{year}}</span>
        </div>
        <a
          class="btn header-content-right"
          *ngIf="pdfSrc"
          (click)="open(pdfSrc)"
          >Download</a
        >
      </header>

      <div class="row-year" *ngIf="!pdfSrc && !!examkeys">
        <div class="col-3" *ngFor="let examtype of examkeys">
          <div [routerLink]="examtype">
            <span class="type-name">{{getTranslation(examtype)}}</span>
          </div>
        </div>
      </div>
      <div class="oldexam-content empty" *ngIf="!examkeys && !typeloading">
        Geen bestanden gevonden
      </div>
      <div class="oldexam-content" *ngIf="typeloading">
        <exo-loader></exo-loader>
      </div>

      <div *ngIf="pdfSrc" class="oldexam-content pdf" [class.loading]="loading">
        <div class="loader-container" *ngIf="loading">
          <exo-loader></exo-loader>
        </div>
        <pdf-viewer
          *ngIf="pdfSrc"
          (after-load-complete)="afterLoadComplete($event)"
          [src]="pdfSrc"
          [render-text]="false"
          [page]="page"
          [original-size]="false"
          [show-all]="true"
          [zoom]="1"
          style="display: block"
        ></pdf-viewer>
      </div>
    </div>
  </div>
</div>
