<div class="modal-card">
  <exo-button
    text="Sluiten"
    classes="btn-icon-close-modal"
    (click)="close()"
    class="modal-card-close"
  ></exo-button>

  <div class="modal-card-body">
    <span class="title">We hebben een cadeautje voor je!</span>
    <span class="subtitle"
      >Omdat je nieuw bent krijg jij van ons een gedeelte van de samenvatting
      cadeau!</span
    >
    <img src="/assets/present.svg" />

    <exo-button
      text="Pak je cadeau uit"
      classes="btn-regular btn-accent "
      (click)="claimPresent()"
      class="modal-button"
    ></exo-button>
  </div>
</div>
