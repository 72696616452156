import { Component, ComponentFactoryResolver, OnInit, ReflectiveInjector, ViewChild, ViewContainerRef } from '@angular/core';

import { modalAnimation, backdropAnimation } from './modal.animations';
import { ModalController } from './modal.controller';

@Component({
  selector: 'exo-modal',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
  animations: [modalAnimation, backdropAnimation],
})
export class ModalComponent implements OnInit {
  isSecondary = false;
  component = null;
  show = false;
  notificationIsShown = true;

  @ViewChild('modalComponent', { static: true }) modalComponent;
  @ViewChild('dynamicComponentContainer', {
    read: ViewContainerRef,
    static: true,
  }) dynamicComponentContainer: ViewContainerRef;

  constructor(
    private resolver: ComponentFactoryResolver,
    private controller: ModalController,
  ) { }

  ngOnInit() {
    this.controller.modalChanged$.subscribe((component: any) => {
      this.setModal(component.component, component.inputs, component.outputs);
    });
  }

  isActive() {
    return this.show;
  }

  setModal(component: any, inputs: any, outputs: any) {
    if (!component) {
      this.closeModal();
      return;
    }
    this.show = true;
    this.notificationIsShown = true;

    const inputProviders = Object.keys(inputs).map((inputName) => {
      return {
        provide: inputName,
        useValue: inputs[inputName],
      };
    });

    const outputProviders = Object.keys(outputs).map((outputName) => {
      return {
        provide: outputName,
        useValue: outputs[outputName],
      };
    });

    // tslint:disable-next-line: deprecation
    const resolvedInputs = ReflectiveInjector.resolve(inputProviders);
    // tslint:disable-next-line: deprecation
    const injector = ReflectiveInjector.fromResolvedProviders(resolvedInputs, this.dynamicComponentContainer.parentInjector);
    const factory = this.resolver.resolveComponentFactory(component);

    if (this.component) {
      this.component.destroy();
    }

    this.component = factory.create(injector);

    this.dynamicComponentContainer.insert(this.component.hostView);
    inputProviders.forEach((prov) => {
      this.component.instance[prov.provide] = prov.useValue;
    });

    outputProviders.forEach((prov) => {
      this.component.instance[prov.provide].subscribe(prov.useValue);
    });

  }

  closeModal() {
    this.show = false;
  }

  deleteComponent(event) {
    // this will be triggered when @modal.animation is done
    if (event.fromState) {
      this.component = null;
      this.notificationIsShown = false;
      setTimeout(() => {
        this.dynamicComponentContainer.detach();
      }, 0);
    }
  }

}
