import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Nickname, User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'exo-choose-username',
  templateUrl: 'choose-username.html',
  styleUrls: ['choose-username.scss'],
})
export class ChooseUsernameComponent implements OnInit, AfterViewInit {
  @Input() user: User;
  @Output() out = new EventEmitter<any>();
  @ViewChild('inputEl') inputEl: ElementRef;

  public error = '';

  public loaded = false;
  public username: string;
  private usernameOld = '';

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.loaded = true;
    this.usernameOld = this.user.nickname ? this.user.nickname.value : '';
    if (!this.user.nickname) {
      this.user.nickname = new Nickname();
    }
  }

  ngAfterViewInit(): void {
    this.inputEl.nativeElement.focus();
  }

  onSubmit(username: string) {
    if (username) {
      this.userService.editUsername(username).subscribe((user: User) => {
        this.out.emit({ nickname: username });
        this.out.emit({ dismiss: true });
      },
      ((error: any) => {
        this.error = 'Uw gebruikersnaam is niet toegestaan';
      }));
    }
    else if (this.usernameOld === username) {
      this.out.emit({ dismiss: true });
    }
  }

}
