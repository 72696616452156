import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

import { environment } from '../../../shared/environments/environment';

@Component({
  selector: 'exo-payment-link',
  templateUrl: 'payment.html',
  styleUrls: ['payment.scss'],
})
export class PaymentLinkComponent implements OnInit {
  showMessage = false;
  url = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit() {
    this.url = `${environment.WEB_URL}/payment`;

    setTimeout(() => {
      this.showRedirect();
    }, 5000);
  }

  showRedirect() {
    this.showMessage = true;
  }

  isIos() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  openApp() {
    window.open(this.url);
  }
}
