<div class="widget">
  <header>
    Gekoppelde apparaten
  </header>
  <div class="content">
    <exo-loader *ngIf="loading"></exo-loader>

    <ul *ngIf="devices && devices.length > 0">

      <li *ngFor="let device of devices">
        <span class="info">
          <h1>{{device.description}}</h1>
          <br/>
          <span class="date">Laatst ingelogd: {{device.lastLogin | date:'medium' }}</span>
        </span>
        <button class="btn btn-secondary" (click)="removeDevice(device.identifier)" *ngIf="!isOwnDevice(device)">Verwijder</button>
      </li>

    </ul>

    <div *ngIf="!loading && (!devices || devices.length === 0)">
      Nog geen apparaten gekoppeld
    </div>
  </div>
</div>