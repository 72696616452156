import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BadgesNotificationService } from '../shared/badges/badge.notification.service';
import { TrackingService } from '../shared/tracking/tracking.service';
import { HttpAuthInterceptor } from '../shared/util/httpAuth.interceptor';

@Component({
  selector: 'exo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public badgesEnabled = false;
  public connected = false;

  constructor(
    private router: Router,
    private badgesNotificationService: BadgesNotificationService,
    private trackingService: TrackingService,
    private httpAuth: HttpAuthInterceptor,
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.httpAuth.accountState$.subscribe((state) => {
      if (state === 'connected') {
        this.connected = state === 'connected';
        this.trackingService.init();
        this.badgesNotificationService.init();
      }
    });

    if (!this.connected && this.httpAuth.getToken()) {
      this.connected = true;
      this.trackingService.init();
      this.badgesNotificationService.init();
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }

}
