import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ProductService } from '../../shared/products/product.service';
import { Subject } from '../../shared/subjects/subject';
import { SubjectService } from '../../shared/subjects/subject.service';

import { DatalayerService } from './../../services/datalayer/datalayer.service';

@Component({
  selector: 'exo-add-summary',
  templateUrl: 'add-summary.html',
  styleUrls: ['add-summary.scss'],
})
export class AddSummaryComponent implements OnInit {
  @Input('dashboard') dashboard: boolean;
  @Output() summary: EventEmitter<any> = new EventEmitter();

  productCode: string;

  status: string;
  success: boolean;

  routerLink;
  linktext: string;

  subjects: Subject[];
  sub = [];

  constructor(
    private subjectService: SubjectService,
    private productService: ProductService,
    private datalayerService: DatalayerService,
    private location: Location,
  ) {
  }

  ngOnInit() {
    this.subjectService.getSubjects().subscribe((subjects) => {
      this.subjects = subjects;
    },
      (err) => {
        console.log(err);
      });
  }

  onSubmit() {
    this.sendDatalayer();
    if (this.productCode) {
      this.success = false;
      this.routerLink = null;
      this.linktext = null;
      this.productCode = this.productCode.trim();

      this.sub.push(this.productService.pairProducts(this.productCode, null).subscribe((summaryOrExercises) => {
        this.status = null;
        const product = summaryOrExercises;
        this.addProductToMyAccount(product);
      },
        (err) => {
          if (err.status === 404) {
            this.status = 'We hebben helaas geen Product kunnen vinden';
          } else if (err.status === 403) {
            this.status = 'Productcode al gebruikt';
          } else if (err.status === 409) {
            this.status = 'Product is al toegevoegd aan je account';
          }
          console.log(err);
        }));
    }
  }

  addProductToMyAccount(product) {
    if (!Array.isArray(product.subjectId)) { product.subjectId = [product.subjectId]; }
    product.subjectId.forEach((subjectId) => {
      this.subjectService.getSubjectById(subjectId).subscribe((subject: Subject) => {
        const changedProduct = Object.assign({}, product);
        changedProduct.subjectLinked = false;

        if (this.subjects && this.subjects.findIndex(userSubject => userSubject._id === subjectId) > -1) {
          changedProduct.subjectLinked = true;
        }

        changedProduct.subjectId = subjectId;
        changedProduct.name = product.name;
        changedProduct.courseLevel = subject.courseLevel;

        this.status = 'Koppelen gelukt.';
        if (this.dashboard) {
          if (product.type === 'summary') {
            this.routerLink = changedProduct.subjectLinked ? ['/subject', subjectId, 'summary'] : ['/profile', 'summaries'];
            this.linktext = changedProduct.subjectLinked ? 'om je samenvatting te bekijken.' : `om ${subject.name} aan je vakkenpakket toe te voegen.`;
          }
          else {
            this.routerLink = changedProduct.subjectLinked ? ['/subject', subjectId, 'exercises'] : ['/profile'];
            this.linktext = changedProduct.subjectLinked ? 'om je oefenboek te bekijken.' : `om ${subject.name} aan je vakkenpakket toe te voegen.`;
          }

        }
        this.success = true;
        this.summary.emit(changedProduct);
      },
        (err) => {
          console.log(err);
        });
    });
  }

  sendDatalayer() {
    let label = 'dashboard';
    if (this.location.path().includes('profile')) { label = 'profile-products'; }
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: 'button',
      eventAction: 'link-product',
      eventLabel: label,
    });
  }

}
