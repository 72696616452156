export class Datalayer {
  event?: string;
  hitType?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;

  constructor() {
  }
}
