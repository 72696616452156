import { CommonModule, registerLocaleData } from "@angular/common";
import nl from "@angular/common/locales/nl";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import lottieWeb from "lottie-web";
import { InlineSVGModule } from "ng-inline-svg-2";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { LottieModule } from "ngx-lottie";

import { AddSummaryComponent } from "../components/add-summary/add-summary.component";
import { AnswerRatingComponent } from "../components/answer-rating/answer-rating.component";
import { BadgeNotificationComponent } from "../components/badge-notification/badge-notification.component";
import { BadgeComponent } from "../components/badge/badge.component";
import { BadgesFeaturedComponent } from "../components/badges-featured/badges-featured.component";
import { BtnComponent } from "../components/btn/btn.component";
import { ButtonDeprecatedComponent } from "../components/button-deprecated/button-deprecated.component";
import { ButtonComponent } from "../components/button/button.component";
import { CardComponent } from "../components/card/card.component";
import { ChartComponent } from "../components/chart/chart.component";
import { ChoicesComponent } from "../components/choices/choices.component";
import { ChooseSchoolComponent } from "../components/choose-school/choose-school.component";
import { ChooseUsernameComponent } from "../components/choose-username/choose-username.component";
import { CompleteProfileComponent } from "../components/complete-profile/complete-profile.component";
import { DeviceService } from "../components/device/device.service";
import { DropdownComponent } from "../components/dropdown/dropdown.component";
import { ImageUploaderComponent } from "../components/image-uploader/image-uploader.component";
import { IndexWidgetComponent } from "../components/index-widget/index-widget.component";
import { IndexWidgetService } from "../components/index-widget/index-widget.service";
import { LeaderboardComponent } from "../components/leaderboard/leaderboard";
import { LoaderComponent } from "../components/loader/loader.component";
import { ModalComponent } from "../components/modal/modal.component";
import { ModalController } from "../components/modal/modal.controller";
import { SecondaryModalComponent } from "../components/modal/secondary-modal.component";
import { BuyableModalComponent } from "../components/modals/buyable-modal/buyable-modal.component";
import { ReportModalComponent } from "../components/modals/report/report.component";
import { RatingComponent } from "../components/rating/rating.component";
import { RowComponent } from "../components/row/row.component";
import { SliderComponent } from "../components/slider/slider.component";
import { SocialLoginModule } from "../components/sociallogin/sociallogin.module";
import { SubjectsComponent } from "../components/subjects/subjects.component";
import { PaymentLinkComponent } from "../components/url-links/payment/payment.component";
import { BadgesPageComponent } from "../pages/badges/badges.page";
import { CartBuyablesComponent } from "../pages/cart/buyables/buyables.component";
import { CartPageComponent } from "../pages/cart/cart.page";
import { CartDiscountComponent } from "../pages/cart/discount/discount";
import { CartIssuersComponent } from "../pages/cart/issuers/issuers";
import { CartOrderComponent } from "../pages/cart/order/order";
import { CartShippingFormComponent } from "../pages/cart/shipping-form/shipping-form";
import { DashboardContinueComponent } from "../pages/dashboard/dashboard-continue/dashboard-continue";
import { DashboardNavComponent } from "../pages/dashboard/dashboard-nav/dashboard-nav";
import { DashboardPageComponent } from "../pages/dashboard/dashboard.page";
import { NextSubjectComponent } from "../pages/dashboard/next-subject/next-subject.component";
import { TipComponent } from "../pages/dashboard/tip/tip.component";
import { VideoComponent } from "../pages/dashboard/video/video.component";
import { LoginPageComponent } from "../pages/login/login.page";
import { ResetPasswordPageComponent } from "../pages/login/resetpassword.page";
import { UnblockAccountPageComponent } from "../pages/login/unblockaccount.page";
import { LogoutPageComponent } from "../pages/logout/logout.page";
import { PaymentStatusPageComponent } from "../pages/payment/status/payment-status.page";
import { DevicesComponent } from "../pages/profile/devices/devices.component";
import { DisclaimerComponent } from "../pages/profile/disclaimer/disclaimer.component";
import { FaqComponent } from "../pages/profile/faq/faq.component";
import { ProfilePageComponent } from "../pages/profile/profile.page";
import { ProfileComponent } from "../pages/profile/profile/profile.component";
import { SummariesComponent } from "../pages/profile/summaries/summaries.component";
import { AddSummariesComponent } from "../pages/register/add-summaries/add-summaries.component";
import { AlreadyPurchasedComponent } from "../pages/register/already-purchased/already-purchased.component";
import { ChooseGenderComponent } from "../pages/register/choose-gender/choose-gender.component";
import { ChooseGoalsComponent } from "../pages/register/choose-goals/choose-goals.component";
import { ChooseUsertypeComponent } from "../pages/register/choose-usertype/choose-usertype.component";
import { CourseLevelComponent } from "../pages/register/course-level/course-level.component";
import { RegisterPageComponent } from "../pages/register/register.page";
import { SelectCoursesComponent } from "../pages/register/select-courses/select-courses.component";
import { SubjectSelectComponent } from "../pages/register/subject-select/subject-select.component";
import { SubjectDifficultComponent } from "../pages/register/subjects-difficult/subjects-difficult.component";
import { ValidateEmailComponent } from "../pages/register/validate-email/validate-email.component";
import { RootPageComponent } from "../pages/root/root.page";
import { SchedulePageComponent } from "../pages/schedule/schedule.page";
import { DefinitionsComponent } from "../pages/subject/definitions/definitions.component";
import { ExercisesComponent } from "../pages/subject/exercises/exercises.component";
import { SubjectyBuyablesComponent } from "../pages/subject/home/buyables/buyables.component";
import { HomeComponent } from "../pages/subject/home/home";
import { OldexamListComponent } from "../pages/subject/oldexams/oldexam-list/oldexam-list.component";
import { OldexamComponent } from "../pages/subject/oldexams/oldexam/oldexam.component";
import { OldexamsComponent } from "../pages/subject/oldexams/oldexams.component";
import { SubjectPageComponent } from "../pages/subject/subject.page";
import { SummaryBuyablesComponent } from "../pages/subject/summary/buyables/buyables.component";
import { SummaryResultsComponent } from "../pages/subject/summary/results/results.component";
import { SummaryComponent } from "../pages/subject/summary/summary.component";
import { TipsComponent } from "../pages/subject/tips/tips.component";
import { VideosComponent } from "../pages/subject/videos/videos.component";
import { JoinCommaPipe } from "../pipes/join-comma.pipe";
import { MarkSafePipe } from "../pipes/mark-safe.pipe";
import { PricePipe } from "../pipes/price.pipe";
import { SafeHtmlPipe } from "../pipes/safe-html.pipe";
import { ScorePipe } from "../pipes/score.pipe";
import { SearchPipe } from "../pipes/search.pipe";
import { StripHtmlPipe } from "../pipes/strip-html.pipe";
import { BadgeCtrl } from "../shared/badges/badge-notification.controller";
import { SharedModule } from "../shared/shared.module";

import { FeatureComponent } from "./../components/feature/feature.component";
import { CloseTrainingModalComponent } from "./../components/modals/close-training/close-training.component";
import { PictureModalComponent } from "./../components/modals/picture/picture.component";
import { PaymentSuccessPageComponent } from "./../pages/mobile/payment-success/payment-success.page";
import { QuestionTrainingPageComponent } from "./../pages/training/question-page/question.page";
import { ResultsTrainingPageComponent } from "./../pages/training/results-page/results.page";
import { TrainingPageComponent } from "./../pages/training/training.page";
import { AppComponent } from "./app.component";
import { routing } from "./app.routing";
import { AuthGuard } from "./authguard";
import { MathjaxModule } from "../shared/mathjax/directive";

import { SwiperModule } from "swiper/angular";
import { TruncatePipe } from "../pipes/truncate.pipe";
import { RouterModule } from "@angular/router";
import { PaymentLinkPageComponent } from "../pages/payment/link/payment-link.page";
import { SummaryTutorialModalComponent } from "../components/modals/summary-tutorial/summary-tutorial.component";
import { TrainingChoiceModalComponent } from "../components/modals/training-choice/training-choice";
import { TopicModalComponent } from "../components/modals/topic/topic-modal.component";
import { FreeSectionsModalComponent } from "../components/modals/free-sections/free-sections";
import { FreePresentModalComponent } from "../components/modals/free-present/free-present";
import { SectionsListComponent } from "../components/sections-list/sections-list";

registerLocaleData(nl);

export function playerFactory() {
  return lottieWeb;
}

@NgModule({
  declarations: [
    AppComponent,
    RootPageComponent,
    DashboardPageComponent,
    LoginPageComponent,
    ResetPasswordPageComponent,
    UnblockAccountPageComponent,
    RegisterPageComponent,
    NextSubjectComponent,
    ProfilePageComponent,
    SchedulePageComponent,
    SubjectPageComponent,
    AddSummaryComponent,
    SubjectsComponent,
    TipComponent,
    VideoComponent,
    ImageUploaderComponent,
    LoaderComponent,
    ButtonComponent,
    ButtonDeprecatedComponent,
    BtnComponent,
    RatingComponent,
    AnswerRatingComponent,
    DefinitionsComponent,
    OldexamsComponent,
    OldexamListComponent,
    OldexamComponent,
    SummaryComponent,
    HomeComponent,
    ExercisesComponent,
    TipsComponent,
    VideosComponent,
    DevicesComponent,
    DisclaimerComponent,
    FaqComponent,
    ProfileComponent,
    SummariesComponent,
    AddSummariesComponent,
    AlreadyPurchasedComponent,
    CourseLevelComponent,
    SelectCoursesComponent,
    ValidateEmailComponent,
    ChooseUsertypeComponent,
    ChooseGenderComponent,
    ChooseGoalsComponent,
    ChooseUsernameComponent,
    ChooseSchoolComponent,
    SubjectDifficultComponent,
    LogoutPageComponent,
    PaymentLinkPageComponent,
    IndexWidgetComponent,
    StripHtmlPipe,
    SafeHtmlPipe,
    PaymentSuccessPageComponent,
    PaymentStatusPageComponent,
    TrainingPageComponent,
    QuestionTrainingPageComponent,
    ChoicesComponent,
    ModalComponent,
    SecondaryModalComponent,
    ReportModalComponent,
    CloseTrainingModalComponent,
    BuyableModalComponent,
    TopicModalComponent,
    SummaryTutorialModalComponent,
    TrainingChoiceModalComponent,
    ResultsTrainingPageComponent,
    ChartComponent,
    PictureModalComponent,
    FreeSectionsModalComponent,
    FreePresentModalComponent,
    FeatureComponent,
    PaymentLinkComponent,
    BadgesPageComponent,
    BadgeComponent,
    BadgeNotificationComponent,
    BadgesFeaturedComponent,
    SliderComponent,
    CompleteProfileComponent,
    DropdownComponent,
    SearchPipe,
    PricePipe,
    MarkSafePipe,
    JoinCommaPipe,
    ScorePipe,
    TruncatePipe,
    LeaderboardComponent,
    RowComponent,
    CardComponent,
    SubjectyBuyablesComponent,
    SummaryBuyablesComponent,
    SummaryResultsComponent,
    CartPageComponent,
    CartOrderComponent,
    CartBuyablesComponent,
    CartDiscountComponent,
    CartIssuersComponent,
    CartShippingFormComponent,
    DashboardNavComponent,
    DashboardContinueComponent,
    SubjectSelectComponent,
    SectionsListComponent,
  ],
  imports: [
    MathjaxModule.forRoot({
      config: {
        loader: { load: ["input/asciimath", "output/svg"] },
        asciimath: {
          delimiters: [
            ["$", "$"],
            ["`", "`"],
          ],
        },
      },
    }),
    CommonModule,
    RouterModule,
    routing,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    SwiperModule,
    SocialLoginModule,
    InlineSVGModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    AuthGuard,
    DeviceService,
    IndexWidgetService,
    BadgeCtrl,
    ModalController,
    {
      provide: LOCALE_ID,
      useValue: "nl-NL",
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    TopicModalComponent,
    ReportModalComponent,
    CloseTrainingModalComponent,
    SummaryTutorialModalComponent,
    PictureModalComponent,
    BadgeComponent,
    LeaderboardComponent,
    BuyableModalComponent,
    FreeSectionsModalComponent,
    FreePresentModalComponent,
  ],
})
export class AppModule {}
