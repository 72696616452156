import {
  animate,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';

import { User } from '../../shared/user/user';

@Component({
  selector: 'exo-complete-profile',
  templateUrl: 'complete-profile.html',
  styleUrls: ['complete-profile.scss'],
  animations: [
    trigger('stagger', [
      transition('* => *', [
        query(':leave', [
          stagger(
            100, [
              animate('1s ease-in-out', style({
                transform: 'translateY(10%) translateX(-50%)',
              })),
            ],
          ),
        ],    { optional: true }),
        query(':enter', [
          style({
            transform: 'translateY(-10%) translateX(-50%)',
          }),
          stagger(100, [
            animate('0.5s ease-in-out', style({
              transform: 'translateY(0) translateX(-50%)',
            })),
          ]),
        ],    { optional: true }),
      ]),
    ]),
    trigger('popup', [
      state('*', style({ opacity: 1, transform: 'translateY(0) translateX(-50%)' })),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(10px) translateX(-50%)' }),
        animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0) translateX(-50%)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0) translateX(-50%)' }),
        animate('200ms ease-out', style({ opacity: 0, transform: 'translateY(-10px) translateX(-50%)' })),
      ]),
    ]),
    trigger('overlay', [
      state('*', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class CompleteProfileComponent implements OnChanges {
  @Output() out = new EventEmitter<any>();
  @Input() user: User;
  popupIsVisible = false;
  popupType = '';

  state = '';
  notCompletes = [];
  allStates = [];

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(evt: KeyboardEvent) {
    this.popupIsVisible = false;
  }

  constructor(
  ) {

  }

  ngOnChanges() {
    this.allStates = ['username', 'school'];
    this.notCompletes = [];
    if (!this.user.nickname || this.user.nickname.isGenerated) {
      this.notCompletes.push('username');
    }
    if (!this.user.school) {
      this.notCompletes.push('school');
    }

    this.state = this.notCompletes[0];
  }

  data (event: any) {
    if (event.dismiss === true) {
      this.popupIsVisible = false;
    }
    if (event.nickname) {
      this.out.emit({ nickname: event.nickname });
    }
    if (event.school) {
      this.out.emit({ school: event.school });
    }
  }

  profileDoneCount() {
    return this.allStates.length - this.notCompletes.length;
  }

}
