import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Exercise } from "./exercises";

@Injectable()
export class ExercisesService {
  public CONVERSION_EXPERIMENT_NAME = "conversion-experiment";
  constructor(private http: HttpClient) {}

  linkExercises(code): Observable<any> {
    return this.http.put("/exercises/link", { code });
  }

  getExercisesBySubjectId(subjectId: string): Observable<Exercise> {
    return this.http.get<Exercise>(`/subject/${subjectId}/exercises`);
  }
}
