import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RegisterService } from '../../../shared/register/register.service';

@Component({
  selector: 'exo-course-level',
  templateUrl: 'course-level.html',
  styleUrls: ['course-level.scss'],
})
export class CourseLevelComponent implements OnInit {
  @Output() out = new EventEmitter<any>();
  @Input() component: boolean; // component mode
  @Input() userCourseLevel; // component mode

  courseLevels;

  constructor(private registerService: RegisterService) {

  }

  ngOnInit() {
    this.registerService.getCourseLevels().subscribe((data) => {
      this.courseLevels = data;
    },
    (err) => {
      console.log(err);
    });
  }
}
