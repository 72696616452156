<div class="widget">
  <header>
    <span
      *ngIf="displayMode === 'component'"
      (click)="out.emit({back: true})"
      class="fa fa-arrow-left"
    ></span>
    <span
      *ngIf="displayMode !== 'component'"
      style="left: initial; right: 30px"
      (click)="out.emit({dismiss: true})"
      class="fa fa-times"
    ></span>
    <p *ngIf="displayMode === 'popup'">Selecteer vakken voor {{courseLevel}}</p>
    <p *ngIf="displayMode !== 'popup'">Selecteer hieronder minimaal 3 vakken voor {{courseLevel}}</p>
  </header>
  <div class="content">
    <exo-loader *ngIf="loading"></exo-loader>
    <div class="row" *ngIf="!loading">
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Talen</span>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item extra added"
            (click)="otherCourses['Talen'].splice(otherCourses['Talen'].indexOf(course), 1)"
            *ngFor="let course of otherCourses['Talen']; let i = index"
          >
            <span>{{course.name}}</span><span>{{course.courseLevel}}</span>
          </li>
          <li
            class="list-group-item"
            [class.added]="course.added"
            (click)="toggleCourse('Talen', i)"
            *ngFor="let course of courses['Talen']; let i = index"
          >
            {{course.name}}
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Exacte vakken</span>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item extra added"
            (click)="otherCourses['Exacte vakken'].splice(otherCourses['Exacte vakken'].indexOf(course), 1)"
            *ngFor="let course of otherCourses['Exacte vakken']; let i = index"
          >
            <span>{{course.name}}</span><span>{{course.courseLevel}}</span>
          </li>
          <li
            class="list-group-item"
            [class.added]="course.added"
            (click)="toggleCourse('Exacte vakken', i)"
            *ngFor="let course of courses['Exacte vakken']; let i = index"
          >
            {{course.name}}
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Maatschappijvakken</span>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item extra added"
            (click)="otherCourses['Maatschappij vakken'].splice(otherCourses['Maatschappij vakken'].indexOf(course), 1)"
            *ngFor="let course of otherCourses['Maatschappij vakken']; let i = index"
          >
            <span>{{course.name}}</span><span>{{course.courseLevel}}</span>
          </li>
          <li
            class="list-group-item"
            [class.added]="course.added"
            (click)="toggleCourse('Maatschappij vakken', i)"
            *ngFor="let course of courses['Maatschappij vakken']; let i = index"
          >
            {{course.name}}
          </li>
        </ul>
      </div>
      <div class="col-md-3">
        <div class="list-group-header">
          <span>Extra vakken</span>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item extra added"
            (click)="otherCourses['Extra vakken'].splice(otherCourses['Extra vakken'].indexOf(course), 1)"
            *ngFor="let course of otherCourses['Extra vakken']; let i = index"
          >
            <span>{{course.name}}</span><span>{{course.courseLevel}}</span>
          </li>
          <li
            class="list-group-item"
            [class.added]="course.added"
            (click)="toggleCourse('Extra vakken', i)"
            *ngFor="let course of courses['Extra vakken']; let i = index"
          >
            {{course.name}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="displayMode !== 'popup'" class="footer">
    <a class="btn-link" (click)="showpopup = true"
      >Ik volg ook vakken op een ander niveau</a
    >
    <div
      class="overlay"
      [class.component]="displayMode === 'modal'"
      *ngIf="showpopup"
      [@overlay]
      (click)="showpopup = false; selectOtherCourses = false"
    ></div>
    <exo-course-level
      *ngIf="showpopup && !selectOtherCourses"
      [@popup]
      [userCourseLevel]="courseLevel"
      [component]="true"
      (out)="data($event)"
    ></exo-course-level>
    <exo-select-courses
      *ngIf="showpopup && selectOtherCourses"
      [@popup]
      [courseLevel]="otherCourseLevel"
      [displayMode]="'popup'"
      (out)="data($event)"
    ></exo-select-courses>
    <exo-button
      classes="btn-regular"
      text="{{displayMode === 'component' ? 'Volgende' : 'Selecteer'}}"
      disabled="{{filterCourses().length < 3}}"
      (clickEvent)="submit()"
    ></exo-button>
  </div>
</div>
