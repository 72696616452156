import { Component, OnInit } from '@angular/core';

import { ModalController } from '../../modal/modal.controller';

@Component({
  selector: 'exo-summary-tutorial',
  templateUrl: 'summary-tutorial.html',
  styleUrls: ['summary-tutorial.scss'],
})
export class SummaryTutorialModalComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  public close() {
    this.modalCtrl.closeModal();
  }

}
