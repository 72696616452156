import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { VideosService } from '../../../shared/videos/videos.service';

import { DatalayerService } from './../../../services/datalayer/datalayer.service';

@Component({
  selector: 'exo-video',
  templateUrl: 'video.html',
  styleUrls: ['video.scss'],
})
export class VideoComponent implements OnInit {
  title: string;
  link: SafeResourceUrl;
  notFound = false;

  constructor(
    private videosService: VideosService,
    private sanitizer: DomSanitizer,
    private datalayerService: DatalayerService,
  ) { }

  ngOnInit() {
    this.videosService.getVideoOfTheDay().subscribe((video) => {
      this.title = video.title;
      video.link = video.link.replace('watch?v=', 'embed/');
      this.link = this.sanitizer.bypassSecurityTrustResourceUrl(video.link);
    },
    (err) => {
      if (err.status === 404) {
        this.notFound = true;
      }
    });
  }

  sendDatalayer() {
    this.datalayerService.send({
      hitType: 'event',
      eventCategory: 'video',
      eventAction: 'play',
      eventLabel: 'daily-video',
    });
  }

}
