<div class="row">
  <div class="col-md-12">
    <div class="card spaceless">
      <header>
        Video's
      </header>
      <div class="content" *ngIf="loading">    
        <exo-loader></exo-loader>
      </div>
      <div class="content video" *ngIf="!loading">
        <div class="video-content" *ngFor="let video of videos" (click)="showVideo(video)">
          <div class="image">        
            <img [src]="'https://img.youtube.com/vi/' + video.ytId + '/mqdefault.jpg'" />
            <img class="playbutton" src="./assets/playbutton.png"/>
          </div>
          <div class="title">
            {{video.title}}
          </div>
        </div>
      </div>
      <div class="content" *ngIf="notFound">
        Geen video's beschikbaar
      </div>
    </div>
    <div [@vid] class="overlay" *ngIf="showVid" (click)="showVid = false">
      <iframe [attr.src]="curVid?.safeLink" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</div>