import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { SubjectService } from "../../../shared/subjects/subject.service";

@Component({
  selector: "exo-subjects-difficult",
  templateUrl: "subjects-difficult.html",
  styleUrls: ["subjects-difficult.scss"],
  animations: [
    trigger("popup", [
      state("*", style({ opacity: 1, transform: "translateY(-50%)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-48%)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 1, transform: "translateY(-50%)" })
        ),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(-50%)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 0, transform: "translateY(-52%)" })
        ),
      ]),
    ]),
    trigger("overlay", [
      state("*", style({ opacity: 1 })),
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-out", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SubjectDifficultComponent implements OnInit {
  @Output() out = new EventEmitter<any>();
  @Input() courseLevel;
  @Input() subjects;

  @Input() courses;
  @Input() otherCourses;

  loading = true;

  difficultSubjects: string[];

  constructor(private subjectService: SubjectService) {}

  ngOnInit() {
    this.loading = false;
  }

  toggleCourse(type, index, other?: boolean) {
    if (other) {
      this.otherCourses[type][index].difficult =
        !this.otherCourses[type][index].difficult;
    } else {
      this.courses[type][index].difficult =
        !this.courses[type][index].difficult;
    }
  }

  submit() {
    let difficultSubjects = [];

    difficultSubjects = difficultSubjects.concat(
      this.courses["Talen"].filter((course: any) => course.difficult)
    );
    difficultSubjects = difficultSubjects.concat(
      this.courses["Exacte vakken"].filter((course: any) => course.difficult)
    );
    difficultSubjects = difficultSubjects.concat(
      this.courses["Maatschappij vakken"].filter(
        (course: any) => course.difficult
      )
    );
    difficultSubjects = difficultSubjects.concat(
      this.courses["Extra vakken"].filter((course: any) => course.difficult)
    );

    difficultSubjects = difficultSubjects.concat(
      this.otherCourses["Talen"].filter((course: any) => course.difficult)
    );
    difficultSubjects = difficultSubjects.concat(
      this.otherCourses["Exacte vakken"].filter(
        (course: any) => course.difficult
      )
    );
    difficultSubjects = difficultSubjects.concat(
      this.otherCourses["Maatschappij vakken"].filter(
        (course: any) => course.difficult
      )
    );
    difficultSubjects = difficultSubjects.concat(
      this.otherCourses["Extra vakken"].filter(
        (course: any) => course.difficult
      )
    );

    difficultSubjects = difficultSubjects.map((subject) => subject._id);

    this.out.emit({ difficultSubjects });
  }
}
