import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { User } from "@sentry/angular";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { ModalController } from "../../../components/modal/modal.controller";
import { BuyableModalComponent } from "../../../components/modals/buyable-modal/buyable-modal.component";
import { SummaryTutorialModalComponent } from "../../../components/modals/summary-tutorial/summary-tutorial.component";
import { AuthService } from "../../../shared/auth/auth.service";
import { environment } from "../../../shared/environments/environment";
import { Subject } from "../../../shared/subjects/subject";
import { SubjectService } from "../../../shared/subjects/subject.service";
import {
  Summary,
  SummaryChapter,
  SummarySection,
  SummaryTopic,
} from "../../../shared/summaries/summary";
import { SummaryService } from "../../../shared/summaries/summary.service";
import { SummaryProgressService } from "../../../shared/summary-progress/summary-progress.service";
import { BuyableType } from "../../../shared/training/buyable";
import { TrainingService } from "../../../shared/training/training.service";
import { ratingColorLabel } from "../../../shared/util/ratingColorLabel";

import { DatalayerService } from "./../../../services/datalayer/datalayer.service";

@Component({
  selector: "exo-summary",
  templateUrl: "summary.html",
  styleUrls: ["summary.scss"],
})
export class SummaryComponent implements OnInit, OnDestroy {
  @ViewChild("reader", { static: true }) reader: ElementRef;
  public subject: Subject;
  public user: User;
  public loaded = false;
  public showNav = true;
  public isLastSection = false;
  public lastResult: any;
  public answersLink: string;
  public selectedSection: SummarySection;
  public selectedChapter: SummaryChapter;
  public summarySections: SummarySection;
  public practiceModuleActive = false;

  private subjectEncodedName: string;
  private subjectSubscription: Subscription;
  private querySection: string;
  private selectedSectionIndex = 0;
  private selectedChapterIndex = 0;
  private isSummaryPreview = true;
  private isTrainingPreview = true;
  private subjectProgress = [];
  private summaryHtml: any;
  private lastResults: any;
  private summary: Summary;
  private summaryProgress;

  readonly SHOW_TUTORIAL_DELAY = 1000;

  constructor(
    private route: ActivatedRoute,
    private summaryService: SummaryService,
    private subjectService: SubjectService,
    private datalayerService: DatalayerService,
    private trainingService: TrainingService,
    private summaryProgressService: SummaryProgressService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private router: Router,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.hideNavOnMobileDevice();
  }

  async ngOnInit() {
    this.setCopyProtection(true);

    this.authService.getUser().subscribe((user: User) => {
      this.user = user;
      this.openTutorialModal();
    });

    this.route.queryParams.subscribe((params: ParamMap) => {
      if (params["section"]) {
        this.querySection = params["section"];
      }
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.subjectEncodedName = params.get("slug");
      this.subjectSubscription = this.subjectService.subjectChange$.subscribe(
        (subject: Subject) => {
          if (!subject || subject?.encodedName !== this.subjectEncodedName) {
            this.subjectService
              .getSubjectBySlug(this.subjectEncodedName)
              .pipe(first())
              .subscribe();
          }
          this.subject = subject;
        }
      );
    });
    await this.getSummaryProgress();
    this.getSummary();
  }

  openTutorialModal() {
    const tutorialOpenedUsers =
      JSON.parse(localStorage.getItem("summary-tutorial-opened")) || [];

    if (
      !tutorialOpenedUsers.find((userId: string) => userId === this.user._id)
    ) {
      setTimeout(() => {
        this.modalCtrl.setModal(SummaryTutorialModalComponent, {});
      }, this.SHOW_TUTORIAL_DELAY);

      tutorialOpenedUsers.push(this.user._id);
      localStorage.setItem(
        "summary-tutorial-opened",
        JSON.stringify(tutorialOpenedUsers)
      );
    }
  }

  getSummary() {
    this.summaryService
      .getSummaryBySubjectEncodedName(this.subjectEncodedName)
      .subscribe((summary) => {
        this.summary = summary;
        this.summaryHtml = this.summary.summary;
        if (summary.answers) {
          this.answersLink = `${environment.API_URL}${this.summary.answers}`;
        }
        this.getSummarySections();
      });

    this.getResults();
  }

  getSummarySections() {
    this.summaryService
      .getSummarySections(this.subjectEncodedName)
      .subscribe((summarySections) => {
        this.summarySections = summarySections;
        this.isSummaryPreview = summarySections.previewSummary;
        this.isTrainingPreview = summarySections.previewExercise;
        this.practiceModuleActive = summarySections.practiceModuleActive;

        this.loaded = true;

        if (this.querySection) {
          let selectedSectionIndex: number;

          summarySections.chapters.forEach(
            (chapter: SummaryChapter, chapterIndex: number) => {
              selectedSectionIndex = chapter.sections.findIndex(
                (section: SummarySection) =>
                  section.encodedSectionName === this.querySection
              );
              if (selectedSectionIndex !== -1) {
                this.selectSection(chapterIndex, selectedSectionIndex);
              }
            }
          );
          return;
        }

        this.selectSection(0, 0);
      });
  }

  getResults() {
    this.trainingService
      .getLastResults(this.subjectEncodedName)
      .pipe(first())
      .subscribe((lastResults: any) => {
        this.lastResults = lastResults;
      });
  }

  getSelectedResult() {
    if (this.lastResults) {
      this.lastResult = this.lastResults.find(
        (result: any) =>
          result.encodedSectionName === this.selectedSection.encodedSectionName
      );
    }
  }

  selectSection(chapterIndex: number, sectionIndex: number) {
    // show buyable modal if section is locked
    if (
      this.isSummaryPreview &&
      !this.summarySections.chapters[chapterIndex].sections[sectionIndex]
        .preview
    ) {
      this.openBuyableModal();
      return;
    }

    this.selectedChapterIndex = chapterIndex;
    this.selectedSectionIndex = sectionIndex;

    this.selectedChapter = this.summaryHtml[chapterIndex];
    this.selectedSection = this.selectedChapter.sections[sectionIndex];
    this.selectedSection.preview =
      this.summarySections.chapters[chapterIndex].sections[
        sectionIndex
      ].preview;

    this.isLastSectionCheck(
      this.selectedChapterIndex,
      this.selectedSectionIndex
    );
    this.getSelectedResult();

    this.scrollToTop();
    this.hideNavOnMobileDevice();

    this.router.navigate([], {
      skipLocationChange: false,
      queryParams: { section: this.selectedSection.encodedSectionName },
    });

    this.summaryProgressService.startTracking(
      this.selectedChapter,
      this.selectedSection,
      this.subject.encodedName,
      this.getFirstUnread()
    );
  }

  selectNextSection() {
    // select next section within same chapter
    if (
      this.summarySections.chapters[this.selectedChapterIndex].sections[
        this.selectedSectionIndex + 1
      ]
    ) {
      this.selectSection(
        this.selectedChapterIndex,
        this.selectedSectionIndex + 1
      );
    }

    // select next chapter and first section
    else if (this.summarySections.chapters[this.selectedChapterIndex + 1]) {
      this.selectSection(this.selectedChapterIndex + 1, 0);
    }
  }

  isLastSectionCheck(chapterIdx: number, sectionIdx: number) {
    const lastChapterIndex = this.summarySections.chapters.length - 1;
    const lastSectionIndex =
      this.summarySections.chapters[lastChapterIndex].sections.length - 1;

    this.isLastSection =
      chapterIdx === lastChapterIndex && sectionIdx === lastSectionIndex;
  }

  scrollToTop() {
    this.reader.nativeElement.scrollTo(0, 0);
  }

  hideNavOnMobileDevice() {
    if (window.innerWidth <= 768) {
      this.showNav = false;
    }
  }

  sendDatalayer() {
    this.datalayerService.send({
      hitType: "event",
      eventCategory: "course-menu",
      eventAction: "navigatie",
      eventLabel: "answers",
    });
  }

  async checkReaderBottomIsHit(scrollPos: number) {
    const scrollClearanceAmount = 50;
    const { top, bottom } = this.reader.nativeElement.getBoundingClientRect();
    const readerHeight = this.reader.nativeElement.scrollHeight - bottom + top;

    if (scrollPos >= readerHeight - scrollClearanceAmount) {
      await this.getSummaryProgress();
      this.summaryProgressService.setScrollingIsDone();
    }
  }

  onReaderScroll(ev) {
    const scrollPos = ev.srcElement.scrollTop;
    this.checkReaderBottomIsHit(scrollPos);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.hideNavOnMobileDevice();
  }

  setCopyProtection(bool: Boolean) {
    this.document.oncontextmenu = () => {
      return !bool;
    };
  }

  openBuyableModal() {
    let buyableType = BuyableType.all;
    if (this.isSummaryPreview && !this.isTrainingPreview)
      buyableType = BuyableType.summary;
    if (this.isTrainingPreview && !this.isSummaryPreview)
      buyableType = BuyableType.training;

    this.modalCtrl.setModal(BuyableModalComponent, {
      subject: this.subject,
      buyableType,
    });
  }

  getTopicRatingLvl(encodedTopicName: string) {
    const topic = this.summarySections.chapters[
      this.selectedChapterIndex
    ].sections[this.selectedSectionIndex].topics.find(
      (topic: SummaryTopic) => topic.encodedTopicName === encodedTopicName
    );

    return ratingColorLabel(topic?.rating);
  }

  getFirstUnread() {
    let firstUnreadSection: SummarySection;
    const sections = _.uniq(
      _.flatMap(this.summarySections.chapters, "sections")
    );

    firstUnreadSection = sections.find((section: SummarySection) => {
      const sectionNotInProgress = !this.summaryProgress.find(
        (_section) => _section.encodedSectionName === section.encodedSectionName
      );
      const notCurrentSection =
        section.encodedSectionName !== this.selectedSection.encodedSectionName;
      return sectionNotInProgress && notCurrentSection;
    });

    return firstUnreadSection;
  }

  async getSummaryProgress() {
    this.summaryProgress = await this.summaryProgressService.loadProgress();
    const tempSubjectProgress = _.groupBy(
      this.summaryProgress,
      (progress) => progress.encodedSubjectName
    );

    this.subjectProgress = [];
    Object.entries(tempSubjectProgress).forEach((entry) => {
      const [key, value] = entry;
      this.subjectProgress.push({
        progresses: value,
      });
    });
  }

  ngOnDestroy(): void {
    this.loaded = false;
    this.subjectSubscription.unsubscribe();
    this.summaryProgressService.resetTracking();
    this.setCopyProtection(false);
  }
}
