import { Component, Input, OnInit } from '@angular/core';
import { Subject } from '../../../shared/subjects/subject';
import { SummaryTopic } from '../../../shared/summaries/summary';
import { SummaryService } from '../../../shared/summaries/summary.service';
import { ModalController } from '../../modal/modal.controller';

@Component({
  selector: 'exo-topic-modal',
  templateUrl: 'topic-modal.html',
  styleUrls: ['topic-modal.scss'],
})
export class TopicModalComponent implements OnInit {
  @Input() subject: Subject;
  @Input() encodedTopicName: string;

  public topic: SummaryTopic;

  constructor(
    private modalCtrl: ModalController,
    private summaryService: SummaryService,
  ) { }

  ngOnInit() {
    this.getTopic(this.encodedTopicName);
  }

  getTopic(encodedTopicName: string) {
    this.summaryService.getTopic(this.subject.encodedName, encodedTopicName).subscribe((topic: SummaryTopic) => {
      this.topic = topic;
    });
  }

  close() {
    this.modalCtrl.closeModal();
  }
}
