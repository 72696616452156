import { Component, Input, OnInit } from '@angular/core';

import { UserBadge } from '../../shared/badges/badge';
import { ModalController } from '../modal/modal.controller';

@Component({
  selector: 'exo-badge',
  templateUrl: 'badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() userBadge: UserBadge;
  @Input() detailed: Boolean;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  calcProgress(userBadge: UserBadge) {
    if (userBadge.progress.length === 0) {
      return '0%';
    }
    return `${this.amount(userBadge) / this.targetAmount(userBadge) * 100}%`;
  }

  amount(userBadge: UserBadge) {
    return userBadge.progress.reduce((total, badgeProgress) => total + badgeProgress.amount, 0);
  }

  targetAmount(userBadge: UserBadge) {
    return userBadge.badge.goals.reduce((total, goal) => total + goal.targetAmount, 0);
  }

  closeModal() {
    this.modalCtrl.closeModal();
  }

}
