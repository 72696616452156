import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { environment } from '../../../shared/environments/environment';
import { ProductService } from '../../../shared/products/product.service';
import { ProductBundle } from '../../../shared/products/productBundle';

@Component({
  selector: 'exo-cart-buyables',
  templateUrl: 'buyables.html',
  styleUrls: ['buyables.scss'],
})
export class CartBuyablesComponent implements OnInit, OnDestroy {
  @Input() simpleMode? = false;

  public buyables = [];
  public products = [];
  private cart: Cart;
  public apiUrl = environment.API_URL;
  private cartSubscription: Subscription;

  constructor(
    private router: Router,
    private cartService: CartService,
    private productService: ProductService,
  ) {
  }

  ngOnInit() {
    this.cartSubscription = this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
      this.getBuyables();
    });
  }

  getBuyables() {
    this.productService.getBuyAbleProductBundlesForUser().pipe(first()).subscribe((products) => {
      this.buyables = products.sort((a, b) => b.price - a.price);
      this.filterBuyables();
    });
  }

  addToCart(product) {
    this.cartService.addToCart(product);
    this.filterBuyables();
    if (this.simpleMode) this.router.navigate(['/cart']);
  }

  filterBuyables() {
    // Remove products that already are in the cart
    this.products = this.buyables.filter((product: ProductBundle) => {
      return !this.cart.items.find((cartItem: ProductBundle) => {
        return cartItem._id === product._id;
      });
    });
  }

  ngOnDestroy() {
    this.cartSubscription.unsubscribe();
  }

}
