<div class="widget">
  <header>
    Veelgestelde vragen
  </header>
  <div class="content">
    <p>Heb je een vraag? Wij hebben het antwoord voor je!</p>
    <p>Hieronder kun je de antwoorden vinden op veelgestelde vragen over onze producten en diensten.</p>
    <p><strong>84% van onze gebruikers vond hier het antwoord op zijn vraag.</strong></p>
    <p>Mocht jouw vraag hier niet tussen staan, dan kun je contact met ons opnemen.</p>

    <div class="category" *ngFor="let category of faq">
      <h2><span style="color: #EC671B;">{{category.category}}</span></h2>
      <div class="question" [class.open]="currentQuestion == question" *ngFor="let question of category.questions">
        <p class="q" (click)="currentQuestion == question ? currentQuestion = null : currentQuestion = question"><strong>{{question.q}}</strong><span class="fa fa-caret-down"></span></p>
        <div *ngIf="currentQuestion == question" [@question] [innerHTML]="question.a"></div>
      </div>
    </div>
  </div>
</div>