<div class="payment-status-container">
  <exo-card space="0">
    <div class="card-content">
      <figure class="{{paymentStatus}}">
        <img [src]="statusIcon" />
      </figure>
      <span class="TextSubjectMollieTitle">{{statusDescription}}</span>
      <div class="payment-back-btn">
        <exo-button [style.width]="'80%'" classes="btn-regular" text="Terug naar het dashboard" [routerLink]="['/dashboard']"></exo-button>
      </div>
    </div>
  </exo-card>

</div>