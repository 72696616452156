import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'exo-already-purchased',
  templateUrl: 'already-purchased.html',
  styleUrls: ['already-purchased.scss'],
})
export class AlreadyPurchasedComponent implements OnInit {
  @Output() out = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }
}
