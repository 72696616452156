<div class="widget">
  <header>
    Disclaimer
  </header>
  <div class="content">
    Voor het gebruik van het digitale platform ExamenOverzicht.nl gelden gebruiksvoorwaarden. Deze gaan onder andere in op de volgende punten:
    <br/><br/>
    • registratie op de website;<br/>
    • gebruik van het online platform;<br/>
    • onderhoud en storingen;<br/>
    • aansprakelijkheid.<br/><br/>
    De algemene voorwaarden dien je te accepteren alvorens een account aan te kunnen maken. De gebruiksvoorwaarden van het digitale platform zijn als volgt:
    <br/><br/>
    Je staat in voor het invullen van juiste persoonsgegevens bij het aanmaken van een account. Als je via de social media plugin inlogt, gebruik je een persoonlijk account. Het aanmaken van een generiek of gedeeld account is niet toegestaan. Je account mag niet worden gedeeld met anderen.
    <br/><br/>
    Digitale producten zijn pas in de persoonlijke omgeving zichtbaar na het voltooien van een bestelling van een digitale product en na het invoeren van de activatiecode in de persoonlijke omgeving.
    <br/><br/>
    We zullen af en toe onderhoud moeten plegen om het platform goed werkend te houden. We proberen dit zoveel mogelijk te doen op momenten waarop er weinig verkeer op de website is. Mochten we onderhoud kunnen plannen en denken we dat het even kan duren, dan melden we dit vooraf. We zullen niet overgaan tot het vergoeden van schade als gevolg van het aanpassen van de website.
    <br/><br/>
    Bij overmacht (denk aan storingen in telecommunicatie, infrastructuur, staking, problemen bij toeleveranciers) die buiten onze invloed liggen, zullen we ook niet overgaan tot het vergoeden van schade.
    <br/><br/>
    Na het aflopen van de gebruiksperiode van een digitaal product, blijft de persoonlijke omgeving bestaan voor het geval je nieuwe producten zou willen toevoegen of zou willen verlengen. Mocht je je account volledig willen opheffen, dan kun je ons een mail sturen. Wij verwijderen vervolgens binnen 7 dagen je volledige account.
    <br/><br/>
    De gebruiksvoorwaarden blijven van kracht voor onbepaalde tijd tot het volledige account wordt verwijderd. Mochten we misbruik van de digitale omgeving vermoeden, dan hebben we het recht je account op te schorten zonder compensatie of schadevergoeding.
    <br/><br/>
    De volgende browsers worden ondersteund: Google Chrome, Safari, FireFox en Microsoft Edge.
  </div>
</div>