import { Injectable } from '@angular/core';

import { CredentialsModel } from './credentials.model';

@Injectable()
export class PasswordService {
  private passwordRegex = /(?=.*?[0-9])(?=.*?[A-Za-z]).+/;

  public validatePassword(credentials: CredentialsModel, emptyError = true, checkCurrent = false): [boolean, string] {
    if (emptyError && ((checkCurrent && !credentials.current) || !credentials.password || !credentials.repeat)) {
      return [false, 'Niet alle velden zijn ingevuld.'];
    }
    if (!credentials.password || credentials.password.length < 8) {
      return [false, 'Wachtwoord moet minimaal 8 tekens bevatten.'];
    }
    if (!credentials.password || credentials.password.length > 72) {
      return [false, 'Wachtwoord mag maximaal 72 karakters bevatten.'];
    }
    if (!this.passwordRegex.test(credentials.password)) {
      return [false, 'Wachtwoord moet minimaal een letter en een speciaal teken of cijfer bevatten.'];
    }
    else if (['examenoverzicht', 'password', 'wachtwoord'].some(word => credentials.password.includes(word))) {
      return [false, 'Wachtwoord mag niet de woorden \'examenoverzicht\', \'password\' of \'wachtwoord\' bevatten.'];
    }
    if (credentials.password !== credentials.repeat) {
      return [false, 'Wachtwoorden komen niet overeen.'];
    }
    return [true, ''];
  }
}
