import { Component, Input, OnInit } from '@angular/core';
import { differenceInDays } from 'date-fns';
import { first, map } from 'rxjs/operators';

import { DatalayerService } from '../../services/datalayer/datalayer.service';
import { Leaderboard, LeaderBoardResponse, Score } from '../../shared/leaderboard/leaderboard';
import { LeaderboardService } from '../../shared/leaderboard/leaderboard.service';
import { User } from '../../shared/user/user';
import { UserService } from '../../shared/user/user.service';
import { HttpAuthInterceptor } from '../../shared/util/httpAuth.interceptor';
import { ModalController } from '../modal/modal.controller';

@Component({
  selector: 'exo-leaderboard',
  templateUrl: 'leaderboard.html',
  styleUrls: ['leaderboard.scss'],
})
export class LeaderboardComponent implements OnInit {
  @Input() mode = '';
  public leaderboard: Leaderboard;
  public neighborScores: Score[];
  public daysLeft: number;
  private user: User;

  constructor(
    private leaderboardService: LeaderboardService,
    private modalController: ModalController,
    private userService: UserService,
    private dataService: DatalayerService,
    private auth: HttpAuthInterceptor,
  ) {
  }

  ngOnInit() {
    this.leaderboardFeatureCheck().subscribe((isSupported: boolean) => {
      if (isSupported) {
        this.getLeaderboard();
      }
    });

    this.auth.user.pipe(first()).subscribe((user: User) => {
      this.user = user;

      if (this.mode === 'popup') {
        this.createEventForAnalytics();
      }
    });
  }

  leaderboardFeatureCheck() {
    return this.userService.supportedFeatures$.pipe(map(features => features.includes('leaderboard')));
  }

  getLeaderboard() {
    this.leaderboardService.getLeaderboard().subscribe((leaderboardRes: LeaderBoardResponse) => {
      if (leaderboardRes.isAssigned) {
        const leaderboard = leaderboardRes.leaderboard
        leaderboard.scores.map((score, i) => score.rank = i + 1);
        this.leaderboard = leaderboard;
        this.neighborScores = this.setNeighborScores(leaderboard);
        this.daysLeft = differenceInDays(new Date(leaderboard.endDate), new Date());
      }
    });
  }

  setNeighborScores(leaderboard: Leaderboard) {
    const lowerScores = leaderboard.scores.slice().slice(3);
    const myScoreIndex = lowerScores.findIndex((score: Score) => score.isMe);

    if (lowerScores.length <= 5 || this.mode === 'popup') {
      return lowerScores;
    }

    if (myScoreIndex < 0) {
      return lowerScores.slice(0, 5);
    }

    if (myScoreIndex >= lowerScores.length - 2) {
      return lowerScores.slice(Math.max(lowerScores.length - 5, 0));
    }

    return lowerScores.slice(myScoreIndex - 2, myScoreIndex + 3);
  }

  openLeaderboardModal() {
    this.modalController.setModal(LeaderboardComponent,
      {
        mode: 'popup',
      },
    );
  }

  closeModal() {
    this.modalController.closeModal();
  }

  createEventForAnalytics = () => {
    if (this.user) {
      this.dataService.send({
        hitType: 'event',
        eventCategory: 'button',
        eventLabel: this.user._id,
        eventAction: 'view-leaderboard',
      });
    }
  }

}
