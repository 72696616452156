import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'exo-button-deprecated',
  templateUrl: 'button-deprecated.html',
  styleUrls: ['button-deprecated.scss'],
})
export class ButtonDeprecatedComponent implements OnInit {
  @Input() icon: string;
  @Input() label: string;
  @Input() labelSecond: string;
  @Input() size: string;
  @Input() style: string;
  @Input() extra: string;
  @Input() extra2: string;
  @Input() extra3: string;
  @Input() active: boolean;
  @Input() noRadius: boolean;
  @Input() disabled: boolean;
  @Input() data: any;
  @Output() clickEvent = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  public onSubmit() {
    if (!this.disabled) {
      this.clickEvent.emit();
    }
  }

}
