import { Component, Input, OnInit } from '@angular/core';

import { Subject } from '../../../shared/subjects/subject';
import { SubjectService } from '../../../shared/subjects/subject.service';

@Component({
  selector: 'exo-next-subject',
  templateUrl: 'next-subject.html',
  styleUrls: ['next-subject.scss'],
})
export class NextSubjectComponent implements OnInit {
  @Input() subject: Subject;
  days;
  hasDefinitions: boolean;

  constructor(private subjectService: SubjectService) {
  }

  ngOnInit() {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(this.subject.timeslots['1'].startDate);

    this.days = Math.round((firstDate.getTime() - Date.now()) / oneDay);

    this.subjectService.getDefinitions(this.subject._id).subscribe((definitions) => {
      this.hasDefinitions = Boolean(definitions);
    });
  }
}
