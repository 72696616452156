import { GoogleOAuthProvider, hasGrantedAllScopesGoogle, googleLogout } from 'google-oauth-gsi';
import { BaseLoginProvider } from '../entities/base-login-provider';
import { SocialUser } from '../entities/user';

const GOOGLE_USERINFO_URL = "https://www.googleapis.com/oauth2/v1/userinfo";
const GOOGLE_LOGIN_FLOW = 'implicit';
const GOOGLE_SCOPE_EMAIL = 'email';
const GOOGLE_SCOPE_PROFILE = 'profile';
export class GoogleLoginProvider extends BaseLoginProvider {

  public static readonly PROVIDER_ID: string = 'GOOGLE';

  private googleProvider: GoogleOAuthProvider;
  private login: () => Promise<SocialUser>;

  constructor(private clientId: string) { super(); }

  initialize(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.googleProvider = new GoogleOAuthProvider({
        clientId: this.clientId,
      });
      this.login = () => new Promise((resolveLogin, rejectLogin) => {
        this.googleProvider.useGoogleLogin({
          flow: GOOGLE_LOGIN_FLOW,
          onSuccess: (res) => {
            if (!hasGrantedAllScopesGoogle(res, GOOGLE_SCOPE_EMAIL, GOOGLE_SCOPE_PROFILE)) {
              return rejectLogin();
            };
            fetch(`${GOOGLE_USERINFO_URL}?access_token=${res.access_token}`)
              .then(response => response.json())
              .then(data => {
                const user: SocialUser = new SocialUser();

                user.id = data.id;
                user.name = data.name;
                user.email = data.email;
                user.photoUrl = data.picture;
                user.token = res.access_token;

                return resolveLogin(user);
              });
          },
          onError: (err) => {
            console.error('Failed to login with google', err);
            return rejectLogin(err);
          }
        })();
      });
      reject();
    });
  }

  signIn(): Promise<SocialUser> {
    return this.login();
  }

  signOut(): Promise<void> {
    googleLogout();
    return;
  }

}
