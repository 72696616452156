<div class="results-card">
  <div
    class="results-details"
    [class.no-results]="summarySectionTraining?.sessions?.length <= 0"
  >
    <div>
      <span
        class="TextSummaryResultsTitle dynamic-color"
        [style.margin]="'0 0 8px 0'"
        >{{chapter?.chapterTitle}}</span
      >
      <span class="TextSummaryResultsSubTitle">{{section?.sectionTitle}}</span>
      <span class="training-btn" [style.margin]="'16px 0 0 0'">
        <exo-button
          classes="btn-regular dynamic-bgcolorf"
          text="Test onderdeel{{summarySectionTraining?.sessions.length > 0 ? ' opnieuw' : ''}}"
          (click)="openTraining()"
        ></exo-button>
      </span>
    </div>
    <div *ngIf="summarySectionTraining?.averageRating">
      <exo-chart
        class="summary-results-score"
        [size]="88"
        [score]="summarySectionTraining?.averageRating"
        [strokeSize]="6"
      ></exo-chart>
    </div>
  </div>
</div>
