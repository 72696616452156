import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { SubjectService } from "../../../shared/subjects/subject.service";
import { TipsService } from "../../../shared/tips/tips.service";
import { VideosService } from "../../../shared/videos/videos.service";

@Component({
  selector: "exo-select-courses",
  templateUrl: "select-courses.html",
  styleUrls: ["select-courses.scss"],
  animations: [
    trigger("popup", [
      state("*", style({ opacity: 1, transform: "translateY(-50%)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-48%)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 1, transform: "translateY(-50%)" })
        ),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(-50%)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 0, transform: "translateY(-52%)" })
        ),
      ]),
    ]),
    trigger("overlay", [
      state("*", style({ opacity: 1 })),
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-out", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SelectCoursesComponent implements OnInit {
  @Output() out = new EventEmitter<any>();
  @Input() courseLevel;
  @Input() displayMode: 'popup' | 'component' | 'modal';
  @Input() curCourses;

  showpopup = false;
  selectOtherCourses = false;
  loading = true;

  otherCourseLevel;

  otherCourses: {
    Talen: [];
    "Exacte vakken": [];
    "Maatschappij vakken": [];
    "Extra vakken": [];
  } = {
      Talen: [],
      "Exacte vakken": [],
      "Maatschappij vakken": [],
      "Extra vakken": [],
    };

  courses: {
    Talen: [];
    "Exacte vakken": [];
    "Maatschappij vakken": [];
    "Extra vakken": [];
  } = {
      Talen: [],
      "Exacte vakken": [],
      "Maatschappij vakken": [],
      "Extra vakken": [],
    };

  constructor(
    private subjectService: SubjectService,
    private tipsService: TipsService,
    private videosService: VideosService
  ) { }

  ngOnInit() {
    this.subjectService.getCourses(this.courseLevel).subscribe(
      (data) => {
        for (const course of data) {
          this.courses[course.category].push(course);
        }

        Object.keys(this.courses).forEach((key, index) => {
          this.courses[key].sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );
        });

        if (this.curCourses) {
          this.curCourses.forEach((course) => {
            if (
              this.courses[course.category].findIndex(
                (obj) => obj._id === course._id
              ) !== -1
            ) {
              this.courses[course.category][
                this.courses[course.category].findIndex(
                  (obj) => obj._id === course._id
                )
              ].added = true;
            } else {
              course.added = true;
              this.otherCourses[course.category].push(course);
            }
          });
        }

        this.loading = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  toggleCourse(type, index) {
    if (this.displayMode === 'popup') {
      this.out.emit({ addCourse: this.courses[type][index] });
    } else {
      this.courses[type][index].added = !this.courses[type][index].added;
    }
  }

  data(event) {
    if (event.courseLevel) {
      this.otherCourseLevel = event.courseLevel;
      this.selectOtherCourses = true;
    }

    if (event.dismiss === true) {
      this.selectOtherCourses = false;
      this.showpopup = false;
    }

    if (event.addCourse) {
      if (
        this.otherCourses[event.addCourse.category].findIndex(
          (obj) => obj._id === event.addCourse._id
        ) === -1
      ) {
        this.otherCourses[event.addCourse.category].push(event.addCourse);
      }
      this.selectOtherCourses = false;
      this.showpopup = false;
    }
  }

  submit() {
    this.loading = true;
    let subjects = this.filterCourses();

    if (this.displayMode === 'modal') {
      this.tipsService.refreshTips();
      this.videosService.refreshVideos();
    }

    this.out.emit({
      subjects,
      courses: this.courses,
      otherCourses: this.otherCourses,
    });
  }

  filterCourses() {
    let subjects = [];

    subjects = subjects.concat(
      this.courses["Talen"].filter((course: any) => course.added)
    );
    subjects = subjects.concat(
      this.courses["Exacte vakken"].filter((course: any) => course.added)
    );
    subjects = subjects.concat(
      this.courses["Maatschappij vakken"].filter((course: any) => course.added)
    );
    subjects = subjects.concat(
      this.courses["Extra vakken"].filter((course: any) => course.added)
    );
    subjects = subjects.concat(this.otherCourses["Talen"]);
    subjects = subjects.concat(this.otherCourses["Exacte vakken"]);
    subjects = subjects.concat(this.otherCourses["Maatschappij vakken"]);
    subjects = subjects.concat(this.otherCourses["Extra vakken"]);

    return (subjects = subjects.map((subject) => ({ _id: subject._id, encodedName: subject.encodedName })));
  }
}
