<div class="row">
  <div class="col-md-4">

    <div class="definitions-index">
      <exo-index-widget [subject]="subject"></exo-index-widget>
    </div>

  </div>

  <div class="col-md-8">
    <div class="card spaceless">
    <header>
      Begrippenlijst
      <a class="btn header-content-right" *ngIf="legacy; else modern" (click)="openPdf()">Download</a>
      <ng-template #modern>
        <div class="search">
          <button class="button toggle" (click)="toggleSearch()"></button>
          <div *ngIf="searchOpen" [@search] class="bar">
            <input class="form-control" [ngModel]="searchTerm" (ngModelChange)="onChange($event)" #searchbar />
            <button *ngIf="searchTerm" class="button clear" (click)="clearSearch()" [@clear]></button>
          </div>
        </div>
      </ng-template>
    </header>
    <div [class.loading]="loading || notFound" [class.content]="legacy">
      <exo-loader *ngIf="loading; else content"></exo-loader>
      <ng-template #content>
        <pdf-viewer *ngIf="legacy; else modern" (after-load-complete)="loadComplete()" [src]="link"
          [render-text]="false" [page]="page" [original-size]="false" [show-all]="true" [zoom]="1" style="display: block;"></pdf-viewer>
        <ng-template #modern>
          <ng-container *ngIf="categories.length; else empty">
            <div *ngFor="let category of categories; let j = index">
              <div *ngFor="let subCategory of category.subcategories; let i = index" [id]="'h' + j + 'c' + i" for="index-widget">
                <div class="definition-header" [class.bordered]="i !== 0">
                  <h1 class="category" *ngIf="i === 0" [attr.id]="'h' + j">{{category.name}}</h1>
                  <h2 class="sub-category" [attr.id]="'h' + j + 'c' + i" [style.color]="subject?.color">
                    {{subCategory.name}}
                  </h2>
                </div>
                <div class="definition" *ngFor="let definition of subCategory.definitions">
                  <div class="term"><span [innerHTML]="definition.term"></span></div>
                  <p [innerHTML]="definition.definition"></p>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #empty>
            <div class="fallback">
              {{searchTerm ? 'Geen resultaten gevonden' : 'Voor dit vak is er geen begrippenlijst.'}}
            </div>
          </ng-template>
        </ng-template>
      </ng-template>
      <div *ngIf="notFound">
        Voor dit vak is er geen begrippenlijst.
      </div>
    </div>
  </div>
</div>

</div>
<button *ngIf="scrollTop" [@scroller] class="scroll-top-btn" (click)="scrollToTop()"></button>

