import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './../../shared/user/user.service';

@Component({
  selector: 'exo-feature',
  templateUrl: './feature.component.html',
})
export class FeatureComponent implements OnInit {
  @Input() name: string;
  @Input() invert = false;
  supported$: Observable<boolean>;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.supported$ = this.userService.supportedFeatures$.pipe(map(features => features.includes(this.name) ? !this.invert : this.invert));
  }

}
