<div class="rating" #ratingelement >
  <ul >

    <li (click)="setRating(1)" (mouseover)="rate(1)" (mouseout)="mouseOut()">
      <div [class.editting]="edit">
        <span class="star" [class.show]="rating <= 0" [inlineSVG]="'/assets/nav-icons/star-empty.svg'"></span>
        <span class="star" [class.show]="rating < 0.75 && rating >= 0.25" [inlineSVG]="'/assets/nav-icons/star-half.svg'"></span>
        <span class="star" [class.show]="rating >= 0.75" [inlineSVG]="'/assets/nav-icons/star-full.svg'"></span>
      </div>
    </li>

    <li (click)="setRating(2)" (mouseover)="rate(2)" (mouseout)="mouseOut()">
      <div [class.editting]="edit">
        <span class="star" [class.show]="rating <= 1" [inlineSVG]="'/assets/nav-icons/star-empty.svg'"></span>
        <span class="star" [class.show]="rating < 1.75 && rating >= 1.25" [inlineSVG]="'/assets/nav-icons/star-half.svg'"></span>
        <span class="star" [class.show]="rating >= 1.75" [inlineSVG]="'/assets/nav-icons/star-full.svg'"></span>
      </div>
    </li>

    <li (click)="setRating(3)" (mouseover)="rate(3)" (mouseout)="mouseOut()">
      <div [class.editting]="edit">
        <span class="star" [class.show]="rating <= 2" [inlineSVG]="'/assets/nav-icons/star-empty.svg'"></span>
        <span class="star" [class.show]="rating < 2.75 && rating >= 2.25" [inlineSVG]="'/assets/nav-icons/star-half.svg'"></span>
        <span class="star" [class.show]="rating >= 2.75" [inlineSVG]="'/assets/nav-icons/star-full.svg'"></span>
      </div>
    </li>

    <li (click)="setRating(4)" (mouseover)="rate(4)" (mouseout)="mouseOut()">
      <div [class.editting]="edit">
        <span class="star" [class.show]="rating <= 3" [inlineSVG]="'/assets/nav-icons/star-empty.svg'"></span>
        <span class="star" [class.show]="rating < 3.75 && rating >= 3.25" [inlineSVG]="'/assets/nav-icons/star-half.svg'"></span>
        <span class="star" [class.show]="rating >= 3.75" [inlineSVG]="'/assets/nav-icons/star-full.svg'"></span>
      </div>
    </li>

    <li (click)="setRating(5)" (mouseover)="rate(5)" (mouseout)="mouseOut()">
      <div [class.editting]="edit">
        <span class="star" [class.show]="rating <= 4" [inlineSVG]="'/assets/nav-icons/star-empty.svg'"></span>
        <span class="star" [class.show]="rating < 4.75 && rating >= 4.25" [inlineSVG]="'/assets/nav-icons/star-half.svg'"></span>
        <span class="star" [class.show]="rating >= 4.75" [inlineSVG]="'/assets/nav-icons/star-full.svg'"></span>
      </div>
    </li>
    
  </ul>
</div>
