import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";

import { AuthService } from "./auth/auth.service";
import { BadgesNotificationService } from "./badges/badge.notification.service";
import { BadgesService } from "./badges/badge.service";
import { CartService } from "./cart/cart.service";
import { ClassesService } from "./classes/classes.service";
import { DefinitionService } from "./definitions/definition.service";
import { ExercisesService } from "./exercises/exercises.service";
import { ExperimentService } from "./experiment/experiment.service";
import { LeaderboardService } from "./leaderboard/leaderboard.service";
import { OldexamsService } from "./oldexams/oldexams.service";
import { PasswordService } from "./password/password.service";
import { PaymentService } from "./payment/payment.service";
import { ProductService } from "./products/product.service";
import { RegisterService } from "./register/register.service";
import { SubjectService } from "./subjects/subject.service";
import { SummaryService } from "./summaries/summary.service";
import { SummaryProgressService } from "./summary-progress/summary-progress.service";
import { TipsService } from "./tips/tips.service";
import { TrackingService } from "./tracking/tracking.service";
import { TrainingService } from "./training/training.service";
import { UserService } from "./user/user.service";
import { EnvironmentInterceptor } from "./util/environment.interceptor";
import { ErrorInterceptor } from "./util/error.interceptor";
import { HttpAuthInterceptor } from "./util/httpAuth.interceptor";
import { VideosService } from "./videos/videos.service";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    AuthService,
    OldexamsService,
    RegisterService,
    SubjectService,
    ProductService,
    SummaryService,
    ExercisesService,
    TipsService,
    BadgesService,
    UserService,
    VideosService,
    DefinitionService,
    PasswordService,
    ProductService,
    TrainingService,
    HttpAuthInterceptor,
    BadgesNotificationService,
    LeaderboardService,
    TrackingService,
    PaymentService,
    CartService,
    ClassesService,
    SummaryProgressService,
    ExperimentService,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnvironmentInterceptor,
      multi: true,
    },
  ],
  bootstrap: [],
})
export class SharedModule {}
