<div class="rating" [class.small-rating]="maxRating <= 2" #ratingelement>
  <ul>
    <li
      (click)="setRatingDefinitely(i)"
      *ngFor="let ratingNo of [].constructor(maxRating + 1); index as i"
    >
      <div>
        <span class="star" [class.dynamic-bgcolorf]="rating === i"></span>
        <span class="number">{{ i }}</span>
      </div>
    </li>
  </ul>
</div>
