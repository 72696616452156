import { Component, OnInit } from '@angular/core';

import { Tip } from '../../../shared/tips/tip';
import { TipsService } from '../../../shared/tips/tips.service';

@Component({
  selector: 'exo-tip',
  templateUrl: 'tip.html',
  styleUrls: ['tip.scss'],
})
export class TipComponent implements OnInit {
  title: string;
  description: string;
  notFound = false;

  constructor(private tipsService: TipsService) {
  }

  ngOnInit() {
    this.tipsService.getTipOfTheDay().subscribe((tip) => {
      this.title = tip.title;
      this.description = tip.description;
    },
    (err) => {
      if (err.status === 404) {
        this.notFound = true;
      }
    });
  }
}
