import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserBadge } from '../../shared/badges/badge';
import { BadgesService } from '../../shared/badges/badge.service';

@Component({
  selector: 'exo-badges-featured',
  templateUrl: 'badges-featured.component.html',
  styleUrls: ['./badges-featured.component.scss'],
})
export class BadgesFeaturedComponent implements OnInit {
  loading: boolean;
  userBadge: UserBadge;

  constructor(
    private router: Router,
    private badgesService: BadgesService,
  ) {
  }

  ngOnInit() {
    this.getFeaturedBadge();
  }

  gotoBadges() {
    this.router.navigate(['profile/badges']);
  }

  getFeaturedBadge() {
    this.badgesService.getFeatured().subscribe((userBadge: UserBadge) => {
      this.userBadge = userBadge;
      this.loading = false;
    },
    (err) => {
      this.loading = false;
      console.log(err);
    });
  }

  calcProgress(userBadge: UserBadge) {
    if (userBadge.progress.length === 0) {
      return '0%';
    }

    return `${this.amount(userBadge) / this.targetAmount(userBadge) * 100}%`;
  }

  amount(userBadge: UserBadge) {
    return userBadge.progress.reduce((total, badgeProgress) => total + badgeProgress.amount, 0);
  }

  targetAmount(userBadge: UserBadge) {
    return userBadge.badge.goals.reduce((total, goal) => total + goal.targetAmount, 0);
  }

}
