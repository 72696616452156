import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'score',
})
export class ScorePipe implements PipeTransform {
  transform(value: number): string {
    let newValue = value as any;

    if (!newValue || isNaN(newValue)) {
      return '-';
    }

    if (typeof newValue === 'string') {
      newValue = parseFloat(newValue);
    }

    return `${newValue.toFixed(1).toString().replace('.', ',')}`;
  }
}
