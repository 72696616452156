import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'exo-chart',
  templateUrl: 'chart.html',
  styleUrls: ['chart.scss'],
})
export class ChartComponent implements OnInit {
  @Input() score: number;
  @Input() size: number;
  @Input() strokeSize = 8;
  @Input() id = 'chart';
  @Input() showPercentage = false;

  private circumference: number;
  public radius: number;
  public fill: number;

  public loaded = false;

  constructor() {
  }

  ngOnInit() {
    this.calc();
  }

  calc() {
    this.radius = (this.size / 2) - this.strokeSize;
    this.circumference = 2 * this.radius * Math.PI;
    this.fill = (this.circumference / 10) * this.score;
    this.setStyling();
    this.loaded = true;
  }

  setStyling() {
    const css = `#${this.id} { stroke-dasharray: ${this.fill}, ${this.circumference * 2}; stroke-dashoffset: ${this.circumference}; }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style: any = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }

}
