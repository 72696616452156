<div class="widget">
  <exo-add-summary (summary)="addedSummary($event)"></exo-add-summary>
</div>

<div class="widget">
  <header>Mijn producten</header>

  <exo-loader *ngIf="!products"></exo-loader>

  <div *ngIf="products" class="content summaries">
    <div *ngIf="products.length === 0" style="margin-bottom: 20px">
      Nog geen producten gevonden.
    </div>
    <div
      class="product"
      *ngFor="let product of products; let i = index"
      [routerLink]="productPath(product)"
      [class.notlinked]="!product.subjectLinked"
    >
      <img src="{{apiUrl}}{{product.cover}}" />
      <div *ngIf="product.subjectLinked" class="info">
        <div>{{product.name}}</div>
        <div class="courselevel">{{product.courseLevel}}</div>
      </div>
      <div *ngIf="!product.subjectLinked" class="overlay">
        <div>{{product.name}}</div>
        <div class="courselevel">{{product.courseLevel}}</div>
        <a class="btn btn-primary" (click)="linkCourse(product, i)"
          >Koppel vak</a
        >
      </div>
    </div>
  </div>
</div>

<div class="widget">
  <header>Producten interessant voor jou</header>

  <exo-cart-buyables [simpleMode]="true"></exo-cart-buyables>
</div>
