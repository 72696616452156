import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../../../shared/auth/auth.service";
import { BadgesNotificationService } from "../../../shared/badges/badge.notification.service";
import { BadgesService } from "../../../shared/badges/badge.service";
import { CredentialsModel } from "../../../shared/password/credentials.model";
import { Subject } from "../../../shared/subjects/subject";
import { SubjectService } from "../../../shared/subjects/subject.service";
import { TrackingService } from "../../../shared/tracking/tracking.service";
import { AccountType, Gender, User } from "../../../shared/user/user";
import { UserService } from "../../../shared/user/user.service";

import { DatalayerService } from "./../../../services/datalayer/datalayer.service";

@Component({
  selector: "exo-profile-main",
  templateUrl: "profile.html",
  styleUrls: ["profile.scss"],
  animations: [
    trigger("popup", [
      state("*", style({ opacity: 1, transform: "translateY(0)" })),
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(10px)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 1, transform: "translateY(0)" })
        ),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(0)" }),
        animate(
          "200ms ease-out",
          style({ opacity: 0, transform: "translateY(-10px)" })
        ),
      ]),
    ]),
    trigger("overlay", [
      state("*", style({ opacity: 1 })),
      transition(":enter", [
        style({ opacity: 0 }),
        animate("200ms ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate("200ms ease-out", style({ opacity: 0 })),
      ]),
    ]),
    trigger("slideleft", [
      state(
        "*",
        style({ opacity: 1, transform: "translateX(0)", height: "*" })
      ),
      transition(":enter", [
        style({ opacity: 0, transform: "translateX(20%)", height: "*" }),
        animate(
          "200ms ease-out",
          style({ opacity: 1, transform: "translateX(0)", height: "*" })
        ),
      ]),
    ]),
    trigger("expand", [
      state("*", style({ height: "*" })),
      transition(":enter", [
        style({ height: 0, opacity: 0 }),
        animate("200ms ease-out", style({ height: "*", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ height: "*", opacity: 1 }),
        animate("200ms ease-out", style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class ProfileComponent implements OnInit {
  @ViewChild("profileForm") profileForm;
  @ViewChild("passForm") passForm;

  subjects: Subject[];
  normalSubjects: Subject[];
  extraSubjects: Subject[];

  nextSubject: Subject;

  courseLevel: string;

  verification = false;
  newUser;

  loading = false;
  subjectsLoading = true;
  showCourseLevelSelect = false;
  popupIsVisible = false;
  editProfile = false;
  changePicture = false;
  deleteAccount = false;
  password = false;
  status: string;
  errstatus: string;

  editSchool = false;
  editGender = false;
  editUsertype = false;

  picture: string;

  credentials: CredentialsModel = { current: "", password: "", repeat: "" };
  nickname: string;
  name: string;
  email: string;
  curpasswordErr = false;

  confirm: string;
  delete = false;

  badgesCount = 0;

  user;

  created: string;

  Gender: typeof Gender = Gender;
  AccountType: typeof AccountType = AccountType;

  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    evt: KeyboardEvent
  ) {
    this.closeAll();
  }

  constructor(
    private subjectService: SubjectService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private datalayerService: DatalayerService,
    private badgesService: BadgesService,
    private badgesNotificationService: BadgesNotificationService,
    private trackingService: TrackingService
  ) {}

  get canEditPassword() {
    return this.user && !this.user.socialMediaProvider;
  }

  ngOnInit() {
    this.authService.getUser().subscribe(
      (user: User) => {
        this.courseLevel = user.courseLevel;
        this.user = user;
        this.nickname = user.nickname.value;
        this.name = user.name;
        this.email = user.email;

        // tslint:disable-next-line: prefer-template
        this.created = user.created ? this.displayCreationDate(user) : null;
      },
      (err) => {
        console.log(err);
      }
    );

    this.subjectService.getSubjects().subscribe(
      (subjects) => {
        this.normalSubjects = [];
        this.extraSubjects = [];

        if (subjects) {
          subjects.forEach((subject) => {
            if (subject.category === "Extra vakken") {
              this.extraSubjects.push(subject);
            } else {
              this.normalSubjects.push(subject);
            }
          });
          this.subjects = subjects.sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          );
        }
        this.subjectsLoading = false;
      },
      (err) => {
        console.log(err);
        this.subjectsLoading = false;
      }
    );

    this.getbadgesCount();
  }

  displayCreationDate(user) {
    return (
      new Date(user.created).getDate() +
      " " +
      [
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december",
      ][new Date(user.created).getMonth()] +
      " " +
      new Date(user.created).getFullYear()
    );
  }

  closeAll() {
    this.errstatus = null;
    this.showCourseLevelSelect = false;
    this.popupIsVisible = false;
    this.closeModal();
    this.changePicture = false;
    this.deleteAccount = false;
    this.editSchool = false;
    this.editGender = false;
    this.editUsertype = false;
  }

  editUser() {
    this.errstatus = null;

    if (this.password) {
      this.userService
        .editPassword(this.credentials.password, this.credentials.current)
        .subscribe(
          (data) => {
            this.status = "Wachtwoord gewijzigd";
          },
          (err) => {
            if (err.status === 403) {
              this.curpasswordErr = true;
              this.errstatus = "Huidig wachtwoord verkeerd";
            } else {
              this.errstatus = "Wachtwoord wijzigen niet gelukt";
            }
            console.log(err);
          }
        );
    } else {
      this.loading = true;

      this.userService
        .editProfile(
          this.name,
          this.nickname,
          this.email,
          this.credentials.current
        )
        .subscribe(
          (user) => {
            if (this.email !== this.user.email) {
              this.editEmail(this.email);
            } else {
              this.closeModal();
              this.loading = false;

              this.user = user;
              this.nickname = user.nickname.value;
              this.name = user.name;
              this.email = user.email;
            }
          },
          (err) => {
            if (err.status === 409) {
              this.errstatus = "E-mailadres bestaat al";
            } else if (err.status === 400) {
              if (err.message.includes("nickname")) {
                this.errstatus = "Ongeldige gebruikersnaam";
              }

              if (err.message.includes("curpassword")) {
                this.errstatus = "Wachtwoord verkeerd";
              } else {
                this.errstatus = "Ongeldig e-mailadres";
              }
            } else if (err.status === 403) {
              this.curpasswordErr = true;
              this.errstatus = "Wachtwoord verkeerd";
            } else {
              this.errstatus =
                "Wijzigen niet gelukt, probeer het later nog eens";
            }
            this.loading = false;
            console.log(err);
          }
        );
    }
  }

  data(event) {
    if (event.courseLevel) {
      this.userService.editCourseLevel(event.courseLevel).subscribe(
        (data) => {
          this.showCourseLevelSelect = false;
          this.courseLevel = event.courseLevel;
          this.popupIsVisible = true;
        },
        (err) => {
          console.log(err);
        }
      );
    } else if (event.dismiss === true) {
      this.showCourseLevelSelect = false;
      this.popupIsVisible = false;
    } else if (event.subjects) {
      this.userService.editSubjects(event.subjects).subscribe(
        (data) => {
          this.popupIsVisible = false;
          this.subjectService.reloadSubjects();
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  next(event) {
    if (event === "next") {
      this.verification = false;

      this.user.nickname = this.newUser.nickname.value;
      this.user.name = this.newUser.name;
      this.user.email = this.newUser.editEmail;
    } else {
      this.loading = false;
    }
  }

  close(event: any) {
    this.closeAll();

    if (typeof event.gender !== "undefined") {
      this.user.gender = event.gender;
    }
    if (typeof event.accountType !== "undefined") {
      this.user.accountType = event.accountType;
    }
    if (typeof event.school !== "undefined") {
      this.user.school = event.school;
    }
  }

  openChangePictureModal() {
    this.changePicture = true;
  }

  pictureObject(event) {
    this.picture = event;
  }

  editProfilePicture() {
    if (this.picture) {
      this.loading = true;
      this.userService.editProfilePicture(this.picture).subscribe(
        (user) => {
          this.changePicture = false;
          this.loading = false;
          this.user = user;
          this.nickname = user.nickname.value;
          this.name = user.name;
          this.email = user.email;
        },
        (err) => {
          this.loading = false;
          console.log(err);
        }
      );
    }
  }

  editPassword() {
    this.password = true;
    this.status = null;
    this.errstatus = null;
  }

  deleteAccountPermanently() {
    this.sendDatalayer("profile", "button", "delete-account");
    this.errstatus = null;
    if (this.confirm.toLowerCase() === "verwijder") {
      this.loading = true;
      this.userService.deleteAccount().subscribe(
        (data) => {
          this.trackingService.destroy();
          this.badgesNotificationService.destroy();
          this.authService.logout();
          this.delete = true;
          setTimeout(() => this.router.navigate(["login"]), 3000);
        },
        (err) => {
          this.loading = false;
          this.errstatus = "Verwijderen niet gelukt.";
          console.log(err);
        }
      );
    } else {
      this.errstatus =
        "Typ 'verwijder' om te bevestigen dat je je account echt wil verwijderen.";
    }
  }

  editUserOrClose() {
    if (this.status === "Wachtwoord gewijzigd") {
      this.closeModal();
      this.password = false;
      this.status = null;
      this.errstatus = null;
    } else {
      this.editUser();
    }
  }

  sendDatalayer(label: string, category = "main-menu", action = "navigate") {
    this.datalayerService.send({
      hitType: "event",
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  getbadgesCount() {
    this.badgesService.getCount().subscribe((badgeCount: any) => {
      this.badgesCount = badgeCount.amount;
    });
  }

  typeof(value: any) {
    return typeof value;
  }

  public get canSubmit() {
    if (!this.password) {
      return (
        (this.name !== this.user.name ||
          this.nickname !== this.user.nickname.value ||
          this.email !== this.user.email) &&
        this.credentials.current
      );
    }

    return (
      this.credentials.current &&
      this.credentials.password &&
      this.credentials.password === this.credentials.repeat
    );
  }

  private editEmail(newEmail: string) {
    this.userService.editEmail(newEmail, this.credentials.current).subscribe(
      (user) => {
        this.verification = true;
        this.closeModal();
        this.newUser = user;
        this.loading = false;
      },
      (err) => {
        this.errstatus = "Email wijzigen niet gelukt";
        console.log(err);
        this.loading = false;
      }
    );
  }

  private closeModal() {
    this.editProfile = false;
    this.password = false;
    this.credentials.current = "";
    this.credentials.password = "";
    this.credentials.repeat = "";
  }
}
