import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Cart } from '../../../shared/cart/cart';
import { CartService } from '../../../shared/cart/cart.service';
import { environment } from '../../../shared/environments/environment';

@Component({
  selector: 'exo-cart-order',
  templateUrl: 'order.html',
  styleUrls: ['order.scss'],
})
export class CartOrderComponent implements OnInit, OnDestroy {
  public cart: Cart;
  public apiUrl = environment.API_URL;

  private cartSubscription: Subscription;

  constructor(
    private cartService: CartService,
  ) {
  }

  ngOnInit() {
    this.cartSubscription = this.cartService.cart$.subscribe((cart: Cart) => {
      this.cart = cart;
    });
  }

  deleteFromCart(product) {
    this.cartService.deleteFromCart(product);
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }

}
