<div class="navbar">
  <div class="container">
    <header>
      <img routerLink="/dashboard" src="/assets/logo.png" height="60" />
    </header>

    <nav class="desktop">
      <a
        class="btn"
        routerLink="/dashboard"
        routerLinkActive="active"
        (click)="sendDatalayer('dashboard')"
        >Dashboard</a
      >
      <a
        class="btn"
        [class.active]="rootRoute === 'subject'"
        (mouseenter)="lockEnter ? '' : showsubjects = true"
        (mouseleave)="showsubjects = false"
        (click)="showsubjects = false; sendDatalayer('courses')"
        >Vakken
        <div
          *ngIf="showsubjects"
          class="subject-popup"
          [@popupAnimation]
          (@popupAnimation.start)="lockEnter = true"
          (@popupAnimation.done)="lockEnter = false"
        >
          <exo-loader *ngIf="!normalSubjects"></exo-loader>
          <div class="nosubjects" *ngIf="normalSubjects?.length === 0">
            Geen vakken geselecteerd.<br />
            Voeg ze toe aan je account.
          </div>
          <ul class="list-group">
            <li
              class="list-group-item"
              *ngFor="let subject of normalSubjects"
              [routerLink]="['/subject', subject.encodedName, 'home']"
              (click)="sendDatalayer('courses')"
            >
              <div class="subject-code">
                <img src="{{subject.subjectImage}}" />
              </div>
              <div class="subject">
                <div>{{subject.name}}</div>
                <div class="courseLevel">{{subject.courseLevel}}</div>
              </div>
            </li>
          </ul>
          <div
            class="extradivider"
            *ngIf="extraSubjects && extraSubjects.length > 0"
          >
            Extra vakken
          </div>
          <ul
            class="list-group extra"
            *ngIf="extraSubjects && extraSubjects.length > 0"
          >
            <li
              class="list-group-item"
              *ngFor="let subject of extraSubjects"
              (click)="showpopup(subject)"
            >
              <div class="subject-code">
                <img src="{{subject.subjectImage}}" />
              </div>
              <div class="subject">{{subject.name}}</div>
            </li>
          </ul>
        </div>
      </a>

      <a
        class="btn"
        routerLink="/schedule"
        routerLinkActive="active"
        (click)="sendDatalayer('schedule')"
        >Rooster</a
      >
      <a
        class="btn profile"
        [class.active]="rootRoute === 'profile'"
        (click)="toggleDropdown()"
      >
        <div
          class="img"
          [style.background-image]="'url(' + (user && user.profileImage ? user.profileImage : './assets/profile-picture.png') + ')'"
        ></div>
        <span class="fa fa-caret-down"></span>
        <div *ngIf="showdropdown" class="overlay"></div>
        <ul *ngIf="showdropdown" class="dropdown" [@popupAnimation]>
          <li
            class="list-group-item"
            routerLink="/profile"
            (click)="sendDatalayer('profile')"
          >
            Profiel
            <span class="icon icon-profile"></span>
          </li>
          <li
            class="list-group-item"
            (click)="logout(); sendDatalayer('profile', 'button', 'logout-account')"
          >
            Uitloggen
            <span class="fa fa-sign-out"></span>
          </li>
        </ul>
      </a>

      <a class="btn-cart" routerLink="/cart" *ngIf="cart?.items?.length > 0">
        <div class="cart-badge"><span>{{cart?.items?.length}}</span></div>
      </a>
    </nav>

    <nav class="mobile">
      <div class="mobile-header-actions">
        <a
          class="cart-mobile"
          routerLink="/cart"
          *ngIf="cart?.items?.length > 0"
        >
          <span class="cart-icon"></span>
          <span class="cart-badge">{{cart?.items?.length}}</span>
        </a>
        <a class="btn" (click)="menu = !menu" [ngClass]="{'active': menu}">
          <span
            class="fa"
            [ngClass]="{'fa-times': menu, 'fa-bars': !menu}"
          ></span>
        </a>
      </div>

      <ul class="list-group" *ngIf="menu" [@menuAnimation]>
        <li
          class="list-group-item"
          routerLink="/dashboard"
          routerLinkActive="active"
          (click)="menu = false; sendDatalayer('dashboard')"
        >
          Dashboard
        </li>
        <li
          class="list-group-item subject"
          [class.active]="rootRoute === 'subject'"
          (click)="showsubjects = !showsubjects; sendDatalayer('courses')"
        >
          Vakken
          <ul class="subjects" *ngIf="showsubjects" [@menuAnimation]>
            <li
              *ngFor="let subject of normalSubjects"
              [routerLink]="['/subject', subject.encodedName, 'home']"
              (click)="menu = false"
            >
              <div class="subject">
                <div>{{subject.name}}</div>
                <div class="courseLevel">{{subject.courseLevel}}</div>
              </div>
            </li>
            <div
              class="extradivider"
              *ngIf="extraSubjects && extraSubjects.length > 0"
            >
              Extra vakken
            </div>
            <li *ngFor="let subject of extraSubjects">{{subject.name}}</li>
          </ul>
        </li>

        <li
          class="list-group-item"
          routerLink="/schedule"
          routerLinkActive="active"
          (click)="menu = false; sendDatalayer('schedule')"
        >
          Rooster
        </li>
        <li
          class="list-group-item"
          routerLink="/profile"
          routerLinkActive="active"
          (click)="menu = false; sendDatalayer('profile')"
        >
          Profiel
        </li>
        <li
          class="list-group-item"
          (click)="logout(); sendDatalayer('profile', 'button', 'logout-account')"
        >
          Uitloggen
        </li>
      </ul>
    </nav>
  </div>
</div>

<div
  class="popup"
  *ngIf="extraSubject"
  [@popupAnimation]
  (click)="closepopup($event)"
>
  <div class="widget">
    <header>
      <p>{{extraSubject.name}}</p>
      <span style="left: initial; right: 30px" class="fa fa-times"></span>
    </header>
    <div class="content">
      Van {{extraSubject.name}} zijn er geen samenvattingen beschikbaar.
    </div>
    <div class="buttons">
      <button class="btn btn-primary" routerLink="/schedule">
        Bekijk in rooster
      </button>
    </div>
  </div>
</div>

<div class="outlet" [@routerAnimation]="getRouteAnimation(route)">
  <router-outlet #route="outlet"></router-outlet>
</div>
