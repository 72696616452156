import { Injectable } from '@angular/core';

import { Datalayer } from './datalayer';

declare const ga: any;

@Injectable({
  providedIn: 'root',
})
export class DatalayerService {

  constructor() { }

  send(datalayer: Datalayer) {
    const _datalayer = Object.assign(new Datalayer(), datalayer);
    ga('send', _datalayer);
  }

}
