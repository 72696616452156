import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { DefinitionTypeModel } from './models/definition-type.model';
import { DefinitionModel } from './models/definition.model';
import { FlatDefinitionModel } from './models/flat-definition.model';
import { RawDefinitionModel } from './models/raw-definition.model';

@Injectable()
export class DefinitionService {
  private cache = new Map<string, Observable<DefinitionModel>>();

  constructor(private http: HttpClient) { }

  private mapDefinition(raw: RawDefinitionModel[]): DefinitionModel {
    const modern = raw.find(e => !e['name']);

    if (!modern) {
      return [DefinitionTypeModel.LEGACY, raw[0]];
    }

    return [DefinitionTypeModel.MODERN, modern];
  }

  public getDefinition(subject: string) {
    if (!this.cache.has(subject)) {
      this.cache.set(
        subject,
        this.http.get<RawDefinitionModel[]>(`/subject/${subject}/definitions`).pipe(
          map(this.mapDefinition),
          shareReplay(1),
        ),
      );
    }

    return this.cache.get(subject);
  }

  public flatten(definition: RawDefinitionModel): FlatDefinitionModel[] {
    return definition.categories.reduce(
      (previous, current) => ([
        ...previous,
        ...current.subcategories.reduce(
          (prev, curr) => ([
            ...prev,
            ...curr.definitions.reduce(
              (p, c) => ([
                ...p,
                {
                  _id: definition._id,
                  subjectId: definition.subjectId,
                  category: current,
                  subcategory: curr,
                  definition: c,
                },
              ]),
              [],
            ),
          ]),
          [],
        ),
      ]),
      [],
    );
  }

  public reshape(mapped: FlatDefinitionModel[]): RawDefinitionModel {
    if (!mapped.length) {
      return {
        _id: '',
        subjectId: '',
        categories: [],
      };
    }

    const categories = {};

    for (const m of mapped) {
      let category;
      if (!(category = categories[m.category._id])) {
        category = categories[m.category._id] = {
          ...m.category,
          subcategories: {},
        };
      }

      let subcategory;
      if (!(subcategory = category.subcategories[m.subcategory._id])) {
        subcategory = category.subcategories[m.subcategory._id] = {
          ...m.subcategory,
          definitions: [],
        };
      }

      subcategory.definitions.push(m.definition);
    }

    return {
      _id: mapped[0]._id,
      subjectId: mapped[0].subjectId,
      categories: Object.keys(categories).map((categoryId) => {
        const category = categories[categoryId];

        return {
          ...category,
          subcategories: Object.keys(category.subcategories).map((subcategoryId: string) => category.subcategories[subcategoryId]),
        };
      }),
    };
  }
}
