import { Injectable } from '@angular/core';
import { DeviceDetectorService  } from 'ngx-device-detector';

import { Device } from '../../shared/user/device';

@Injectable()
export class DeviceService {

  constructor(private deviceService: DeviceDetectorService) { }

  getFingerprint(): Promise<Device> {
    return new Promise((resolve, reject) => {
      const deviceInfo = this.deviceService.getDeviceInfo();
      const device = new Device();
      device.identifier = '1';
      device.description = `${deviceInfo.browser} for ${deviceInfo.os}`;
      device.platform = deviceInfo.os;

      resolve(device);
    });
  }
}
