import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(value: number): string {
    let newValue = value;
    let isNegative = false;
    if (value.toString().includes('-')) {
      newValue = Math.abs(value);
      isNegative = true;
    }
    return `${isNegative ? '- ' : ''}&euro;${newValue.toFixed(2).toString().replace('.', ',')}`;
  }
}
