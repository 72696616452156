import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'exo-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  @Input() rating: number;
  @Input() editable: true;
  @Output() ratingChange = new EventEmitter<number>();
  @Output() ratingSet = new EventEmitter<number>();
  @ViewChild('ratingelement', { static: true }) ratingelement: ElementRef;
  edit = true;

  constructor() { }

  ratable() {
    return this.edit = !this.edit;
  }

  ngOnInit() {
  }

  rate(score) {
    if (this.edit && this.editable) {
      this.changeRating(score);
    }
  }

  setRating(score) {
    if (this.editable) {
      this.ratable();
      this.changeRating(score);
      this.ratingSet.emit(this.rating);
    }
  }

  mouseOut() {
    if (this.edit && this.editable) {
      this.changeRating(0);
    }
  }

  changeRating(rating) {
    this.rating = rating;
    this.ratingChange.emit(this.rating);
  }

}
