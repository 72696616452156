<div class="dashboard-nav">
  <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="30"
    [freeMode]="true"
    [navigation]="true"
    [breakpoints]="{
      '1024': {
        slidesPerView: 8,
        slidesPerGroup: 8,
        freeMode: false
      }
    }"
    class="mySwiper"
  >
    <ng-template swiperSlide *ngFor="let subject of allSubjects">
      <div [class.active]="subject.hasSummary || subject.hasExercise">
        <img src="{{subject.subjectImage}}" (click)="openTraining(subject)" />
        <div (click)="openTraining(subject)">
          <span class="text-subject">{{subject.name | truncate : 12}}</span>
          <span class="text-score">{{subject.subjectRating || 'Start'}}</span>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
