import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { exerciseAnimation } from './exercise.animations';

@Component({
  selector: 'exo-training',
  templateUrl: 'training.page.html',
  styleUrls: ['training.page.scss'],
  animations: [exerciseAnimation],
})

export class TrainingPageComponent implements OnInit {
  constructor(
  ) { }

  ngOnInit() {
  }

  public canDeactivate() {
    return timer(350).pipe(mapTo(true)).toPromise();
  }

}
