<div class="container">
  <div class="row">
    <div class="col-md-8" *ngIf="products.length > 0">
      <div class="widget">
        <header>
          <p>Producten toevoegen</p>
        </header>
        <div class="content">
          We hebben deze bestellingen gevonden.
          <br/>Wil je ze aan je account toevoegen?

          <div class="row mt-4">
            <div class="col-6 mb-4" *ngFor="let product of products; let i = index">
              <div class="summary" [class.added]="product.subjectLinked">
                <img src="{{apiUrl}}{{product.cover}}" />
                <div class="info">
                  <div>{{product.name}}</div>
                  <div class="courselevel">{{product.courseLevel}}</div>
                  <a *ngIf="!product.subjectLinked" class="btn" (click)="linkCourse(product, i)">{{product.subjectLinked ? 'Toevoegen' : 'Koppel vak'}}</a>
                  <div *ngIf="product.subjectLinked" class="bottom">Gekoppeld</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="footer">
          <button class="btn" [class.btn-primary]="addCount > 0" (click)="out.emit('next');">Verder</button>
        </div>
      </div>
    </div>
    <div *ngIf="products.length === 0" class="col-md-4"></div>
    <div class="col-md-4">
      <div class="widget">
        <exo-add-summary (summary)="addedSummary($event)"></exo-add-summary>
      </div>
      <div class="widget" *ngIf="products.length === 0">
        <div class="content">
          <p>Heb je nog geen samenvattingen? Sla deze stap dan over.</p>
          <button class="btn btn-primary" (click)="out.emit('next')">Overslaan</button>
        </div>
      </div>
    </div>
  </div>
</div>