import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'exo-answer-rating',
  templateUrl: './answer-rating.component.html',
  styleUrls: ['./answer-rating.component.scss'],
})
export class AnswerRatingComponent implements OnInit {
  @Input() maxRating: number;
  @Input() rating = -1;
  @Input() editable: true;
  @Output() ratingSet = new EventEmitter<number>();
  @ViewChild('ratingelement', { static: true }) ratingelement: ElementRef;

  constructor() { }

  ngOnInit() { }

  setRatingDefinitely(rating: number) {
    if (this.editable) {
      this.rating = rating;
      this.ratingSet.emit(this.rating);
    }
  }

}
