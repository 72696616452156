<form [formGroup]="shippingForm" (ngSubmit)="onSubmit()" novalidate>
  <h3 class="TextTitleRegular" [style.margin]="'0 0 8px 0'">Verzendadres</h3>

    <div class="input-regular" [class.invalid]="inputInvalid('firstName')">
      <label for="firstName">Voornaam</label>
      <input class="form-control" type="text" name="firstName" formControlName="firstName" />
    </div>

    <div class="input-regular" [class.invalid]="inputInvalid('lastName')">
      <label for="lastName">Achternaam</label>
      <input class="form-control" type="text" name="lastName" formControlName="lastName" />
    </div>
    <div class="input-regular" [class.invalid]="inputInvalid('email')">
      <label for="email">E-mailadres</label>
      <input class="form-control" type="text" name="email" formControlName="email" />
    </div>
    
    <div class="input-regular" [class.invalid]="inputInvalid('street')">
      <label for="street">Straat</label>
      <input class="form-control" type="text" name="street" formControlName="street" />
    </div>
    <div class="input-split">
      <div class="input-regular" [class.invalid]="inputInvalid('houseNumber')">
        <label for="houseNumber">Huisnummer</label>
        <input class="form-control" type="text" name="houseNumber" formControlName="houseNumber" />
      </div>
      <div class="input-regular" [class.invalid]="inputInvalid('addition')">
        <label for="addition">Toevoeging</label>
        <input class="form-control" type="text" name="addition" formControlName="addition" />
      </div>
    </div>
    <div class="input-regular" [class.invalid]="inputInvalid('postalCode')">
      <label for="postalCode">Postcode</label>
      <input class="form-control" type="text" name="postalCode" formControlName="postalCode" />
    </div>
    <div class="input-regular" [class.invalid]="inputInvalid('city')">
      <label for="city">Woonplaats</label>
      <input class="form-control" type="text" name="city" formControlName="city" />
    </div>

    <h3 class="TextTitleRegular" [style.margin]="'32px 0 8px 0'">Betalingsmethode</h3>
    <exo-cart-issuers></exo-cart-issuers>
    <span class="TextCartPrivacy" [style.margin]="'8px 0 16px 0'">Door op ‘Bestelling afronden’ te klikken ga je akkoord met onze <a href="https://www.examenoverzicht.nl/algemene-voorwaarden" target="_blank"><strong>Algemene Voorwaarden & Privacybeleid</strong></a></span>
   
    <exo-button classes="btn-regular" text="Bestelling afronden" [type]="'submit'"></exo-button>

    <span class="form-error" [style.margin]="'16px 0 16px 0'" *ngIf="errorMsg">{{errorMsg}}</span>

  </form>