
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserBadge } from './badge';

@Injectable()
export class BadgesService {

  constructor(private http: HttpClient) { }

  getBadges(): Observable<UserBadge[]> {
    return this.http.get<UserBadge[]>('/user/badges');
  }

  getCount(): Observable<any> {
    return this.http.get<any>('/user/badges/unlocked/count');
  }

  getFeatured(): Observable<UserBadge> {
    return this.http.get<UserBadge>('/user/badges/featured');
  }

}
