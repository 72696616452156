<div class="widget" [class.popup]="mode === 'popup'">
  <header>
    <p>Ik ben een</p>
    <span *ngIf="mode === 'popup'" style="left: initial; right: 30px" (click)="out.emit({dismiss: true})" class="fa fa-times"></span>
  </header>
  <div class="content">
    <exo-loader *ngIf="!accountTypes"></exo-loader>
    <button *ngFor="let accountType of accountTypes" class="btn btn-primary" (click)="onSubmit(accountType.type)">{{accountType.name}}</button>
  </div>
</div>
