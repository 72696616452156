import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from '../../../shared/subjects/subject';
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

@Component({
  selector: 'exo-dashboard-nav',
  templateUrl: 'dashboard-nav.html',
  styleUrls: ['dashboard-nav.scss'],
})

export class DashboardNavComponent implements OnInit, OnChanges {
  @Input() subjects: Subject[];
  @Input() extraSubjects: Subject[];
  @Input() extra;

  allSubjects: Subject[];
  courseLevel: string;

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subjects || changes.extraSubjects) {
      this.allSubjects = [...this.subjects, ...this.extraSubjects].sort((a, b) => Number(b.hasSummary || b.hasExercise) - Number(a.hasSummary || a.hasExercise));
    }
  }

  openTraining(subject: Subject) {
    this.router.navigate(['/subject', subject.encodedName, 'home']);
  }

}
