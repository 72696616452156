import { format } from 'date-fns';

export function timefy(seconds: number) {
  if (seconds) {
    const d = new Date(0).setSeconds(seconds);
    const displayMinutes = `${format(d, 'm')}m`;
    const displaySeconds = `${format(d, 's')}s`;
    return `${displayMinutes} ${displaySeconds}`;
  }
  return '-';
}
