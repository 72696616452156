import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "../environments/environment";
import {
  ExperimentSection,
  ExperimentSubjectSections,
  Experiment,
} from "./experiment";

@Injectable()
export class ExperimentService {
  public CONVERSION_EXPERIMENT_NAME = "conversion-experiment";

  constructor(private http: HttpClient) {}

  addUser(): Observable<Experiment> {
    return this.http.post<any>(
      `/experiments/${this.CONVERSION_EXPERIMENT_NAME}/addUser`,
      {}
    );
  }

  getSections(): Observable<ExperimentSubjectSections[]> {
    return this.http.get<any>(
      `/experiments/${this.CONVERSION_EXPERIMENT_NAME}/sections`,
      {}
    ).pipe(map((subjects: ExperimentSubjectSections[]) => subjects.map((subject) => ({
      ...subject,
      subjectImage: environment.API_URL + subject.subjectImage
    }))));;
  }

  claimPresent(
    claimUrl,
    experimentSection?: ExperimentSection
  ): Observable<Experiment> {
    return this.http.post<any>(
      `/${claimUrl}`,
      experimentSection ? experimentSection : {}
    );
  }
}
