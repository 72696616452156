import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from '../../../shared/subjects/subject';

import { Section } from '../../../shared/training/section';
import { ThesisCategory } from '../../../shared/training/thesis-category';
import { Training } from '../../../shared/training/training';
import { TrainingService } from '../../../shared/training/training.service';
import { ModalController } from '../../modal/modal.controller';

@Component({
  selector: 'exo-training-choice',
  templateUrl: 'training-choice.html',
  styleUrls: ['training-choice.scss'],
})
export class TrainingChoiceModalComponent implements OnInit {
  @Input() subject: Subject;
  @Input() section: Section;
  @Input() encodedChapterName: string;

  currentThesisCategory: string | null = '';
  thesisCategories: ThesisCategory[];
  sliderLabels = [];

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private trainingService: TrainingService,
  ) { }

  ngOnInit() {
    this.trainingService.getThesisCategories().subscribe((categories) => {
      this.thesisCategories = categories;
      this.sliderLabels = categories.map((cat: ThesisCategory) => cat.displayName);
    })
  }

  public sliderChange(sliderLevel: number) {
    if (sliderLevel === 0) {
      this.currentThesisCategory = this.thesisCategories[0].name;
    }
    if (sliderLevel === 1) {
      this.currentThesisCategory = '';
    }
    if (sliderLevel === 2) {
      this.currentThesisCategory = this.thesisCategories[1].name;
    }
  }

  public startTraining() {
    this.trainingService.postTraining(this.subject.encodedName, [this.section.encodedSectionName], this.encodedChapterName, this.currentThesisCategory).subscribe((training: Training) => {
      this.trainingService.setCurrentTraining({...training, encodedChapterName: this.encodedChapterName});
      this.router.navigate(['training', this.subject.encodedName, training.lastThesisId], { skipLocationChange: true });
      this.close();
    });
  }

  public close() {
    this.modalCtrl.closeModal();
  }

}
