<exo-feature name="leaderboard" *ngIf="leaderboard">
  <div class="card {{mode}}">
    <exo-btn
      class="btn-close"
      styleClass="icon-plain"
      icon="/assets/icons/close-black.svg"
      (click)="closeModal()"
      *ngIf="mode === 'popup'"
    ></exo-btn>

    <div class="header">
      <div>
        <span class="title">{{leaderboard.title}}</span>
        <span class="subtitle" *ngIf="daysLeft > 0"
          >Nog {{daysLeft > 1 ? daysLeft + ' dagen' : '1 dag'}}</span
        >
        <span class="subtitle" *ngIf="daysLeft === 0">Laatste dag</span>
        <p *ngIf="mode === 'popup'" class="description">
          {{leaderboard.subtitle}}
        </p>
      </div>
    </div>

    <div class="content lb-content">
      <ul class="top-rank">
        <li
          class="user-score-top"
          [class.isme]="score.isMe"
          *ngFor="let score of leaderboard.scores | slice:0:3"
        >
          <span class="rank-no">#{{score.rank}}</span>
          <div class="lb-labels">
            <span class="name">{{score.nickname}}</span>
            <div class="score-label">
              <span class="score">{{score.score}}</span>
              <span class="points" *ngIf="score.todaysScore"
                >+{{score.todaysScore}}</span
              >
            </div>
          </div>
        </li>
      </ul>

      <ul class="lower-rank">
        <li
          class="user-score"
          [class.isme]="score.isMe"
          *ngFor="let score of neighborScores"
        >
          <div class="lb-labels">
            <span class="name">#{{score.rank}} {{score.nickname}}</span>
            <div class="score-label">
              <span class="score">{{score.score}}</span>
              <span class="points" *ngIf="score.todaysScore"
                >+{{score.todaysScore}}</span
              >
            </div>
          </div>
        </li>
      </ul>

      <div class="lb-button-container" *ngIf="mode !== 'popup'">
        <exo-btn
          label="Bekijk jouw volledige competitie"
          (click)="openLeaderboardModal()"
        ></exo-btn>
      </div>
    </div>
  </div>
</exo-feature>
